import { useCallback, useEffect, useState } from 'react';

import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { Plus } from 'lucide-react';
import { nanoid } from 'nanoid';
import { useParams } from 'react-router-dom';

import { classNames, functionName, questionsStatusType } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';

import { useJuneTrack } from '../../../../../../../../hooks/useJuneTrack';
import { useTranslation } from '../../../../../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../../../../../lib/colors';
import { useGetClientAuthQuery } from '../../../../../../../../lib/react-query/features/auth/auth.hooks';
import { EMAIL_LANGUAGES_SUPPLIER } from '../../../../../../../../utils/constants';

import SupplierContactRowForm from './SupplierContactRowForm';

type Props = {
	open: boolean;
	setOpen: (open: boolean) => void;
	supplier: Record<string, any>;
	data: Record<string, any>[];
};

const SuperModal = ({ open, setOpen, supplier, data }: Props) => {
	const { t } = useTranslation();
	const analytics = useJuneTrack();

	const handleClose = (event: any, reason: any) => {
		setOpen(false);
		setTimeout(() => {
			setNewContacts([]);
		}, 1000);
		setToSaveContacts([]);
	};

	const [toSaveContacts, setToSaveContacts] = useState<Record<string, any>[]>([]);
	const [newContacts, setNewContacts] = useState<Record<string, any>[]>(() => {
		const initial: Record<string, any>[] = [];
		_.forEach(data, (item) => {
			if (_.get(item, 'statusType') === questionsStatusType.SUPPLIER_NOT_CONTACTED) {
				initial.push({
					...item,
					isEditing: false,
				});
			}
		});
		return initial;
	});

	useEffect(() => {
		if (!open) {
			return;
		}

		setNewContacts((prev) => {
			const initial: Record<string, any>[] = [];
			_.forEach(data, (item) => {
				if (_.get(item, 'statusType') === questionsStatusType.SUPPLIER_NOT_CONTACTED) {
					initial.push({
						...item,
						isEditing: false,
					});
				}
			});
			return initial;
		});
	}, [data, open]);

	const someIsEditing = _.some(newContacts, (item) => {
		return _.get(item, 'isEditing') === true;
	});

	const updateEditingStatus = useCallback((id: string, isEditing: boolean) => {
		setNewContacts((prev) => {
			return _.map(prev, (contact) => {
				if (contact.objectId === id || contact.tempId === id) {
					return {
						...contact,
						isEditing,
					};
				}

				return contact;
			});
		});
	}, []);

	const isEmptyToSave = _.isEmpty(toSaveContacts);

	const queryClient = useQueryClient();

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();
	const params = useParams();
	const questionFormId = _.toString(_.get(params, 'questionFormId'));

	const { mutate: submitData, isLoading: isSubmitLoading } = useMutation({
		mutationKey: ['saveContactsForSuppliersList'],
		mutationFn: async (data: Record<string, any>[]) => {
			try {
				const objects = _.map(data, (item) => {
					const newObject = new Parse.Object(classNames.QUESTION_FORM_STATUS_UPDATE);

					if (item.objectId) {
						newObject.id = item.objectId;
					}

					const company = authData?.session.company;
					const companyCode = company?.code;

					if (!companyCode) {
						throw new Error('Company code not found');
					}

					const companyObject = new Parse.Object(classNames.COMPANY);
					companyObject.id = company.objectId;
					newObject.set('company', companyObject);

					newObject.set('statusType', questionsStatusType.SUPPLIER_NOT_CONTACTED);
					newObject.set('contact', {
						email: item.email,
						firstName: item.firstName,
						lastName: item.lastName,
						language: item.language,
					});
					newObject.set('supplier', {
						uid: supplier._id,
						name: supplier.name,
					});
					const qForm = new Parse.Object(classNames.QUESTION_FORM);
					qForm.id = questionFormId;
					newObject.set('questionForm', qForm);

					const acl = new Parse.ACL();
					acl.setRoleReadAccess(`${companyCode}_user`, true);
					acl.setRoleWriteAccess(`${companyCode}_editor`, true);
					return newObject;
				});

				await Parse.Object.saveAll(objects);

				// Add the supplier to the campaign
				await Parse.Cloud.run(functionName.questions.addSuppliersSelectPreCampaign, {
					supplierUids: [supplier._id],
					questionFormId,
				});

				return { ok: 'ok' };
			} catch (error) {
				return Promise.reject(error);
			}
		},
		onSuccess: async () => {
			queryClient.invalidateQueries(['findSuppliersTracking']);
			queryClient.invalidateQueries(['contacts']);
		},
	});

	const addTempContactToSave = useCallback((newContact: Record<string, any>) => {
		setToSaveContacts((prev) => {
			const arr = prev.filter((contact) => {
				return contact.tempId !== newContact.tempId;
			});
			arr.push(newContact);
			return arr;
		});
	}, []);

	const removeContactToSave = useCallback((objectId: string) => {
		setToSaveContacts((prev) => {
			return prev.filter((contact) => {
				return contact.objectId !== objectId;
			});
		});
	}, []);

	const addContactToSave = useCallback((newContact: Record<string, any>) => {
		setToSaveContacts((prev) => {
			const arr = prev.filter((contact) => {
				return contact.objectId !== newContact.objectId;
			});
			arr.push(newContact);
			return arr;
		});
	}, []);

	const addContactAtTop = useCallback(() => {
		// Add a new contact at the top of the list
		setNewContacts((prev) => {
			return [
				{
					tempId: nanoid(),
					contact: {
						firstName: '',
						lastName: '',
						email: '',
						language: EMAIL_LANGUAGES_SUPPLIER[0],
					},
					statusType: questionsStatusType.SUPPLIER_NOT_CONTACTED,
					isEditing: true,
				},
				...prev,
			];
		});
	}, []);

	const removeTempContact = useCallback((tempId: string) => {
		setNewContacts((prev) => {
			return prev.filter((contact) => {
				return contact.tempId !== tempId;
			});
		});
		setToSaveContacts((prev) => {
			return prev.filter((contact) => {
				return contact.tempId !== tempId;
			});
		});
	}, []);

	const noContact = _.isEmpty(data) || (data.length === 1 && data[0].statusType === 'no_contact');

	const otherContacts = _.filter(data, (item) => {
		return _.get(item, 'statusType') !== 'supplier_not_contacted';
	});

	const mapContacts = _.concat(newContacts, otherContacts);

	const existingEmails = _.map(mapContacts, (item) => {
		return _.get(item, 'contact.email');
	}).concat(_.map(toSaveContacts, (item) => {
		return _.get(item, 'email');
	})).filter((item) => {
		return !_.isEmpty(item);
	});

	const trackEvent = useCallback(
		(name: string) => {
			if (!analytics) return;
			analytics.track(name, {
				context: { category: 'Question Form' },
			});
		},
		[analytics],
	);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			scroll="paper"
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
			maxWidth="lg"
			fullWidth
			PaperProps={{
				sx: { padding: '24px' },
			}}
		>
			<DialogTitle
				id="scroll-dialog-title"
				component="div"
				sx={{
					padding: 0,
					paddingBottom: '22px',
				}}
			>
				<div
					css={{
						fontSize: 16,
						fontWeight: 600,
						fontFamily: 'Inter',
					}}
				>
					{_.capitalize(t('users'))} {t('of')} {_.get(supplier, 'name')}
				</div>
			</DialogTitle>
			<DialogContent sx={{ paddingLeft: 0, paddingRight: 0 }}>
				{_.map(
					mapContacts,
					(item, index) => {
						return (
							<div
								key={_.get(item, 'objectId', _.get(item, 'tempId'))}
								css={{ padding: '12px 0', borderTop: cn('1px solid', siteColors.grey500) }}
							>
								<SupplierContactRowForm
									objectId={_.get(item, 'objectId')}
									tempId={_.get(item, 'tempId')}
									data={{ ..._.get(item, 'contact') }}
									statusType={_.get(item, 'statusType')}
									existingEmails={existingEmails}
									// isEditing={toSaveContacts?.length ?  _.get(item, 'isEditing', false) : true}
									isEditing={noContact === true ? true : _.get(item, 'isEditing', false)}
									removeTempContact={removeTempContact}
									addTempContactToSave={addTempContactToSave}
									addContactToSave={addContactToSave}
									removeContactToSave={removeContactToSave}
									updateEditingStatus={updateEditingStatus}
									supplierUid={supplier._id}
								/>
							</div>
						);
					},
				)}
			</DialogContent>
			<DialogActions sx={{ padding: 0 }}>
				<div className="width100 flexRow justifyBetween">
					<BButton
						iconLeft={<Plus />}
						label={t('Ajouter un contact')}
						variant="secondary"
						disableRipple
						onClick={() => {
							addContactAtTop();
						}}
					/>
					<BButton
						label={isSubmitLoading ? <CircularProgress size={20} sx={{ color: '#FFF' }} /> : t('save-button')}
						variant={isEmptyToSave || someIsEditing ? 'secondary' : 'primary'}
						disableRipple
						isDisabled={(isEmptyToSave || someIsEditing) && !noContact}
						onClick={() => {
							if ((isEmptyToSave || someIsEditing) && !noContact) {
								return;
							}

							trackEvent('Question Form Supplier Contacts Edited');
							submitData(toSaveContacts);
						}}
					/>
				</div>
			</DialogActions>
		</Dialog>
	);
};

export default SuperModal;
