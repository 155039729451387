import { css, cx } from '@emotion/css';

import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';

import BubbleChatForm from './BubbleChatForm';
import BubbleChatHeader from './BubbleChatHeader';
import SuccessContent from './SuccessContent';

const styles = {
	container: css({
		borderRadius: 8,
		border: '1px solid #E2DDEB',
		background: '#FFF',
		boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.40), 0px 1px 2px 0px rgba(0, 0, 0, 0.45)',
		padding: '26px 24px',
		width: 400,
		minHeight: 446,
		gap: 16,
		justifyContent: 'center',
	}),
};

type BubbleChatCardProps = {
	isSuccess: boolean;
	isLoading: boolean;
	email?: string;
	onSendMessage: (data: { email: string; message: string }) => void;
	onClose: () => void;
};

const BubbleChatCard = ({ onSendMessage, isSuccess, isLoading, onClose, email = '' }: BubbleChatCardProps) => {
	// ---- user connected ---- //

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	return (
		<div className={cx('flexColumn', styles.container)}>
			{!isSuccess
? (
				<>
					<BubbleChatHeader />
					<BubbleChatForm onSaveData={onSendMessage} email={authData?.user?.email} isLoading={isLoading} />
				</>
			)
: (
				<SuccessContent onClose={onClose} email={email} />
			)}
		</div>
	);
};

export default BubbleChatCard;
