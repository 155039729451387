import { css, cx } from '@emotion/css';
import _ from 'lodash';

import { displayValue } from '@carbonmaps/ui/utils/numberFormat';

import Typography from '../../../../components/Typography';
import { useTranslation } from '../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../lib/colors';

const styles = {
	textNoWrap: css({
		whiteSpace: 'nowrap',
	}),
	fontBold: css({
		fontWeight: 'bold',
	}),
};

type CarbonFootprintTooltipProps = {
	label: string;
	value: number;
	unit?: React.ReactNode;
	items?: Array<{ label: string; value: number, unit: React.ReactNode }>;
};

const CarbonFootprintTooltip = ({ label, value, items, unit = 'T' }: CarbonFootprintTooltipProps) => {
	const { t } = useTranslation();
	return (
		<div
			className="flexColumn"
			css={{
				borderRadius: '8px',
				border: cn('1px solid', siteColors.grey500),
				background: siteColors.common.white,
				boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.45), 0px 4px 16px 0px rgba(0, 0, 0, 0.40)',
				padding: '16px',
				gap: '8px',
				minWidth: '195px',
			}}
		>
			<div className={cx('', styles.textNoWrap, styles.fontBold)}>{t(label)}</div>
			{ items?.length ?
			<div className="flexColumn">
				{ items.map((item, index) => {
					return (
						<div className="flexRow gap8 nowrap" key={index}>
							<Typography>{item?.label}: </Typography>
							<div className={cx('grey7', styles.textNoWrap)} >
								{displayValue(item?.value, 0, 0)} {item?.unit}
							</div>
						</div>
					);
				})}
			</div>
				: <div className={cx('', styles.textNoWrap)}>
									{displayValue(value, 0, 0)} {unit}
								</div>
				}
		</div>
	);
};

export default CarbonFootprintTooltip;
