import { Fragment, useEffect, useState } from 'react';

import { css, cx } from '@emotion/css';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import styled from '@mui/material/styles/styled';
import { Search } from 'lucide-react';

import Skeleton from '../../../../../apps/front/src/components/Skeleton';
import { cn, siteColors } from '../../../../../apps/front/src/lib/colors';
import { pxToRem } from '../../../utils/styles';

interface SearchAutocompleteProps {
	isLoading?: boolean;
	setParamsAutocomplete?: (...params: any[]) => any;
	options?: any[];
	placeholder?: string;
	setParams?: (...params: any[]) => any;
	size?: number;
	isClearInputValue?: boolean;
	setIsClearInputValue?: (e: boolean) => void;
	skeleton?: boolean;
}

const styles = {
	autocompleteContainer: css({
		'& .MuiAutocomplete-popper .MuiAutocomplete-option': {
			backgroundColor: 'red',
		},
		'& label.Mui-focused': {
			color: 'transparent',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: 'transparent',
		},
		'& .MuiAutocomplete-popupIndicator': {
			display: 'none',
		},
		'& .MuiOutlinedInput-root': {
			padding: '0px!important',
			width: '297px',
			height: '42px',
			background: '#FFFFFF',
			border: cn('1px solid', siteColors.grey700),
			borderRadius: '8px',
			flex: 'none',
			order: 2,
			alignSelf: 'stretch',
			flexGrow: 0,
			'& fieldset': {
				borderColor: 'transparent',
			},
			'&:hover fieldset': {
				borderColor: 'transparent',
			},
			'&.Mui-focused fieldset': {
				border: cn('1px solid', siteColors.primary),
			},
		},
	}),
	optionContainer: css({
		boxSizing: 'border-box',
		padding: '12px 16px',
		gap: '8px',
		background: '#FFFFFF',
		borderBottom: cn('1px solid', siteColors.grey500),
		flex: 'none',
		order: 0,
		alignSelf: 'stretch',
		flexGrow: 0,
		cursor: 'pointer',
		color: siteColors.text,

		'&:hover': {
			background: siteColors.grey300,
		},
	}),
	content: css({
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		width: '90%',
	}),
	skeleton: css({
		borderRadius: '10px',
	}),
};

const SearchAutocomplete = ({
	isLoading,
	setParamsAutocomplete,
	options = [],
	placeholder,
	size = 10,
	isClearInputValue = false,
	setIsClearInputValue,
	skeleton = false,
}: SearchAutocompleteProps) => {
	const [inputValue, setInputValue] = useState('');
	const [params, setParams] = useState({});

	useEffect(() => {
		if (isClearInputValue) {
			setInputValue('');
			setIsClearInputValue && setIsClearInputValue(false);
		}
	}, [isClearInputValue]);

	const CustomPaper = styled(Paper)(({ theme }) => {
		return {
			border: cn('1px solid', siteColors.primary),
			borderTop: 'none',
			borderTopLeftRadius: 0,
			borderTopRightRadius: 0,
			position: 'relative',
			top: -10,
		};
	});

	const autoCompleteElement = (
		<Autocomplete
			className={styles.autocompleteContainer}
			id="search-bar-controllable"
			PaperComponent={CustomPaper}
			noOptionsText={' '}
			open={false}
			options={options}
			onChange={(event, value: any, reason) => {
				setInputValue(value);
			}}
			autoComplete={false}
			value={inputValue}
			onInputChange={(event, newInputValue) => {
				setParams &&
					setParams({
						...params,
						page: 1,
						size: size,
						input: newInputValue,
					});
				setParamsAutocomplete && setParamsAutocomplete({ input: newInputValue });
				setInputValue(newInputValue);
			}}
			renderInput={(params) => {
				return (
					<TextField
						{...params}
						placeholder={placeholder}
						InputProps={{
							...params.InputProps,
							startAdornment: (
								<InputAdornment position="start">
									{' '}
									<Search css={{ marginLeft: pxToRem(6) }} />
								</InputAdornment>
							),
							endAdornment: (
								<Fragment>
									{isLoading ? <CircularProgress color="inherit" size={20} /> : null}
									{params.InputProps.endAdornment}
								</Fragment>
							),
						}}
					/>
				);
			}}
			renderOption={(props, option) => {
				return (
					<div
						className={cx('flexRow alignCenter', styles.optionContainer)}
						onClick={() => {
							return setInputValue(option);
						}}
					>
						<span className={cx('flexRow alignCenter', styles.optionContainer)}>
							<span className={styles.content}>{option}</span>
						</span>
					</div>
				);
			}}
		/>
	);

	return skeleton ? (
		<Skeleton variant="rounded" animation="wave" className={styles.skeleton}>
			{autoCompleteElement}
		</Skeleton>
	) : (
		autoCompleteElement
	);
};

export default SearchAutocomplete;
