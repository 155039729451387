import { Typography } from '@mui/material';
type UserFieldProps = {
	value: any;
	row: any;
	column: any;
};



const UserField = ({ value, row, column }: UserFieldProps) => {
	const userName = value
		? value?.lastName || value?.firstName
			? `${value?.firstName} ${value?.lastName}`
			: ''
		: '';
	return <Typography>{userName}</Typography>;
};

export default UserField;
