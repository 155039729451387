
import { css, cx } from '@emotion/css';

import Typography from '../../../components/Typography';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { useGetClientAuthQuery, useLogOutMutation } from '../../../lib/react-query/features/auth/auth.hooks';

const styles = {
	container: css({
		marginTop: 32,
	}),
	title: css({
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '24px !important',
		fontStyle: 'normal',
		fontWeight: '400',
		lineHeight: 'normal',
	}),

	content: css({
		a: {
			textDecoration: 'none',
			color: `${siteColors.primary} !important`,
		},
	}),
	button: css({
		cursor: 'pointer',
		color: siteColors.primary,
		textDecorationLine: 'underline',
		textDecorationStyle: 'solid',
		textDecorationSkipInk: 'auto',
		textDecorationThickness: 'auto',
	}),
};

const UserNoCompany = () => {
	const { t }= useTranslation();

	// ------------------------------------------ //
	// ----------- fectch current user ---------- //
	// ------------------------------------------ //
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();
	// -------------------------------------- //
	// ----------- logout ------------------- //
	// -------------------------------------- //
	const {
		result: { mutate: logOut },
	} = useLogOutMutation();


	return (
		<div className={cx('flexColumn width100 gap32', styles.container)}>
			<Typography className={cx('grey9', styles.title)}>{t('You are not associated with any organization.')}</Typography>
			<div className="flexColumn gap16">

				<div
					dangerouslySetInnerHTML={{
						__html: t('subtile-no-company', { email: authData?.user?.email }),
					}}
					className={cx('grey9', styles.content)}
				/>
				<Typography className="grey9">{t('Si votre entreprise est cliente de Carbon Maps, contactez votre administrateur et partagez votre adresse email')}</Typography>
				<Typography className="grey9">{t('Sinon, prenez contact avec notre support.')}</Typography>
			</div>
			<div className={styles.button} onClick={() =>{ logOut();}}>{t('logout-text')}</div>
		</div>
	);

};


export default UserNoCompany;
