import Modelization from '../../../../../apps/front/src/components/supplier/Modelization';

const ModelizationRow = (props: any) => {
	let {
		value,
		// row,
		// column
	} = props;
	if (!value) value = 1;

	return <Modelization value={value} />;
};

export default ModelizationRow;
