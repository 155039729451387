import { ComponentProps } from 'react';

import Box from '@mui/material/Box';
import MUILink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { useQuery } from '@tanstack/react-query';
import { ArrowUpRight, Box as BoxIcon } from 'lucide-react';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { getProductTableDataActionPackagingUses } from '@carbonmaps/ui/actions/product.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import BlockCard from '../../../components/BlockCard';
import BlockListItem from '../../../components/BlockListItem';
import BlockTitle from '../../../components/BlockTitle';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { useGetPackagingSheetTimePeriod } from '../../../lib/react-query/features/packaging/packaging.hooks';

const PackagingDataBlock = () => {
	const params = useParams(); // * { packagingId: string; tab: string }
	const theme = useTheme();

	// translation
	const { t } = useTranslation();

	const { data /* , isLoading, isError, error */ } = useGetPackagingSheetTimePeriod({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.packagingId!,
		// period: 2023,
	});

	const { indicator } = useApp();
	const { selectedYear } = useYearSelection();
	const packagingId = params.packagingId;

	const {
		data: products,
		// isLoading: _isLoading,
		// isSuccess: isSearchSuccess,
	} = useQuery({
		queryKey: [
			'getProductTableDataPackagingUses',
			{
				input: '',
				page: 1,
				size: 10_000,
				facetFilters: [],
				supplierIds: [],
				viewMode: indicator,
				selectedYear,
				packagingId,
			},
		],
		queryFn: getProductTableDataActionPackagingUses,
	});

	const formedData: ComponentProps<typeof BlockListItem>[] = [
		{
			label: 'uses',
			// value: formatNumber(data?.countUse, undefined, 0),
			value: formatNumber(products?.meta.count, undefined, 0),
			link: `${FRONT_PATH_NAMES.packagings.details}/${params.packagingId}/usage`,
		},
		{
			label: 'Volume',
			value: data?.volume !== 0 ? (data?.volume < 0 ? '-' : `${formatNumber(data?.volume)}`) : '-', // kg
			unity: data?.volume && data?.volume > 0 ? 'T' : '',
		},
	];

	return (
		<>
			<Box className="flexRow" gap={'8px'}>
				<BoxIcon color={theme.palette.grey[700]} height={20} width={20} />
				<BlockTitle variant="h3" color={theme.palette.common.black}>
					{t('packaging-data-card-title')}
				</BlockTitle>
			</Box>
			<BlockCard>
				{formedData.map(({ label, value, unity, link }, index) => {
					return (
						<Box key={index} mb="6px" display="flex" alignItems="center" justifyContent="space-between">
							<Box display="flex" alignItems="center">
								<Typography mr="6px" color={siteColors.grey700} fontSize={'14px'} fontWeight={600}>
									{t(label as any)}
								</Typography>
								<Typography mr="6px" className="number">
									{value} {unity ? unity : ''}
								</Typography>
							</Box>
							{link && (
								<Box display="flex" alignItems="center">
									<MUILink component={RouterLink} to={link}>
										<ArrowUpRight
											size={20}
											color={theme.palette.grey[700]}
											css={{ '&:hover': { stroke: theme.palette.primary.main } }}
										/>
									</MUILink>
								</Box>
							)}
						</Box>
					);
				})}
			</BlockCard>
		</>
	);
};

export default PackagingDataBlock;
