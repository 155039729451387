import { ReactElement } from 'react';

import { css, cx } from '@emotion/css';
import { type HeaderContext, type RowData } from '@tanstack/react-table';
import _ from 'lodash';

import { ReactComponent as SortDownIcon } from '@carbonmaps/media/icons/sort-down.svg';
import { ReactComponent as SortListIcon } from '@carbonmaps/media/icons/sort-list.svg';
import { ReactComponent as SortUpIcon } from '@carbonmaps/media/icons/sort-up.svg';

import Unit, { Measure } from '../units/Unit';

type Props<TData extends RowData> = {
	label: React.ReactNode;
	withPlaceholderUnit?: boolean;
	error?: React.ReactNode;
	disableSort?: boolean;
	valueType?: 'string' | 'number';
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	info?: HeaderContext<TData, any>;
} & (
	| {
			variant: 'measure';
			measure: Measure;
	  }
	| {
			variant: 'unit';
			unit: ReactElement;
	  }
	| {
			variant?: undefined;
	  }
);

const styles = {
	containerText: css({
		marginRight: '6px',
	}),
	otherContainer: css({
		marginLeft: '6px',
	}),
	text: css({
		margin: '0',
		fontWeight: 600,
		fontStyle: 'normal',
		fontSize: '0.8125rem',
		textTransform: 'uppercase',
		lineHeight: '1',
	}),

	unit: css({
		lineHeight: '1.5 !important',
	}),

	ifCanBeSorted: css({
		cursor: 'pointer',
	}),

	widthFitContent: css({
		width: 'fit-content',
	}),

	marginLeft: css({
		marginLeft: '4px',
	}),
	marginRight: css({
		marginRight: '4px',
	}),
};

const TableHeadCellSection = <TData extends RowData>({
	label,
	withPlaceholderUnit = true,
	valueType = 'number',
	info,
	...props
}: Props<TData>) => {
	const getSortIcon = () => {
		if (!info?.column.getCanSort()) {
			return null;
		}

		return info.column.getIsSorted()
		? (
			{
				asc: <SortUpIcon />,
				desc: <SortDownIcon />,
			}[_.toString(info.column.getIsSorted())]
		)
		: (
			<SortListIcon />
		);
	};

	const sortIcon = getSortIcon();

	return (
		<div onClick={info?.column.getToggleSortingHandler()} className={cx('alignRight')}>
			<div className={cx('', styles.widthFitContent, info?.column.getCanSort() ? styles.ifCanBeSorted : '')}>
				<div className={cx('flexRow alignCenter nowrap justifyEnd' /* , className */)}>
					{valueType === 'string' ? sortIcon : null}
					<p
						className={cx(
							'table-header-component-label-section grey700',
							styles.text,
							valueType === 'string' ? styles.marginLeft : '',
							valueType === 'number' ? styles.marginRight : '',
						)}
					>
						{label}
					</p>
					{valueType === 'number' ? sortIcon : null}
				</div>
				{props.variant === 'measure' && (
					<Unit component="p" variant="caption" className={cx('grey700', styles.unit)} measure={props.measure} />
				)}
				{props.variant === 'unit' && props.unit}
			</div>
		</div>
	);
};

export default TableHeadCellSection;
