import { css, cx } from '@emotion/css';

import SEO from '../../SEO';

type ContainerProps = {
	header: React.ReactNode;
	children: React.ReactNode;
	actionFilter?: React.ReactNode;
	graph?: React.ReactNode;
	seoText?: string;
	containedContent?: boolean;
	contentContainerClassName?: string;
	kpi?: React.ReactNode;
};

const containerCss = {
	gap: css({
		gap: 32,
	}),
	graphContainer: css({
		borderRadius: 16,
	}),
};

const Container = ({
	header,
	actionFilter,
	graph,
	kpi,
	children,
	seoText,
	containedContent = true,
	contentContainerClassName,
}: ContainerProps) => {
	return (
		<>
			<SEO title={seoText} />
			{header}
			{actionFilter}
			<div className={cx('flexColumn alignCenter', containerCss.gap)}>
				{kpi && (<div className={cx('widthContainer')}>
						{kpi}
					</div>)}
				{graph && (
					<div className={cx('flexRow alignCenter justifyCenter widthContainer', containerCss.graphContainer)}>
						{graph}
					</div>
				)}
				<div className={cx(containedContent ? 'widthContainer' : contentContainerClassName ?? '')}>{children}</div>
			</div>
		</>
	);
};

export default Container;
