import { useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { CircularProgress } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import { type TFunction } from 'i18next';
import _ from 'lodash';
import { Minus, Plus } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { classNames } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { displayValue } from '@carbonmaps/ui/utils/numberFormat';

import ImpactCell from '../../../../../../components/_comp/ImpactCell';
import IntensityCell from '../../../../../../components/_comp/IntensityCell';
import { SupplierIcon } from '../../../../../../components/_comp/ItemIcon';
import Table from '../../../../../../components/_comp/Table';
import TableHeadCell from '../../../../../../components/_comp/TableHeadCell';
import LabelLogo from '../../../../../../components/question/LabelLogo';
import { useYearSelection } from '../../../../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../../../lib/colors';
import { useGetClientAuthQuery } from '../../../../../../lib/react-query/features/auth/auth.hooks';
import { addSupplierPreCampaignAction, removeSupplierPreCampaignAction } from '../../../../../../lib/react-query/features/question/question.actions';
import { useFindSuppliersTable } from '../../../../../../lib/react-query/features/supplier/supplier.hooks';
import SuppliersFilter from '../../../../../supplier/general/tabs/impacts/SuppliersFilter';
import StatusChip from '../components/StatusChip';

export type SupplierContactSubRowData = {
	id: string;
	supplierName: string;
	supplierUid: string;

	contactFirstName?: string;
	contactLastName?: string;
	contactEmail?: string;
	contactLanguage?: string;

	isEdited?: boolean;
};

export type SupplierContactRowData = {
	id: string;
	supplierUid: string;
	supplierName: string;
	subRows: SupplierContactSubRowData[];
};

type SupplierRowData = {
	id: string;
	name: string;
	uid: string;
	intensity?: number;
	intensityPercent?: number;
	impact?: number;
	impactPercent?: number;
	countContact?: number;
	isContacted?: boolean;
	volume?: number;
	isAdded?: boolean;
};

const tableStyles = {
	main: css({
		width: '100%',
		borderCollapse: 'collapse',
		'& tr': {
			height: '60px',
			alignSelf: 'stretch',
		},
		'& tbody tr': {
			borderTop: cn('1px solid', siteColors.grey500),
		},
		'.labelLogoText': {
			maxWidth: '200px',
		},
		'& tr > th:nth-of-type(1), & tr > td:nth-of-type(1)': {
			width: 300,
		},
		'& tr > th:not(:nth-of-type(1)) .alignRight': {
			display: 'flex!important',
			justifyContent: 'flex-end',
		},
	}),
	loading: css({
		opacity: 0.5,
		pointerEvents: 'none',
	}),

	text1: css({
		color: siteColors.primary,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '24px',
		fontStyle: 'normal',
		fontWeight: 600,
		lineHeight: 'normal',
		marginBottom: 32,
	}),
	hiddenVisibility: css({
		visibility: 'hidden',
	}),
};

const columnHelper = createColumnHelper<SupplierRowData>();

export type AddSupplierWithContacts = (value: SupplierContactRowData) => void;
export type RemoveSupplierContactById = (options: { supplierId: string; contactId: string }) => void;

type GetColumnsProps = {
	t: TFunction;
	addSupplier: any;
	removeSupplier: any;
	questionFormId?: string;
};

const getColumns = ({
	t,
	addSupplier,
	removeSupplier,
	questionFormId,
}: GetColumnsProps) => {

	return [
		columnHelper.accessor('name', {
			header: (info) => {
				return <TableHeadCell info={info} label={t('supplier-label')} valueType="string" />;
			},
			cell: (info) => {
				return <LabelLogo label={info.row.original.name} uid={info.row.original.uid} icon={<SupplierIcon />} />;
			},
		}),
		columnHelper.accessor('intensity', {
			header: (info) => {
				return <TableHeadCell info={info} label={t('Par kilo')} valueType="number" />;
			},
			cell: (info) => {
				return <IntensityCell value={info.getValue()} percentage={info.row.original.intensityPercent} />;
			},
		}),
		columnHelper.accessor('impact', {
			header: (info) => {
				return (
					<TableHeadCell
						info={info}
						label={t('Toutes les années')}
						valueType="number"
						variant="measure"
						measure="carbonImpact"
					/>
				);
			},
			cell: (info) => {
				return (
					<div className={cx('flexRow justifyEnd')}>
						<ImpactCell<SupplierRowData> info={info} value={_.toNumber(info.getValue())} />
					</div>
				);
			},
		}),
		columnHelper.accessor('volume', {
			header: (info) => {
				return <TableHeadCell info={info} label={t('volume')} valueType="number" variant="measure" measure="massTon" />;
			},
			cell: (info) => {
				return (
					<div className={cx('flexRow justifyEnd')}>
						<div>{displayValue(info.getValue())}</div>
					</div>
				);
			},
		}),
		columnHelper.accessor('countContact', {
			header: (info) => {
				return <TableHeadCell info={info} label={t('Contacts')} valueType="number" variant="measure" measure="massTon" />;
			},
			cell: (info) => {
				return (
					<div className={cx('flexRow justifyEnd')}>
						<div>{displayValue(info.getValue(), undefined, 0)}</div>
					</div>
				);
			},
		}),
		columnHelper.accessor('isAdded', {
			header: (info) => {
				return <TableHeadCell info={info} label={t('ajouté')} valueType="string" />;
			},
			cell: (info) => {
				return (
					<>{info.row.original.isContacted
						?
						<div className={cx('flexRow justifyEnd')}><StatusChip statusType={t('supplier_contacted')} /></div> :
						(
							<div className={cx('flexRow justifyEnd')}>
								{info.row.original.isAdded
									? (
										<BButton
											variant="primary"
											iconLeft={<Minus />}
											label={t('Retirer')}
											onClick={() => {
												removeSupplier({ supplier: _.pick(info.row.original, ['id', 'uid', 'name']), questionFormId });
											}}
										/>
									)
									: (
										<BButton
											variant="secondary"
											iconLeft={<Plus />}
											label={t('Ajouter')}
											onClick={async () => {
												addSupplier({ supplier: _.pick(info.row.original, ['id', 'uid', 'name']), questionFormId });
											}}
										/>
									)}
							</div>)
					}</>
				);
			},
		}),
	];
};

const QuestionFormSuppliersTableSelect = () => {
	const [data, setData] = useState<SupplierRowData[]>([]);
	const params = useParams();
	const queryClient = useQueryClient();

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const { mutate: addSupplier, isLoading: isLoadingAdd } = useMutation({
		mutationKey: ['addSupplier'] as const,
		mutationFn: addSupplierPreCampaignAction,
		onSuccess(data, variables, context) {
			queryClient.invalidateQueries({ queryKey: ['findSupplierTable'] });
			queryClient.invalidateQueries({ queryKey: ['getPreCampaignSuppliersContacts'] });
			queryClient.invalidateQueries({ queryKey: ['findSuppliersTracking'] });
		},
	});

	const { mutate: removeSupplier, isLoading: isLoadingRemove } = useMutation({
		mutationKey: ['removeSupplier'] as const,
		mutationFn: removeSupplierPreCampaignAction,
		onSuccess(data, variables, context) {
			queryClient.invalidateQueries({ queryKey: ['findSupplierTable'] });
			queryClient.invalidateQueries({ queryKey: ['getPreCampaignSuppliersContacts'] });
			queryClient.invalidateQueries({ queryKey: ['findSuppliersTracking'] });
		},
	});


	const { t } = useTranslation();

	const columns = useMemo(() => {
		return getColumns({
			t: t as never,
			addSupplier,
			removeSupplier,
			questionFormId: params?.questionFormId,
		});
	}, [addSupplier, t]);

	// init filters values
	const [searchQueryParams] = useSearchQueryParams();

	const {
		result: { data: findSuppliersData, isLoading: isTableLoading },
	} = useFindSuppliersTable({
		paramsTable: {
			// input: searchQueryParams?.input || '',
			// facetFilters: searchQueryParams?.facetFilters || [],
			...(searchQueryParams?.pageKey === 'modal_pre_campaign'
				? {
					input: searchQueryParams?.input || '',
					facetFilters: searchQueryParams?.facetFilters || [],
				}
				: {}),
			selectedYear: -1, // get all supplier for all year
			questionFormId: _.toString(params.questionFormId),
			isPreCampaign: true,
		},
	});


	useEffect(() => {
		if (!findSuppliersData) {
			return;
		}

		const maxIntensity = _.maxBy(findSuppliersData.suppliers as any[], 'carbonIntensity')?.carbonIntensity;

		const newData: SupplierRowData[] = _.get(findSuppliersData, 'suppliers', []).map((item: any) => {

			return {
				id: item.objectId,
				name: item.name,
				uid: item.uid,
				intensity: item.carbonIntensity,
				intensityPercent: ((item.carbonIntensity || 0) * 100) / maxIntensity,
				impact: item.carbonImpact,
				impactPercent: item.carbonImpactPercent,
				countContact: item?.countContact,
				volume: item.volume ?? item.tonnage,
				isContacted: item?.isContacted,
				isAdded: item?.isAdded || false,
			};
		});

		setData(newData);
	}, [findSuppliersData]);

	return (
		<div>
			<div className={cx('', tableStyles.text1)}>{t('Ajouter des fournisseurs')}</div>
			<div
				className={cx('flexRow alignCenter')}
				css={{
					marginBottom: 32,
					gap: 24,
					'& .filterContainer-wrapper': {
						paddingLeft: 0,
						paddingRight: 0,
					},
				}}
			>
				<SuppliersFilter withMore={false} pageKey={'modal_pre_campaign'} />
			</div>

			<div css={{ position: 'relative' }}>
				{isTableLoading
					? (
						<CircularProgress
							sx={{
								position: 'absolute',
								top: 100,
								left: '0',
								right: '0',
								marginLeft: 'auto',
								marginRight: 'auto',
								width: '100px',
							}}
						/>
					)
					: null}
				<Table<SupplierRowData>
					data={data}
					columns={columns}
					tableProps={{ className: cx('', tableStyles.main, isTableLoading || isLoadingAdd || isLoadingRemove ? tableStyles.loading : '') }}
				/>
			</div>
		</div>
	);
};

export default QuestionFormSuppliersTableSelect;
