import { useState } from 'react';

import { css, cx } from '@emotion/css';
import { zodResolver } from '@hookform/resolvers/zod';
import { Autocomplete, CircularProgress, IconButton, TextField } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { Check, Edit, Trash2 } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import z from 'zod';

import { classNames, questionsStatusType } from '@carbonmaps/shared/utils/constants';
import BInput from '@carbonmaps/ui/components/saas/BInput';

import { useTranslation } from '../../../../../../../../hooks/useTranslation';
import { siteColors } from '../../../../../../../../lib/colors';
import { useGetClientAuthQuery } from '../../../../../../../../lib/react-query/features/auth/auth.hooks';
import { EMAIL_LANGUAGES_SUPPLIER } from '../../../../../../../../utils/constants';
import StatusChip from '../../StatusChip';

const contactSchema = z.object({
	firstName: z.string().min(1),
	lastName: z.string().min(1),
	email: z.string().email(),
	language: z.string().min(1),
});

const styles = {
	input: css({
		'.MuiInputBase-root': {
			borderRadius: '4px!important',
		},
	}),
	inputDisabled: css({
		'.MuiInputBase-root': {
			borderRadius: '4px!important',
			overflow: 'hidden',
		},
		'& .MuiInputBase-input': {
			padding: '10.5px 10px',
			background: '#F4F2F7!important',
		},
		fieldset: {
			borderRadius: '4px!important',
			border: '1px solid #F4F2F7!important',
		},
		'& .MuiInputBase-input.Mui-disabled': {
			WebkitTextFillColor: '#474154',
		},
	}),

	rowFromContainer: css({
		gap: 8,
	}),
	select: css({
		fieldset: {
			border: '1px solid #9587AE',
			borderRadius: '4px!important',
		},
		'.MuiOutlinedInput-root': {
			padding: '3px 22px 3px 12px !important',
		},
	}),
	selectDisabled: css({
		pointerEvents: 'none',
		'.MuiAutocomplete-inputRoot': {
			background: '#F4F2F7!important',
			borderRadius: '4px!important',
		},
		fieldset: {
			border: '1px solid #F4F2F7',
			borderRadius: '4px!important',
		},
		'.MuiOutlinedInput-root': {
			padding: '3px 22px 3px 12px !important',
		},
	}),

	flexGrow: css({
		flexGrow: 1,
	}),
	emailInputMaxWidth: css({
		maxWidth: 430,
		minWidth: 300,
	}),

	noPointerEvents: css({
		pointerEvents: 'none',
	}),
};

function sortObjectKeysAlphabetically(obj: Record<string, any>) {
	return _.fromPairs(
		_.sortBy(
			_.toPairs(obj),
			([key]) => { return key; },
		),
	);
}

type SupplierContactSubRowData = {
	firstName: string;
	lastName: string;
	email: string;
	language: string;
};

type Props = {
	data: SupplierContactSubRowData;
	supplierUid: string;
	onSubmit?: (data: Record<string, any>) => void;
	statusType: string;
	isEditing?: boolean;
	objectId?: string;
	tempId?: string;
	removeTempContact: (tempId: string) => void;
	addTempContactToSave: (data: Record<string, any>) => void;
	addContactToSave: (data: Record<string, any>) => void;
	removeContactToSave: (tempId: string) => void;
	updateEditingStatus: (tempId: string, status: boolean) => void;
	existingEmails: string[];
};

const SupplierContactRowForm = ({
	data,
	supplierUid,
	onSubmit,
	statusType,
	isEditing,
	objectId,
	removeTempContact,
	tempId,
	addTempContactToSave,
	addContactToSave,
	removeContactToSave,
	updateEditingStatus,
	existingEmails,
}: Props) => {
	const { t } = useTranslation();
	const [editMode, setEditMode] = useState(() => {
		return Boolean(isEditing);
	});
	const [existingEmailError, setExistingEmailError] = useState(false);
	const params = useParams();
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const isEditable = _.isEqual(statusType, questionsStatusType.SUPPLIER_NOT_CONTACTED) || _.isEqual(statusType, questionsStatusType.NO_CONTACT);

	const labels = {
		en: t('Anglais'),
		fr: t('Français'),
		it: t('Italien'),
		es: t('Espagnol'),
		de: t('Allemand'),
	};

	const initialValues = sortObjectKeysAlphabetically({
		..._.pick(data, ['email', 'firstName', 'lastName', 'language']),
		language: _.get(data, 'language', 'en'),
	});

	const str_initialValues = JSON.stringify(initialValues);

	const form = useForm({
		resolver: zodResolver(contactSchema),
		defaultValues: initialValues,
	});

	const _onSubmit = form.handleSubmit(
		async (values) => {
			if (!objectId && existingEmails.includes(values.email)) {
				setExistingEmailError(true);
				return;
			}

			setExistingEmailError(false);

			onSubmit?.(values);

			if (!objectId) {
				addTempContactToSave({
					tempId: tempId || '',
					firstName: values.firstName,
					lastName: values.lastName,
					email: values.email,
					language: values.language,
				});
			} else {
				const isDirty = !_.isEqual(str_initialValues, JSON.stringify(sortObjectKeysAlphabetically(values)));

				if (isDirty) {
					addContactToSave({
						objectId,
						firstName: values.firstName,
						lastName: values.lastName,
						email: values.email,
						language: values.language,
					});
				} else {
					removeContactToSave(objectId);
				}
			}

			setEditMode(false);
			updateEditingStatus(objectId || tempId || '', false);
			// editModeItem = false;
			// setEditStatus(objectId || tempId || '', false);
		},
		async (values) => {
			console.log('error', values);
		},
	);

	const queryClient = useQueryClient();
	const { mutate: removeContact, isLoading: isLoadingDelete } = useMutation({
		mutationKey: ['removeNotYetContactedContactForSupplier'],
		mutationFn: async (objectId: string) => {
			try {
				const obj = new Parse.Object(classNames.QUESTION_FORM_STATUS_UPDATE);
				obj.id = objectId;
				//---------------------------------------------------------------//
				//------- check other contact in the same company and question---//
				//---------------------------------------------------------------//
				const company = authData?.session.company;
				const companyObject = new Parse.Object(classNames.COMPANY);
				companyObject.id = company?.objectId ?? '';

				const questionForm = new Parse.Object(classNames.QUESTION_FORM);
				questionForm.id = params.questionFormId ?? '';
				const query = new Parse.Query(classNames.QUESTION_FORM_STATUS_UPDATE);
				query.equalTo('company', companyObject);
				query.notEqualTo('contact.email', data.email);
				query.equalTo('questionForm', questionForm);
				query.equalTo('supplier.uid', supplierUid);
				query.containedIn('statusType', [
					questionsStatusType.SUPPLIER_CONTACTED,
					questionsStatusType.SUPPLIER_NOT_CONTACTED,
					questionsStatusType.NO_CONTACT]);
				const countOtherContact = await query.count();

				//---------------------------------------------------------------//
				//-----------if the last update don't remove --------------------//
				//---------------------------------------------------------------//
				if (countOtherContact === 0) {
					console.log('no_contact');
					obj.set('statusType', 'no_contact');
					obj.unset('contact');
					await obj.save();
					form.setValue('firstName', '');
					form.setValue('lastName', '');
					form.setValue('email', '');
				}
				//---------------------------------------------------------------//
				//----------------------- else destroy --------------------------//
				//---------------------------------------------------------------//
				else {
					console.log('ooooooooooooooooooooooo');
					await obj.destroy();
				}

				return { ok: 'ok' };
			} catch (error) {
				console.log('error', error);
				return Promise.reject(error);
			}
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries(['findSuppliersTracking']);
			await queryClient.invalidateQueries(['contacts']);
		},
	});

	const renderEditButton = (
		<IconButton
			disableRipple
			disableTouchRipple
			sx={{
				backgroundColor: 'transparent',
				color: siteColors.grey700,
				borderRadius: '4px',
				'&:hover': { backgroundColor: 'transparent' },
			}}
			className={cx(isEditable && !editMode ? '' : 'hidden', isEditable && !editMode ? '' : styles.noPointerEvents)}
			onClick={() => {
				setEditMode(true);
				updateEditingStatus(objectId || tempId || '', true);
			}}
		>
			<Edit />
		</IconButton>
	);

	const renderCheckButton = (
		<IconButton
			disableRipple
			disableTouchRipple
			sx={{
				backgroundColor: siteColors.primary,
				color: 'white',
				borderRadius: '4px',
				'&:hover': { backgroundColor: siteColors.primaryHover },
			}}
			className={cx(isEditable && editMode ? '' : 'hidden', isEditable && editMode ? '' : styles.noPointerEvents)}
			onClick={() => {
				_onSubmit?.();
			}}
		>
			<Check />
		</IconButton>
	);
	return (
		<div>
			<div css={{ color: 'red', fontSize: 12, marginBottom: 8 }} className={cx(existingEmailError ? '' : 'display-none')}>
				{t('email-exists-error')}
			</div>
			<div className={cx('flexRow alignCenter justifyBetween', styles.rowFromContainer)}>
				<div className="flexRow alignCenter" style={{ gap: 8 }}>
					<BInput
						disabled={!editMode}
						placeholder={t('first-name-label')}
						{...form.register('firstName')}
						className={cx('', !editMode ? styles.inputDisabled : styles.input)}
					/>
					<BInput
						disabled={!editMode}
						placeholder={t('last-name-label')}
						{...form.register('lastName')}
						className={cx('', !editMode ? styles.inputDisabled : styles.input)}
					/>
					<BInput
						disabled={!editMode}
						placeholder={`${t('input-email-label')} (${t('obligatoire')})`}
						{...form.register('email')}
						className={cx(
							'',
							!editMode ? styles.inputDisabled : styles.input,
							styles.flexGrow,
							styles.emailInputMaxWidth,
						)}
					/>

					<Autocomplete
						sx={{ width: '120px' }}
						className={cx('', editMode ? styles.select : styles.selectDisabled)}
						options={EMAIL_LANGUAGES_SUPPLIER}
						{...form.register('language')}
						defaultValue={form.control._defaultValues.language || 'en'}
						disableClearable
						onChange={(_event: any, newValue: string | null | never) => {
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							form.setValue('language', newValue!);
						}}
						getOptionLabel={(option) => {
							return t((labels as any)[option as any] as any) || labels['en'];
						}}
						renderInput={(params) => {
							return <TextField {...params} fullWidth />;
						}}
					/>
					{isEditable && editMode ? renderCheckButton : null}
					{isEditable && !editMode ? renderEditButton : null}
				</div>

				<div className="flexRow alignCenter" style={{ gap: 4 }}>
					{statusType !== 'no_contact' && <StatusChip statusType={statusType} />}
					<IconButton
						className={cx(isEditable ? '' : 'hidden', isEditable ? '' : styles.noPointerEvents)}
						disableRipple
						disableTouchRipple
						onClick={() => {
							if (!objectId) {
								removeTempContact(tempId || '');
							} else {
								removeContact(objectId);
							}
						}}
					>
						{isLoadingDelete ? <CircularProgress size={20} /> : (statusType === 'no_contact' ? <></> : <Trash2 size={20} color={siteColors.grey700} />)}
					</IconButton>
				</div>
			</div>
		</div>
	);
};

export default SupplierContactRowForm;
