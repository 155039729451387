import { ReactNode } from 'react';

import { css, cx } from '@emotion/css';
import Box, { type BoxProps } from '@mui/material/Box';
import Typography, { type TypographyProps } from '@mui/material/Typography';
import styled from '@mui/material/styles/styled';
import _ from 'lodash';

import { pxToRem } from '@carbonmaps/ui/utils/styles';

type Props = {
	icon: ReactNode;
	label: ReactNode;
	value: ReactNode;
	lastItem?: boolean;
	isNegativeNumber?: boolean | undefined;
	link?: string | undefined;
	unity?: string;
	description?: string;
	order?: number;
	isDisabled?: boolean;
} & BoxProps;

const styles = {
	icon: css({
		width: 32,
		height: 32,
	}),
};

function formatScientificNotation(input: any) {
	let stringValue = input;

	if (!_.isString(input)) {
		if (!_.isNumber()) {
			stringValue = '-';
		}

		stringValue = _.toString(input);
	}

  // Match the base and exponent parts of the scientific notation
  const match = stringValue.match(/([\d.]+)e([+-]?\d+)/);

  if (!match) {
    return stringValue; // Return the input as is if it doesn't match the format
  }

  const [, base, exponent] = match; // Destructure the base and exponent
  return (
    <>
      {_.replace(base, '.', ',')} &times; 10
      <sup>{exponent}</sup>
    </>
  );
}

const OtherIndicatorItem = ({ isDisabled = false, lastItem = false, icon, label, value, isNegativeNumber, link, unity, description, ...props }: Props) => {
	return (
		<Container lastItem={lastItem}>
			{!isDisabled &&
				<>
					<div className='flexColumn gap15'>
						<div className={cx(styles.icon)}>
							{icon}
						</div>
						<div className='flexColumn gap12'>
							<Label>{label}</Label>
							{value !== 0
? <div className='flexRow nowrap alignCenter' css={{ gap: '16px' }}>
								<Value css={{ whiteSpace: 'nowrap' }}>
									{formatScientificNotation(value)}
								</Value>
								<Unity css={{  position: 'relative', top: '2px' }}>
									{unity}
								</Unity>
							</div>
: <>-</>}
						</div>
					</div>
					<Description>
						{description}
					</Description>
				</>
			}

		</Container>

	);
};

export default OtherIndicatorItem;

const Container = styled(Box)<BoxProps & { lastItem?: boolean }>(({ theme, lastItem }) => {
	return {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flexStart',
		flexWrap: 'nowrap',
		gap: 16,
		flex: '1 1 calc(33.33% - 10px)',
		padding: pxToRem(24),
		borderBottom: lastItem ? 'none' : `1px solid ${theme.palette.grey[500]}`, // Pas de bordure pour les 3 derniers items

	};
});

const Label = styled(Typography)<TypographyProps>(({ theme }) => {
	return {
		color: theme.palette.grey[900],
		width: pxToRem(200),
		fontSize: '14px',
		fontWeight: 500,
		lineHeight: '150%',
		textEdge: 'cap',
	};
});


const Value = styled(Typography)<TypographyProps>(({ theme }) => {
	return {
		color: theme.palette.grey[900],
		width: pxToRem(50),
		fontSize: '12px',
		fontWeight: 400,
		textOverflow: 'ellipsis',
		lineHeight: '140%',
		textEdge: 'cap',
	};
});

const Unity = styled(Typography)<TypographyProps>(({ theme }) => {
	return {
		color: theme.palette.grey[800],
		width:'100%',
		fontSize: '12px',
		fontWeight: 400,
		textOverflow: 'ellipsis',
		lineHeight: '140%',
		textEdge: 'cap',
	};
});

const Description = styled(Typography)<TypographyProps>(({ theme }) => {
	return {
		color: theme.palette.grey[800],
		width: '100%',
		fontSize: '12px',
		fontWeight: 400,
		lineHeight: '140%',
		textEdge: 'cap',
	};
});
