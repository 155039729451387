import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more'; // Pour les graphiques polaires
import _ from 'lodash';

import { useTranslation } from '../hooks/useTranslation';

export interface Idata {
	label: string;
	value: number;
}

const PolarChart = ({
	currentSupplierName,
	dataAvg,
	data,
	withLegend = true,
	withAvg = true,
}: {
	currentSupplierName?: string;
	dataAvg?: Idata[];
	data: Idata[];
	withLegend?: boolean;
	withAvg?: boolean;
}) => {
	const { t } = useTranslation();

	const dataCustom = data.filter((d) => {
		return d.label !== undefined;
	}); //don't order

	if (!dataCustom) return null;

	// Étape 1: Trouver la valeur maximale
	const maxValue = _.maxBy(dataCustom, 'value')?.value;
	if (!maxValue) return null;

	const finalData: any = dataCustom.map((category: Idata, index: number) => {
		return Math.floor(category.value);
	});

	const dataExternal = dataCustom.map((category: Idata, index: number) => {
		return {
			y: 5,
			name: category.label,
		};
	});

	const categories = dataCustom.map((category: Idata, index: number) => {
		return category.label;
	});

	// Configuration des intervalles
	const tickInterval = 360 / dataExternal.length;

	const mapCat = new Map();

	// Récupérer tous les intervalles pour ordonner les labels
	const intervals = [];

	for (let i = 0; i < categories.length; i++) {
		mapCat.set((i * tickInterval).toString(), categories[i]);
		intervals.push(i * tickInterval);
	}

	const series = [


		{
			type: withAvg ? 'line' : 'area',
			name: currentSupplierName && currentSupplierName !== '---' ? currentSupplierName : t('Moyenne des fournisseurs'),
			data: finalData,
			color: '#7176FF',
			fillColor: 'rgba(113, 118, 255, 0.5)', // Couleur avec transparence
			lineWidth: 2,
			marker: {
				enabled: true, // Désactiver les marqueurs de points
				states: {
					hover: {
						enabled: true, // Désactiver le point au survol
					},
				},
			},

			plotOptions: {
				area: {
					fillOpacity: 0.9, // Opacité des zones
				},
			},
		},
	];

	if (withAvg) {
		// const maxValue = _.maxBy(dataAvg, 'value')?.value || 1;
		const dataMoyenne = dataAvg
			?.filter((d) => {
				return d.label !== undefined;
			})
			?.map((category: Idata, index: number) => {
				return Math.floor(category.value);
			});
		series.push({
			type: 'line',
			name: t('Moyenne dans votre catégorie'),
			data: dataMoyenne,
			color: '#5F80AD',
			lineWidth: 2,
			dashStyle: 'shortDash',
			marker: {
				enabled: true, // Désactiver les marqueurs de points
				states: {
					hover: {
						enabled: true, // Désactiver le point au survol
					},
				},
			},
		} as any);
	}

	const options = {
		chart: {
			polar: true,
			type: 'line',
			responsive: true,
		},
		title: {
			text: '',
		},
		plotOptions: {
			series: {
				states: {
					hover: {
						enabled: false, // Désactive le survol pour toutes les séries
					},
					inactive: {
						enabled: false, // Empêche de rendre les autres séries inactives au survol
					},
				},
			},
			area: {
				states: {
					hover: {
						enabled: false, // Désactive l'état de survol
					},
				},
				fillOpacity: 0.9, // Opacité des zones
				marker: {
					enabled: false, // Désactiver les marqueurs de points
				},
			},
			line: {
				marker: {
					enabled: false, // Désactiver les marqueurs de points
				},
			},
		},

		tooltip: {
			shared: true, // Afficher les valeurs de toutes les séries dans la tooltip
			enabled: true, // Désactive la tooltip
			pointFormat: '<div style="color:{series.color};">{series.name}: <b>' +
            '{point.y:,.0f}%</b></div><br/>',
		},

		legend: {
			enabled: withLegend,
			align: 'left',
			verticalAlign: 'bottom',
			layout: 'horizontal',
		},

		pane: {
			size: '100%',
		},
		xAxis: {
			categories,
			tickmarkPlacement: 'off',
			lineWidth: 0,
			gridLineWidth: 1, // Désactiver les lignes de grille
			labels: {
				style: {
					color: '#3E3A45', // Changer la couleur des labels ici
					fontSize: '14px', // Optionnel: changer la taille de la police
					fontFamily: 'Inter', // Optionnel: changer la police
				},
			},
		},
		yAxis: {
			 tickPositions: [0, 25, 50, 75, 100],
			gridLineInterpolation: 'polygon',
			lineWidth: 0,
			max: 100,
			ceiling:100,
			labels: {
				enabled: false,
			},
		},
		series,
		credits: {
			enabled: false, // Désactiver le logo Highcharts
		},
	};

	HighchartsMore(Highcharts);

	return (
		<div>
			<HighchartsReact highcharts={Highcharts} options={options} />
		</div>
	);
};

export default PolarChart;
