import { useEffect, useState } from 'react';

import { css, cx } from '@emotion/css';
import { BarChart2 } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useGetProductSheetTimePeriod, useProductImpact } from '@carbonmaps/ui/hooks/useGetProductSheet';
import { displayValue } from '@carbonmaps/ui/utils/numberFormat';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import Skeleton from '../../../components/Skeleton';
import IndicatorsBlockLight, { indicatorConfigItems } from '../../../components/synthese/IndicatorsBlockLight';
import Card from '../../../components/widget/Card';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import ProductModel from '../../../models/Product.model';
import { FULFILLED_MODELIZATION_TYPE } from '../../../utils/supplier';

import IndicatorProductPopover from './IndicatorProductPopover';

const stylesCard = {
	skeleton: css({
		height: '100px !important',
		width: '100% !important',
		flex: 1,
		marginBottom: pxToRem(32),
	} as any),
};

const ProductIndicatorBlock = () => {
	const { indicator } = useApp();
	const params = useParams();

	const [searchQueryParams] = useSearchQueryParams();

	const [productObject, setProductObject] = useState<ProductModel>();
	const { t } = useTranslation();
	const { data, isLoading, isSuccess } = useProductImpact({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.productId!,
		viewMode: indicator,
		uid: searchQueryParams?.uid,
	});

	return (
		<Card
			title={
				indicatorConfigItems.get(indicator)?.indicatorTitle
					? t(indicatorConfigItems.get(indicator)?.indicatorTitle as any)
					: t('Indicateurs carbone')
			}
			icon={<BarChart2 color={siteColors.grey700} size={20} />}
			sx={{ padding: 0, marginBottom: pxToRem(32), flexWrap: 'nowrap', width: '100%' }}
			withBorder={false}
			center={false}
		>
			<IndicatorsBlockLight
				withPopover={false}
				indicator={indicator}
				indicatorDatas={{
					impact: {
						value: displayValue(data && (data[`${indicator}Impact`] as number), undefined, 0) /* ?.split(',')[0] */,
						fullFilledPercent: productObject?.getSupplierFullFilledPercent(),
						suppliersCount: {
							fulfilled: productObject?.getSuppliersCount(FULFILLED_MODELIZATION_TYPE),
							total: productObject?.getSuppliersCount(),
						},
						popoverContent: null,
					},
					intensity: {
						value: displayValue(data && (data[`${indicator}Intensity`] as number)),
						fullFilledPercent: productObject?.getSupplierFullFilledPercent(),
						suppliersCount: {
							fulfilled: productObject?.getSuppliersCount(FULFILLED_MODELIZATION_TYPE),
							total: productObject?.getSuppliersCount(),
						},
						popoverContent: null,
					},
					impactPortion: {
						value: displayValue(data && (data[`${indicator}ImpactPortion`] as number)),
						fullFilledPercent: productObject?.getSupplierFullFilledPercent(),
						suppliersCount: {
							fulfilled: productObject?.getSuppliersCount(FULFILLED_MODELIZATION_TYPE),
							total: productObject?.getSuppliersCount(),
						},
						popoverContent: null,
					},
				}}
				isLoading={isLoading}
			/>
		</Card>
	);
};

export default ProductIndicatorBlock;
