import { useEffect } from 'react';

import _ from 'lodash';

import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import EmbedNotion from '../../components/EmbedNotion';
import SEO from '../../components/SEO';
import { useTranslation } from '../../hooks/useTranslation';


const embedUrl = {
	fr: 'https://v2-embednotion.com/4f09bd1d0c014330b08b00f316a20d1f',
	en: 'https://v2-embednotion.com/12fce5d6e39680d08dfcc43ffedfbe8c',
};

const SectorSheet = () => {
	const { setBreadcrumbs } = useApp();
	const { i18n } = useTranslation();
	const language = getIsoCountryCode(i18n.language);

	useEffect(() => {
		setBreadcrumbs([{ text: 'Fiche filières' }]);
	}, [setBreadcrumbs]);

	return (
		<>
			<SEO title="Fiche Filière - Carbon Maps" />
			<EmbedNotion
				title="sector-sheet-carbonmaps"
				url={_.get(embedUrl, language, _.get(embedUrl, 'fr'))}
			/>
		</>
	);
};

export default SectorSheet;
