import { Fragment, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { ChevronDown, ChevronUp, Leaf } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { ReactComponent as AcidificationTerrestreEauDouceIcon } from '@carbonmaps/media/icons/other-indicator/AcidificationTerrestreEauDouce.svg';
import { ReactComponent as AppauvrissementCoucheOzoneIcon } from '@carbonmaps/media/icons/other-indicator/AppauvrissementCoucheOzone.svg';
import { ReactComponent as EcotoxiciteEauDouceIcon } from '@carbonmaps/media/icons/other-indicator/EcotoxiciteEauDouce.svg';
import { ReactComponent as EpuisementRessourcesEnergetiquesIcon } from '@carbonmaps/media/icons/other-indicator/EpuisementRessourcesEnergetiques.svg';
import { ReactComponent as EpuisementRessourcesMinerauxIcon } from '@carbonmaps/media/icons/other-indicator/EpuisementRessourcesMineraux.svg';
import { ReactComponent as EutrophisationEauDouceIcon } from '@carbonmaps/media/icons/other-indicator/EutrophisationEauDouce.svg';
import { ReactComponent as EutrophisationMarineIcon } from '@carbonmaps/media/icons/other-indicator/EutrophisationMarine.svg';
import { ReactComponent as EutrophisationTerrestreIcon } from '@carbonmaps/media/icons/other-indicator/EutrophisationTerrestre.svg';
import { ReactComponent as FormationPhotoChimiqueOzoneIcon } from '@carbonmaps/media/icons/other-indicator/FormationPhotoChimiqueOzone.svg';
import { ReactComponent as PEFBiodiversityNoteIcon } from '@carbonmaps/media/icons/other-indicator/PEF-biodiversity-note.svg';
import { ReactComponent as ParticulesFinesIcon } from '@carbonmaps/media/icons/other-indicator/ParticulesFine.svg';
import { ReactComponent as RayonnementsIonisantsIcon } from '@carbonmaps/media/icons/other-indicator/RayonnementsIonisants.svg';
import { ReactComponent as SubstanceCancerogeneIcon } from '@carbonmaps/media/icons/other-indicator/SubstanceCancerogene.svg';
import { ReactComponent as SubstanceNonCancerogeneIcon } from '@carbonmaps/media/icons/other-indicator/SubstanceNonCancerogene.svg';
import { ReactComponent as UtilisationSolIcon } from '@carbonmaps/media/icons/other-indicator/UtilisationSol.svg';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import BlockCard2 from '../../../components/BlockCard2';
import BlockTitle from '../../../components/BlockTitle';
import OtherIndicatorBloc from '../../../components/OtherIndicatorBloc';
import OtherIndicatorItem from '../../../components/OtherIndicatorItem';
import PefNote from '../../../components/PefNote';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { useGetPackagingSheetTimePeriod } from '../../../lib/react-query/features/packaging/packaging.hooks';
import PackagingModel from '../../../models/Packaging.model';
import { stylesOtherIndicator } from '../../productSheet/synthesis/OtherIndicatorsBlock';

import OtherIndicatorsTable from './OtherIndicatorsTable';

const toExponential = (num: number, digits = 2) => {
	return num.toExponential(digits);
};



const PackagingOtherIndicatorsBlock = () => {
	const theme = useTheme();
	const params = useParams();

	// translation
	const { t } = useTranslation();

	const { data } = useGetPackagingSheetTimePeriod({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.packagingId!,
	});

	// const otherIndicatorsColumns = useMemo(() => {
	// 	return [
	// 		{
	// 			accessor: 'label',
	// 			Header: (props: any) => {
	// 				return (
	// 					<Box padding="16px" bgcolor={theme.palette.grey[400]}>
	// 						<Typography fontSize="14px" color={theme.palette.grey[900]} sx={{ opacity: 0 }}>
	// 							invisible
	// 						</Typography>
	// 					</Box>
	// 				);
	// 			},
	// 			Cell: (props: any) => {
	// 				return (
	// 					<Box padding="16px">
	// 						<Typography fontSize="14px" color={theme.palette.grey[900]}>
	// 							{props.value}
	// 						</Typography>
	// 					</Box>
	// 				);
	// 			},
	// 		},
	// 		{
	// 			accessor: 'measure',
	// 			Header: (props: any) => {
	// 				return (
	// 					<Box padding="16px" bgcolor={theme.palette.grey[400]}>
	// 						<Typography fontSize="14px" color={theme.palette.grey[900]} textAlign="right">
	// 							{t('per_kilo')}
	// 						</Typography>
	// 					</Box>
	// 				);
	// 			},
	// 			Cell: (props: any) => {
	// 				// console.log('====================================');
	// 				// console.log('PROPS', props);
	// 				// console.log('====================================');
	// 				return (
	// 					<Box padding="16px">
	// 						<Typography fontSize="14px" color={theme.palette.grey[900]} textAlign="right">
	// 							{props.value.replace('.', ',')}
	// 						</Typography>
	// 					</Box>
	// 				);
	// 			},
	// 		},
	// 		{
	// 			accessor: 'unit',
	// 			Header: (props: any) => {
	// 				return (
	// 					<Box padding="16px" bgcolor={theme.palette.grey[400]}>
	// 						<Typography fontSize="14px" color={theme.palette.grey[900]} textAlign="left">
	// 							{t('product-other-indicator-mesure')}
	// 						</Typography>
	// 					</Box>
	// 				);
	// 			},
	// 			Cell: (props: any) => {
	// 				// console.log('====================================');
	// 				// console.log('PROPS', props);
	// 				// console.log('====================================');
	// 				return (
	// 					<Box padding="16px">
	// 						<Typography fontSize="14px" color={theme.palette.grey[900]} textAlign="left">
	// 							{props.value}
	// 						</Typography>
	// 					</Box>
	// 				);
	// 			},
	// 		},
	// 	];
	// }, [theme.palette.grey, t]);

	const toValueIndicatorByView = (value: any) => {
		if (value === 0) {
			return '-';
		}

		return toExponential(value);
	};

	const otherIndicatorsData = useMemo(() => {
		const packagingObject = new PackagingModel(data);

		/* eslint-disable quotes */
		return [
			{
				label: t('Particules fines'),
				measure: toValueIndicatorByView(packagingObject?.getIntensity('particules') ?? 0),
				unit: 'disease increase/kg',
				description: t('Particules fines - description'),
				icon: <ParticulesFinesIcon />,
			},
			{
				label: t('Epuisement des ressources énergétiques'),
				measure: toValueIndicatorByView(packagingObject?.getIntensity('fossilUse') ?? 0),
				unit: 'MJ/kg',
				description: t('Épuisement des ressources énergétiques - description'),
				icon: <EpuisementRessourcesEnergetiquesIcon />,
			},
			{
				label: t("Utilisation du sol"),
				measure: toValueIndicatorByView(packagingObject?.getIntensity('landUse') ?? 0),
				unit: 'Pt/kg',
				description: t('Utilisation du sol - description'),
				icon: <UtilisationSolIcon />,
			},
			{
				label: t('Epuisement des ressources minérales'),
				measure: toValueIndicatorByView(packagingObject?.getIntensity('mineralUse') ?? 0),
				unit: 'kg Sb eq/kg',
				description: t('Épuisement des ressources minérales - description'),
				icon: <EpuisementRessourcesMinerauxIcon />,
			},
			{
				label: t("Appauvrissement de la couche d'ozone"),
				measure: toValueIndicatorByView(packagingObject?.getIntensity('ozone1') ?? 0),
				unit: 'kg CFC11 eq/kg',
				description: t("Appauvrissement de la couche d'ozone - description"),
				icon: <AppauvrissementCoucheOzoneIcon />,
			},
			{
				label: t('Acidification terrestre et eau douce'),
				measure: toValueIndicatorByView(packagingObject?.getIntensity('acidification') ?? 0),
				unit: 'mol H+ eq/kg',
				description: t('Acidification terrestre et eau douce - description'),
				icon: <AcidificationTerrestreEauDouceIcon />,
			},

			{
				label: t('Rayonnements ionisants'),
				measure: toValueIndicatorByView(packagingObject?.getIntensity('rayonnements') ?? 0),
				unit: 'kBq U235 eq/kg',
				description: t('Rayonnements ionisants - description'),
				icon: <RayonnementsIonisantsIcon />,
			},
			{
				label: t('Eutrophisation terrestre'),
				measure: toValueIndicatorByView(packagingObject?.getIntensity('eutrophisation3') ?? 0),
				unit: 'mol N eq/kg',
				description: t('Eutrophisation terrestre - description'),
				icon: <EutrophisationTerrestreIcon />,
			},
			{
				label: t('Eutrophisation eau douce'),
				measure: toValueIndicatorByView(packagingObject?.getIntensity('eutrophisation1') ?? 0),
				unit: 'kg P eq/kg',
				description: t('Eutrophisation eau douce - description'),
				icon: <EutrophisationEauDouceIcon />,
			},
			{
				label: t('Eutrophisation marine'),
				measure: toValueIndicatorByView(packagingObject?.getIntensity('eutrophisation2') ?? 0),
				unit: 'kg N eq/kg',
				description: t('Eutrophisation marine - description'),
				icon: <EutrophisationMarineIcon />,
			},
			{
				label: t("Formation photochimique d'ozone"),
				measure: toValueIndicatorByView(packagingObject?.getIntensity('ozone2') ?? 0),
				unit: 'kg NMVOC eq/kg',
				description: t("Formation photochimique d'ozone - description"),
				icon: <FormationPhotoChimiqueOzoneIcon />,
			},
			{
				label: t('Effets toxicologiques sur la santé humaine : substances cancérogènes'),
				measure: toValueIndicatorByView(packagingObject?.getIntensity('toxicite2') ?? 0),
				unit: 'CTUh/kg',
				description: t('Effets toxicologiques sur la santé humaine : substances cancérogènes - description'),
				icon: <SubstanceCancerogeneIcon />,
			},
			{
				label: t('Effets toxicologiques sur la santé humaine : substances non-cancérogènes'),
				measure: toValueIndicatorByView(packagingObject?.getIntensity('toxicite1') ?? 0),
				unit: 'CTUh/kg',
				description: t('Effets toxicologiques sur la santé humaine : substances non-cancérogènes - description'),
				icon: <SubstanceNonCancerogeneIcon />,
			},

			{
				label: t("Ecotoxicité pour écosystèmes aquatiques d'eau douce"),
				measure: toValueIndicatorByView(packagingObject?.getIntensity('ecotox') ?? 0),
				unit: 'CTUe/kg',
				description: t("Ecotoxicité pour écosystèmes aquatiques d'eau douce - description"),
				icon: <EcotoxiciteEauDouceIcon />,
			},

			{
				label: t('PEF-biodiversity-note'),
				measure: 0,
				unit: 'CTUe/kg',
				description: t('PEF-biodiversity-note - description'),
				icon: <PEFBiodiversityNoteIcon />,
				isDisabled: true,
			},
		];
		/* eslint-enable quotes */
	}, [data, t]);

	const [hidden, setHidden] = useState(true);

	return (
		<>
			<BButton
				addStyles={{ width: '100%', marginBottom: '8px' }}
				variant="secondary"
				// eslint-disable-next-line quotes
				label={
					hidden ?
					(
						<>
							{/* eslint-disable-next-line quotes */}
							{t("Voir plus d'indicateurs")}
							<ChevronDown size={18} />
						</>
					) :
					(
						<>
							{t('voir moins')}
							<ChevronUp size={18} />
						</>
					)
				}
				onClick={() => {
					return setHidden(!hidden);
				}}
			/>
			<Box mb={pxToRem(100)} className={cx(hidden ? stylesOtherIndicator.hidden : '')} >
				<OtherIndicatorBloc data={otherIndicatorsData} />
			</Box>
		</>
	);
};

export default PackagingOtherIndicatorsBlock;
