import { Suspense } from 'react';

import { AuthKitProvider } from '@workos-inc/authkit-react';
import { HelmetProvider } from 'react-helmet-async';

import { AppProvider } from '@carbonmaps/ui/contexts/AppProvider';

import { version } from '../../../package.json';

// import SmallScreenWarning from './components/SmallScreenWarning';
import Version from './components/Version';
import QueryClientProvider from './providers/QueryClientProvider';
import ThemeProvider from './providers/ThemeProvider';
import Routes from './routes/Routes';

const App = () => {
	// const [isSmallScreen, setIsSmallScreen] = useState(false);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsSmallScreen(window.innerWidth <= 720);
  //   };

  //   handleResize(); // Check on initial load
  //   window.addEventListener('resize', handleResize);

  //   return () => {return window.removeEventListener('resize', handleResize);};
  // }, []);

	return (
		<AuthKitProvider clientId="client_01JFSCP2C43AXAFAQ5F0P9SH09">
			<QueryClientProvider>
				<ThemeProvider>
					<HelmetProvider>
						<Version version={version} />
						<Suspense fallback={<div></div>}>
							<AppProvider>
								{/* {isSmallScreen ? <SmallScreenWarning /> : <Routes />} */}
								<Routes />
							</AppProvider>
						</Suspense>
					</HelmetProvider>
				</ThemeProvider>
			</QueryClientProvider>
		</AuthKitProvider>
	);
};

export default App;
