import React from 'react';

import { css, cx } from '@emotion/css';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography, type DialogProps } from '@mui/material';
import _ from 'lodash';
import { X } from 'lucide-react';

import SelectYear from '../../../components/layout/SelectYear';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import ProductsFilter from '../../products/general/tabs/products/ProductsFilter';

type Props = {
	open: DialogProps['open'];
	onClose?: DialogProps['onClose'];
	children: React.ReactNode;
};

const filterContainerStyle = css({
	'& .filterContainer-wrapper': {
		padding: '0!important',
		width: '90%',
	},
});

const ProductsModalSelect = ({ open, onClose, children }: Props) => {
	const { t } = useTranslation();

	return (
		<Dialog
			open={open}
			onClose={onClose}
			scroll="paper"
			PaperProps={{
				sx: {
					borderRadius: 3,
				},
			}}
			fullWidth
			maxWidth="lg"
		>
			<DialogTitle component="div" id="scroll-dialog-title" sx={{ padding: '32px', position: 'relative' }}>
				<Typography variant="h2" sx={{ color: siteColors.primary, mb: '16px' }}>
					{t('add-product-quote', { product: _.toLower(t('product')), of_product: t('of_product') })}
				</Typography>

				<div className={cx('flexRow alignCenter', filterContainerStyle)}>
					<ProductsFilter withMore={false} />
					<SelectYear />
				</div>

				<IconButton
					sx={{ position: 'absolute', right: 10, top: 10 }}
					onClick={(e) => {
						onClose?.(e, 'backdropClick');
					}}
				>
					<X size={20} />
				</IconButton>
			</DialogTitle>
			<DialogContent
				dividers={true}
				sx={{
					padding: 0,
				}}
			>
				{children}
			</DialogContent>
		</Dialog>
	);
};

export default ProductsModalSelect;
