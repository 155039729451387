import { useEffect, useState } from 'react';

import { css, cx } from '@emotion/css';
import { zodResolver } from '@hookform/resolvers/zod';
import {
	Autocomplete,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Switch,
	TextField,
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useForm, UseFormReturn } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useThrottle, useThrottleFn } from 'react-use';
import { z } from 'zod';
import { create } from 'zustand';

import { FRONT_PATH_NAMES, functionName, roleSuffixName } from '@carbonmaps/shared/utils/constants';
import { getCompaniesAction } from '@carbonmaps/ui/actions/company.actions';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import BInput from '@carbonmaps/ui/components/saas/BInput';
import DeleteConfirm from '@carbonmaps/ui/components/saas/DeleteConfirm';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import Dialog from '../../../components/dialogs/Dialog';
import Container from '../../../components/layout/list/Container';
import { useTranslation } from '../../../hooks/useTranslation';
import { EMAIL_LANGUAGES_SUPPLIER } from '../../../utils/constants';

import { useTheme } from '@mui/material';
import { Copy, Pencil, PlayCircle, Plus, Settings, Trash2 } from 'lucide-react';
import {
	useDeleteQuestionForm,
	useDuplicateQuestionForm,
	useFindOneQuestionForm,
	useFindQuestionForm,
	useLaunchQuestionFormsScoring,
	useSaveQuestionForm,
} from './hooks';
import QuestionFormsHeader from './QuestionFormsHeader';
import QuestionTranslation from './QuestionTranslation';

const styles = {
	main: css({
		padding: 24,
	}),
	hidden: css({
		display: 'none',
	}),
	gap12: css({
		gap: 12,
	}),
};

const QuestionForms = () => {
	const { data: questionForms } = useFindQuestionForm({ fromBo: true });
	const { t } = useTranslation();
	const { setBreadcrumbs } = useApp();

	// ---- breadCrumbs ---- //
	useEffect(() => {
		setBreadcrumbs([{ text: t('Questionnaire') }]);
	}, [setBreadcrumbs, t]);

	return (
		<Container header={<QuestionFormsHeader />} seoText={`${t('Questionnaire')} - Carbon Maps`}>
			<div className={cx('mt-3 flexRow', styles.gap12)}>
				<AddFormDialog />

				<ScoringButton />
			</div>
			<div className="mt-3">
				<QuestionFormsTableHeader />
				{(questionForms || [])?.map((qForm: any, index: number) => {
					return <QuestionFormItem key={qForm.objectId} questionFormObject={qForm as never} />;
				})}
			</div>
		</Container>
	);
};

export default QuestionForms;

const qfItemStyles = {
	main: css({
		padding: 12,
		borderBottom: '1px solid #f4f2f7',
	}),
	inner1: css({
		flex: 1,
		justifyContent: 'space-between',
		alignItems: 'center',
	}),
	name: css({
		width: 300,
	}),
	nameContainer: css({
		display: 'flex',
		flexDirection: 'column',
	}),
	nameText: css({
		fontWeight: 400,
	}),
	typeText: css({
		fontSize: '0.85em',
		color: '#666',
	}),
	companies: css({
		width: 300,
	}),
	header: css({
		padding: '12px 12px',
		borderBottom: '1px solid #f4f2f7',
		opacity: 0.5,
	}),
	status: css({
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
	}),
	statusDot: css({
		width: '8px',
		height: '8px',
		borderRadius: '50%',
	}),
};

const roleSuffix = _.values(roleSuffixName);

const getCompanyCodes = (questionFormObject: Record<string, any>) => {
	const companyCodes = new Set<string>();

	_.entries(_.get(questionFormObject, 'ACL')).forEach(([key, value]) => {
		if (!key.startsWith('role:')) {
			return;
		}

		if (_.get(value, 'read') !== true && _.get(value, 'write') !== true) {
			return;
		}

		const roleName = key.split(':')[1];

		if (roleName === 'super_admin') {
			return;
		}

		const suffix = roleSuffix.find((s) => {
			return roleName.endsWith(`_${s}`);
		});

		if (suffix) {
			const companyCode = roleName.replace(`_${suffix}`, '');
			companyCodes.add(companyCode);
		}
	});

	return Array.from(companyCodes);
};

const QuestionFormItem = ({ questionFormObject }: { questionFormObject: IQuestionValues }) => {
	console.log('questionFormObject', questionFormObject.objectId);
	const companyCodes = getCompanyCodes(questionFormObject);

	const queryClient = useQueryClient();
	const theme = useTheme();

	const { data: companies } = useQuery({
		queryKey: ['getCompanies'],
		queryFn: getCompaniesAction,
	});

	const companyNames = companyCodes.map(code => {
		const company = companies?.find((c: any) => c.code === code);
		return company?.name || code;
	}).join(', ');

	const { mutate: duplicateQuestionForm } = useDuplicateQuestionForm({
		onSuccess: (data) => {
			console.log('useDuplicateQuestionForm action success: ', data);
			queryClient.invalidateQueries(['FindQuestionForm']);
		},
	});

	return (
		<div className={cx('flexRow', qfItemStyles.main)}>
			<div className={cx('flexRow', qfItemStyles.inner1)}>
				<div className={cx('', qfItemStyles.companies)}>
					{companyNames || '-'}
				</div>
				<div className={cx('', qfItemStyles.name)}>
					<div className={qfItemStyles.nameContainer}>
						<div className={qfItemStyles.nameText}>
							{questionFormObject?.translation?.fr?.name ||
								questionFormObject?.translation?.en?.name ||
									questionFormObject?.translation?.es?.name ||
									questionFormObject?.translation?.it?.name ||
									questionFormObject?.translation?.de?.name}
						</div>
						<div className={qfItemStyles.typeText}>
							{questionFormObject.type}
						</div>
					</div>
				</div>
				<div className={cx('', qfItemStyles.status)} style={{ width: 100 }}>
					<div
						className={qfItemStyles.statusDot}
						style={{
							backgroundColor: questionFormObject.published ? theme.palette.success.main : theme.palette.error.main
						}}
					/>
					{questionFormObject.published ? 'Publié' : 'Non publié'}
				</div>
				<div style={{ width: 300 }}>
					<div className={cx('flexRow', styles.gap12)}>
						<UpdateFormDialog
							defaultValues={{
								..._.pick(questionFormObject, ['translation', 'type', 'lang', 'published', 'objectId']),
								companyCodes,
							}}
						/>

						<Link
							to={`${FRONT_PATH_NAMES.superAdminSettings}-a/question-forms/settings/${_.toString(
								questionFormObject.objectId,
							)}`}
						>
							<BButton
								label=""
								variant="tertiary"
								iconLeft={<Settings color={theme.palette.grey[700]} />}
								tooltip="Configure questionnaire"
							/>
						</Link>

						<BButton
							label=""
							variant="tertiary"
							iconLeft={<Copy color={theme.palette.grey[700]} />}
							tooltip="Duplicate questionnaire"
							onClick={() => {
								duplicateQuestionForm({ objectId: _.toString(questionFormObject.objectId) });
									queryClient.invalidateQueries(['FindQuestionForm']);
							}}
						/>

						<DeleteQuestionFormButton objectId={_.toString(questionFormObject.objectId)} />
					</div>
				</div>
			</div>
		</div>
	);
};

export type IFormValues = {
	objectId?: string;

	typeFormId_fr: string;
	name_fr: string;
	url_fr: string;

	typeFormId_en: string;
	name_en: string;
	url_en: string;

	typeFormId_it: string;
	name_it: string;
	url_it: string;

	typeFormId_es: string;
	name_es: string;
	url_es: string;

	typeFormId_de: string;
	name_de: string;
	url_de: string;

	lang: string;
	published?: boolean;
	type: string;
	// ...
	companyCodes?: string[];
};

export type IQuestionValues = {
	objectId?: string;
	translation: {
		en: {
			typeFormId: string;
			name: string;
			url: string;
		};
		fr: {
			typeFormId: string;
			name: string;
			url: string;
		};
		it: {
			typeFormId: string;
			name: string;
			url: string;
		};
		es: {
			typeFormId: string;
			name: string;
			url: string;
		};
		de: {
			typeFormId: string;
			name: string;
			url: string;
		};
	};
	lang: string;
	published?: boolean;
	type: string;
	// ...
	companyCodes?: string[];
};

const questionFormSchema = z.object({
	typeFormId_fr: z.string().optional(),
	name_fr: z.string().optional(),
	url_fr: z.string().optional(), // todo change for url

	typeFormId_en: z.string().optional(),
	name_en: z.string().optional(),
	url_en: z.string().optional(),

	typeFormId_it: z.string().optional(),
	name_it: z.string().optional(),
	url_it: z.string().optional(),

	typeFormId_es: z.string().optional(),
	name_es: z.string().optional(),
	url_es: z.string().optional(),

	typeFormId_de: z.string().optional(),
	name_de: z.string().optional(),
	url_de: z.string().optional(),

	lang: z.string().min(1),
	// ...
	objectId: z.string().optional(),
	published: z.boolean().optional(),
	type: z.string().min(1),

	companyCodes: z.array(z.string()) /* .optional() */,
});

const FormDialog = ({
	open,
	setOpen,
	defaultValues,
	title,
}: {
	open: boolean;
	setOpen: (open: boolean) => void;
	defaultValues: IFormValues;
	title: string;
}) => {
	// const [renderKey, setRenderKey] = useState<string>(nanoid());

	const [displayLang, setDisplayLang] = useState('fr');
	const questionForm = useForm({
		resolver: zodResolver(questionFormSchema),
		defaultValues: {
			...defaultValues,
			lang: ['en', 'fr'].includes(defaultValues.lang) ? defaultValues.lang : 'en',
			published: !_.isBoolean(defaultValues.published) ? false : defaultValues.published,
			companyCodes: defaultValues.companyCodes || [],
		},
	});

	const queryClient = useQueryClient();

	const { mutate, isLoading } = useSaveQuestionForm({
		onSuccess: (data) => {
			queryClient.invalidateQueries(['FindQuestionForm']);
			queryClient.invalidateQueries(['FindOneQuestionForm']);

			// it is an update
			if (defaultValues.objectId) {
				Parse.Cloud.run(functionName.updateRelatedCampaigns, {
					objectId: defaultValues.objectId,
					sessionToken: Parse.User.current()?.getSessionToken(),
				});
			}

			setOpen(false);
		},
	});

	questionForm.watch();

	const submitHandler = questionForm.handleSubmit(
		(data) => {
			// console.log('data', data.companyCodes);
			mutate({ input: data });
		},
		(err) => {
			console.log('error', err);
		},
	);

	return (
		<Dialog
			open={open}
			isDrawer={true}
			// onClose={() => { }}
			toggle={() => {
				questionForm.reset();
				setOpen(!open);
			}}
			secondaryButtonText={'cancel'}
			primaryButtonText={'save'}
			onPrimaryButtonAction={() => {
				submitHandler();
			}}
			title={title}
			isLoading={isLoading}
		>
			{/* <h2></h2> */}
			<FormControlLabel
				control={
					<Switch
						// defaultChecked={!_.isBoolean(defaultValues.published) ? false : defaultValues.published}
						// defaultChecked={questionForm.control._defaultValues.published}
						checked={_.isBoolean(questionForm.getValues('published')) ? questionForm.getValues('published') : false}
						onChange={(event, checked) => {
							questionForm.setValue('published', checked);
						}}
					/>
				}
				label="Published"
				css={{
					marginBottom: 16,
				}}
			/>

			<BInput
				label="Type"
				{...questionForm.register('type')}
				error={Boolean(questionForm.formState.errors.type?.message)}
				helperText={questionForm.formState.errors.type?.message}
				addStyles={{
					marginBottom: 20,
				}}
			/>

			<Autocomplete
				options={EMAIL_LANGUAGES_SUPPLIER}
				{...questionForm.register('lang')}
				style={{ width: '100%' }}
				defaultValue={'fr'}
				disableClearable
				onChange={(event: any, newValue: string | null) => {
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					questionForm.setValue('lang', newValue!);
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					setDisplayLang(newValue!);
				}}
				renderInput={(params) => {
					return <TextField {...params} fullWidth />;
				}}
			/>

			<div className={cx('', displayLang !== 'fr' ? styles.hidden : '')}>
				<h2>Questionnaire en Français</h2>
				<QuestionTranslation questionForm={questionForm as never} lng="fr" />
			</div>
			<div className={cx('', displayLang !== 'en' ? styles.hidden : '')}>
				<h2>Questionnaire en Anglais</h2>
				<QuestionTranslation questionForm={questionForm as never} lng="en" />
			</div>

			<div className={cx('', displayLang !== 'es' ? styles.hidden : '')}>
				<h2>Questionnaire en Espagnol</h2>
				<QuestionTranslation questionForm={questionForm as never} lng="es" />
			</div>

			<div className={cx('', displayLang !== 'it' ? styles.hidden : '')}>
				<h2>Questionnaire en Italien</h2>
				<QuestionTranslation questionForm={questionForm as never} lng="it" />
			</div>

			<div className={cx('', displayLang !== 'de' ? styles.hidden : '')}>
				<h2>Questionnaire en Allemand</h2>
				<QuestionTranslation questionForm={questionForm as never} lng="de" />
			</div>

			<CompanyList form={questionForm} />
		</Dialog>
	);
};

const AddFormDialog = () => {
	const [open, setOpen] = useState(false);
	const theme = useTheme();

	return (
		<div>
			<BButton
				variant="primary"
				label="Ajouter un questionnaire"
				iconLeft={<Plus color={theme.palette.common.white} />}
				onClick={() => {
					setOpen(true);
				}}
			/>

			<FormDialog
				open={open}
				setOpen={setOpen}
				defaultValues={{
					typeFormId_fr: '',
					name_fr: '',
					url_fr: '',

					typeFormId_en: '',
					name_en: '',
					url_en: '',

					typeFormId_it: '',
					name_it: '',
					url_it: '',

					typeFormId_es: '',
					name_es: '',
					url_es: '',

					typeFormId_de: '',
					name_de: '',
					url_de: '',

					lang: 'en',
					type: '',
				}}
				title="Ajout d'un questionnaire"
			/>
		</div>
	);
};

const UpdateFormDialog = ({ defaultValues }: { defaultValues: IQuestionValues }) => {
	const [open, setOpen] = useState(false);
	const theme = useTheme();

	return (
		<>
			<BButton
				label=""
				tooltip="Edit questionnaire"
				variant="tertiary"
				iconLeft={<Pencil color={theme.palette.grey[700]} />}
				onClick={() => {
					setOpen(true);
				}}
			/>

			<FormDialog
				open={open}
				setOpen={setOpen}
				defaultValues={{
					objectId: defaultValues?.objectId,

					typeFormId_fr: defaultValues?.translation?.fr?.typeFormId,
					name_fr: defaultValues?.translation?.fr?.name,
					url_fr: defaultValues?.translation?.fr?.url,

					typeFormId_en: defaultValues?.translation?.en?.typeFormId,
					name_en: defaultValues?.translation?.en?.name,
					url_en: defaultValues?.translation?.en?.url,

					typeFormId_it: defaultValues?.translation?.it?.typeFormId,
					name_it: defaultValues?.translation?.it?.name,
					url_it: defaultValues?.translation?.it?.url,

					typeFormId_es: defaultValues?.translation?.es?.typeFormId,
					name_es: defaultValues?.translation?.es?.name,
					url_es: defaultValues?.translation?.es?.url,

					typeFormId_de: defaultValues?.translation?.de?.typeFormId,
					name_de: defaultValues?.translation?.de?.name,
					url_de: defaultValues?.translation?.de?.url,

					lang: defaultValues?.lang,
					published: defaultValues?.published,
					type: defaultValues?.type,
					companyCodes: defaultValues?.companyCodes,
				}}
				title="Modifier questionnaire"
			/>
		</>
	);
};

const DeleteQuestionFormButton = ({ objectId }: { objectId: string }) => {
	const queryClient = useQueryClient();
	const theme = useTheme();

	const { mutate: deleteQForm } = useDeleteQuestionForm({
		onSuccess: (data) => {
			console.log('useDeleteQuestionForm action success: ', data);
			queryClient.invalidateQueries(['FindQuestionForm']);
		},
	});

	return (
		<DeleteConfirm
			title={''}
			onClick={(close?: VoidFunction) => {
				deleteQForm({ objectId });
			}}
		>
			<span>
				<BButton
					variant="tertiary"
					label=""
					iconLeft={<Trash2 color={theme.palette.error.main} />}
					tooltip="Delete questionnaire"
					sx={{
						'&:hover': {
							backgroundColor: theme.palette.error.light,
						}
					}}
				/>
			</span>
		</DeleteConfirm>
	);
};

const CompanyList = ({ form }: { form: UseFormReturn<any> }) => {
	// ---- fetch data company--- //
	const { data } = useQuery({
		queryKey: ['getCompanies'],
		queryFn: getCompaniesAction,
	});

	return (
		<div>
			<FormControl>
				<FormLabel component="legend">Companies</FormLabel>
				<FormGroup>
					{(data || []).map((company: any, index: number) => {
						return <CompanyItem key={_.get(company, 'objectId')} form={form} company={company} />;
					})}
				</FormGroup>
			</FormControl>
		</div>
	);
};

const CompanyItem = ({ form, company }: { form: UseFormReturn<any>; company: any }) => {
	const objectId = form.getValues('objectId') || [];
	const { data, isLoading } = useFindOneQuestionForm({ fromBo: true, objectId });

	const companyCode = _.get(company, 'code');

	const [checked, setChecked] = useState(false);

	useEffect(() => {
		if (!isLoading) {
			const companyCodes = (data as any)?.companyCodes || [];
			setChecked(companyCodes?.includes(companyCode));

			form.setValue('companyCodes', companyCodes);
		}
	}, [data]);

	return (
		<FormControlLabel
			control={
				<Checkbox
					disabled={isLoading}
					checked={checked}
					onChange={(e, checked) => {
						setChecked(checked);

						if (checked) {
							form.setValue('companyCodes', [...form.getValues('companyCodes'), companyCode]);
						} else {
							form.setValue(
								'companyCodes',
								form.getValues('companyCodes').filter((code: string) => {
									return code !== companyCode;
								}),
							);
						}
					}}
					name={companyCode || '--warning--'}
				/>
			}
			label={_.get(company, 'name') || '--???--'}
		/>
	);
};

const TIMEOUT = 30000;

const useScoringStore = create((set) => {
	return {
		isActive: true,
		timeRemaining: TIMEOUT,
		setIsActive: (isActive: boolean) => {
			return set({ isActive });
		},
		setTimeRemaining: (timeRemaining: number | ((prev: number) => void)) => {
			if (_.isFunction(timeRemaining)) {
				return set((state: any) => {
					const next = timeRemaining(state.timeRemaining);
					return { timeRemaining: next };
				});
			}

			return set({ timeRemaining });
		},
	};
});

const ScoringButton = () => {
	const { mutate: launch } = useLaunchQuestionFormsScoring();

	const isActive = useScoringStore((state: any) => {
		return state.isActive;
	});
	const timeRemaining = useScoringStore((state: any) => {
		return state.timeRemaining;
	});
	const setIsActive = useScoringStore((state: any) => {
		return state.setIsActive;
	});
	const setTimeRemaining = useScoringStore((state: any) => {
		return state.setTimeRemaining;
	});

	const theme = useTheme();

	useEffect(() => {
		if (!isActive) {
			const timer = setTimeout(() => {
				setIsActive(true);
			}, TIMEOUT);

			return () => {
				return clearTimeout(timer);
			};
		}
	}, [isActive, setIsActive]);

	useEffect(() => {
		let interval: NodeJS.Timeout;

		if (!isActive) {
			interval = setInterval(() => {
				setTimeRemaining((prev: any) => {
					if (prev <= 1000) {
						clearInterval(interval);
						return TIMEOUT;
					}

					return prev - 1000;
				});
			}, 1000);
		} else {
			setTimeRemaining(TIMEOUT);
		}

		return () => {
			clearInterval(interval);
		};
	}, [isActive, setTimeRemaining]);

	return (
		<BButton
			isDisabled={!isActive}
			variant="secondary"
			label={isActive ? 'Lancer le Scoring pour toutes les orgas' : `Réessayer dans ${timeRemaining / 1000}s`}
			iconLeft={<PlayCircle color={theme.palette.grey[700]} />}
			onClick={_.throttle(() => {
				setIsActive(false);
				launch({});
			}, TIMEOUT)}
		/>
	);
};

// const ScoringButton = () => {
// 	const { mutate: launch } = useLaunchQuestionFormsScoring();

// 	const [isActive, setIsActive] = useState(true);
// 	const [timeRemaining, setTimeRemaining] = useState(TIMEOUT);

// 	useEffect(() => {
// 		if (!isActive) {
// 			const timer = setTimeout(() => {
// 				setIsActive(true);
// 			}, TIMEOUT);

// 			return () => {
// 				return clearTimeout(timer);
// 			};
// 		}
// 	}, [isActive]);

// 	useEffect(() => {
// 		let interval: NodeJS.Timeout;

// 		if (!isActive) {
// 			interval = setInterval(() => {
// 				setTimeRemaining((prev) => {
// 					if (prev <= 1000) {
// 						clearInterval(interval);
// 						return TIMEOUT;
// 					}

// 					return prev - 1000;
// 				});
// 			}, 1000);
// 		} else {
// 			setTimeRemaining(TIMEOUT);
// 		}

// 		return () => {
// 			clearInterval(interval);
// 		};
// 	}, [isActive]);

// 	return (
// 		<BButton
// 			isDisabled={!isActive}
// 			variant={!isActive ? 'secondary' : 'primary'}
// 			label={
// 				isActive
// 					? 'Lancer le Scoring pour toutes les orgas'
// 					: `Calcul en cours: réessayer dans ${timeRemaining / 1000}s`
// 			}
// 			onClick={_.throttle(() => {
// 				setIsActive(false);
// 				launch({});
// 			}, TIMEOUT)}
// 		/>
// 	);
// };

const QuestionFormsTableHeader = () => {
	return (
		<div className={cx('flexRow', qfItemStyles.header)}>
			<div className={cx('flexRow', qfItemStyles.inner1)}>
				<div className={cx('', qfItemStyles.companies)}>Organisations</div>
				<div className={cx('', qfItemStyles.name)}>Nom</div>
				<div style={{ width: 100 }}>Statut</div>
				<div style={{ width: 300 }}>Actions</div>
			</div>
		</div>
	);
};
