import { useCallback, useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { CircularProgress, LinearProgress } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { type TFunction } from 'i18next';
import _ from 'lodash';
import { Box, Minus, Plus } from 'lucide-react';

import BButton from '@carbonmaps/ui/components/saas/BButton';
import { Indicator } from '@carbonmaps/ui/contexts/AppProvider';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { WATER_INDICATOR } from '@carbonmaps/ui/utils/constants';

import IntensityCell from '../../../components/_comp/IntensityCell';
import Table from '../../../components/_comp/Table';
import TableHeadCell from '../../../components/_comp/TableHeadCell';
import LabelLogo from '../../../components/question/LabelLogo';
import { useTranslation } from '../../../hooks/useTranslation';
import { cn, siteColors } from '../../../lib/colors';
import { useAddProductDevis, useFindProductsForDevisInfinite, useRemoveProductDevis } from '../hooks';
import { useQuoteSheet } from '../quote.hooks';

const tableStyles = {
	main: css({
		width: '100%',
		borderCollapse: 'collapse',
		'& tr': {
			height: '60px',
			alignSelf: 'stretch',
		},
		'& tbody tr': {
			borderTop: cn('1px solid', siteColors.grey500),
		},
		'.labelLogoText': {
			maxWidth: '600px',
		},
		'& tr > th:nth-of-type(1), & tr > td:nth-of-type(1)': {
			width: 300,
		},
		'& tr > th:not(:nth-of-type(1)) .alignRight': {
			display: 'flex!important',
			justifyContent: 'flex-end',
		},
	}),
	paddingStart: css({
		paddingLeft: 54,
	}),
};

export type ProductRowData = {
	id: string;
	uid: string;
	label: string;
	tagAdvanced?: boolean;
	intensity?: number;
	intensityPercent?: number;
	isAdded?: boolean;
};

const columnHelper = createColumnHelper<ProductRowData>();

type GetColumnsProps = {
	quoteId?: string;
	groupId?: string;
	groupProductIds?: Map<string, string>;
	indicator: Indicator;
	t: TFunction;
};

const getColumns = ({ t, groupId, quoteId, groupProductIds, indicator }: GetColumnsProps) => {
	return [
		columnHelper.accessor('label', {
			header: (info) => {
				return <TableHeadCell info={info} label={t('product')} valueType="string" />;
			},
			cell: (info) => {
				return <LabelLogo label={info.row.original.label} uid={info.row.original.uid} icon={<Box size={20} />} />;
			},
		}),
		columnHelper.accessor('intensity', {
			header: (info) => {
				return (
					<TableHeadCell
						info={info}
						label={t('Par kilo')}
						valueType="number"
						variant="measure"
						measure={indicator === WATER_INDICATOR ? 'waterIntensity' : 'carbonIntensityKg'}
					/>
				);
			},
			cell: (info) => {
				return <IntensityCell value={info.getValue()} percentage={info.row.original.intensity} indicator={indicator} />;
			},
		}),
		columnHelper.display({
			id: 'actions',
			cell: (info) => {
				return (
					<div className={cx('flexRow alignCenter justifyCenter')}>
						{groupProductIds?.has?.(info.row.original.id)
? (
							<RemoveProductDevisButton groupId={groupId} productId={groupProductIds?.get?.(info.row.original.id)} quoteId={quoteId} />
						)
: (
							<AddProductDevisButton groupId={groupId} productId={info.row.original.id} quoteId={quoteId} />
						)}
					</div>
				);
			},
		}),
	];
};

const AddProductDevisButton = ({
	quoteId,
	groupId,
	productId,
}: {
	quoteId?: string;
	groupId?: string;
	productId?: string;
}) => {
	const { t } = useTranslation();
	const { indicator } = useApp();
	const [isActionRunning, setIsActionRunning] = useState(false);
	const { mutate: addProduct /* , isLoading: isLoadingUpdate */ } = useAddProductDevis();
	const { isFetching: isFetchingGetQuoteSheet } = useQuoteSheet({ quoteId: _.toString(quoteId), viewMode: indicator });

	useEffect(() => {
		if (isActionRunning && !isFetchingGetQuoteSheet) {
			setIsActionRunning(false);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<BButton
			variant="tertiary"
			iconLeft={isActionRunning ? <CircularProgress size={20} /> : <Plus size={20} />}
			onClick={() => {
				setIsActionRunning(true);
				addProduct({ groupId, productId, quoteId });
			}}
			label={t('Ajouter')}
			addStyles={{ background: 'transparent', '&:hover': { background: 'transparent' } }}
		/>
	);
};

const RemoveProductDevisButton = ({
	quoteId,
	groupId,
	productId,
}: {
	quoteId?: string;
	groupId?: string;
	productId?: string;
}) => {
	const { t } = useTranslation();
	const { indicator } = useApp();
	const [isActionRunning, setIsActionRunning] = useState(false);
	const { mutate: removeProduct } = useRemoveProductDevis();
	const { isFetching: isFetchingGetQuoteSheet } = useQuoteSheet({ quoteId: _.toString(quoteId), viewMode: indicator });

	useEffect(() => {
		if (isActionRunning && !isFetchingGetQuoteSheet) {
			setIsActionRunning(false);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<BButton
			iconLeft={isActionRunning ? <CircularProgress size={20} sx={{ color: 'white' }} /> : <Minus size={20} />}
			variant="primary"
			onClick={() => {
				setIsActionRunning(true);
				removeProduct({ groupId, productId, quoteId });
			}}
			label={t('Retirer')}
		/>
	);
};

type Props = {
	quoteId?: string;
	groupId?: string;
	groupProductIds?: Map<string, string>;
};

const ProductsTableSelect = ({ quoteId, groupId, groupProductIds }: Props) => {
	const { indicator } = useApp();
	const { t } = useTranslation();

	const columns = useMemo(() => {
		return getColumns({ t: t as never, quoteId, groupId, groupProductIds, indicator });
	}, [groupId, quoteId, t, groupProductIds, indicator]);

	const { data, fetchNextPage, isFetchingNextPage, isFetching } = useFindProductsForDevisInfinite({
		enabled: true,
	});

	//flatten the array of arrays from the useInfiniteQuery hook
	const flatData = useMemo(() => {
		return (
			data?.pages?.flatMap((page) => {
				return page.products;
			}) ?? []
		);
	}, [data]);

	const totalDBRowCount = data?.pages?.[0]?.meta?.totalCount ?? 0;
	const totalFetched = flatData.length;

	//called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
	const fetchMoreOnBottomReached = useCallback(
		(containerRefElement?: HTMLDivElement | null) => {
			if (containerRefElement) {
				const { scrollHeight, scrollTop, clientHeight } = containerRefElement;

				//once the user has scrolled within 500px of the bottom of the table, fetch more data if we can
				if (scrollHeight - scrollTop - clientHeight < 500 && !isFetching && totalFetched < totalDBRowCount) {
					fetchNextPage();
				}
			}
		},
		[fetchNextPage, isFetching, totalFetched, totalDBRowCount],
	);

	return (
		<div
			css={{ maxHeight: '600px', overflowY: 'scroll', padding: '0 32px' }}
			className={cx('width100')}
			onScroll={(e) => {
				return fetchMoreOnBottomReached(e.target as HTMLDivElement);
			}}
		>
			<Table
				columns={columns}
				data={flatData}
				tableProps={{ className: cx('', tableStyles.main) }}
				isLoading={isFetching && !isFetchingNextPage}
			/>
			{isFetchingNextPage ? <LinearProgress /> : null}
		</div>
	);
};

export default ProductsTableSelect;
