import _ from 'lodash';

import { CARBON_INDICATOR, WATER_INDICATOR } from '@carbonmaps/shared/utils/constants';

import IngredientModel from '../models/Ingredient.model';
import ProductModel from '../models/Product.model';

import { randomColor } from './color.utils';

 type PackagingType = {
	netWeight: number;
	weight: number;
	sim_modelization: {
		materials: Array<{
			codeCmaps: string;
			gesWeighted: number;
			waterUseWeighted: number;
			rawMaterial: Parse.Object;
			material: Parse.Object;
		}>
	}
}

// use to get field by view ( carbon or water)
const FIELDS_CONFIG_BY_VIEW = new Map();

// for carbon
FIELDS_CONFIG_BY_VIEW.set(CARBON_INDICATOR, {
	gesAgriculture: 'gesAgriculture',
	gesTransformationUpstream: { field1: 'gesTransformationUpstream', field2: 'gesUpstreamTransformation' },
	gesTransformation: 'gesTransformation',
	gesPackaging: 'gesPackaging',
	ges: 'ges',
	gesTransport: 'gesTransport',
	gesDistribution: 'gesDistribution',
	gesConsumption: 'gesConsumption',
	gesTotal: 'gesTotal',
	gesAgricultures: 'gesAgricultures',
});

// for water
FIELDS_CONFIG_BY_VIEW.set(WATER_INDICATOR, {
	gesAgriculture: 'waterUseAgriculture',
	gesAgricultures: 'waterUseAgricultures',

	gesTransformationUpstream: { field1: 'waterUseTransformationUpstream', field2: 'waterUseUpstreamTransformation' },
	gesTransformation: 'waterUseTransformation',
	gesPackaging: 'waterUsePackaging',
	ges: 'waterUse',
	gesTransport: 'waterUseTransport',
	gesDistribution: 'waterUseDistribution',
	gesConsumption: 'waterUseConsumption',
	gesTotal: 'waterUseTotal',
});

/**
 * ges Agriculture per kilo
 * @param data
 * @param indicator
 * @returns
 */
export const calculateGesAgriculture = (data: any, indicator: any, isProduct = false /* , log = false */) => {
	let gesAgriculture = 0;
	const configByIndicator = FIELDS_CONFIG_BY_VIEW.get(indicator);

	(data?.ingredients || []).forEach((item: any) => {
		if (item?.isOriginal && !item?.isPresent) return;

		const ingredientModel = new IngredientModel(item.ingredient?.toJSON());
		const composition = parseFloat(item?.composition || 0);
		const gesIngredient = ingredientModel.getIntensityN2(configByIndicator?.gesAgriculture) || 0;
		const prevGesAgriculture = ((composition as any).toFixed(2) * gesIngredient) / 100;

		const currentValue = prevGesAgriculture;

		// const gesIngredient = ingredientModel.getIntensityN2(configByIndicator?.gesAgriculture) || 0;

		// gesAgriculture = gesAgriculture + ((composition as any).toFixed(2) * gesIngredient) / 100;
		gesAgriculture = gesAgriculture + currentValue;
	});

	return roundGesValue(gesAgriculture);
};

/**
 * gesAgriculture diff
 */
export const calculateGesAgricultureDiff = (data: any, indicator: any) => {
	const gesAgriculture = calculateGesAgriculture(data, indicator);
	const configByIndicator = FIELDS_CONFIG_BY_VIEW.get(indicator);
	const product = new ProductModel(data?.product?.toJSON());
	const gesAgricultureProduct = product.getIntensity(configByIndicator?.gesAgricultures);

	return gesAgriculture - gesAgricultureProduct;
};

/**
 * ges agriculture diff percent
 * @param data
 * @param indicator
 * @returns
 */
export const calculateGesAgricultureDiffPercent = (data: any, indicator: any, withPercent = true, precision = 10) => {
	const configByIndicator = FIELDS_CONFIG_BY_VIEW.get(indicator);
	const product = new ProductModel(data?.product?.toJSON());
	const gesAgricultureProduct = product.getIntensity(configByIndicator?.gesAgricultures);
	const gesAgriculture = calculateGesAgriculture(data, indicator);

	return calculateDiffPercentByIndicator(gesAgriculture, gesAgricultureProduct, withPercent, precision);
};

/**
 * ges transformation per kilo
 * @param data
 * @param indicator
 */
export const calculateGesTransformationPerKilo = (
	data: any,
	indicator: any,
	isProduct = false,
	mode: 'all' | 'upstream' = 'all',
	log = false,
) => {
	// sum ingredient (ges transformation per kilo * composition) + product ges transformation ovale
	const product = new ProductModel((data?.product || data[data?.pointer])?.toJSON());

	const configByIndicator = FIELDS_CONFIG_BY_VIEW.get(indicator);

	// const upstreamProduct = data?.product?.get('gesUpstreamTransformationIngredients') || data?.product?.get('gesTransformationUpstreamIngredients');
	let upstreamProduct = 0;
	product?.getIngredients()?.forEach((item: any) => {
		// let ing = item.ingredient;

		// if (ing instanceof Parse.Object) {
		// 	ing = ing.toJSON();
		// }

		const ingredientModel = new IngredientModel(item?.ingredient);
		const composition = parseFloat(item?.composition || 0);

		const gesTransformation =
			ingredientModel.getIntensityN2(configByIndicator?.gesTransformationUpstream?.field1) ||
			ingredientModel.getIntensityN2(configByIndicator?.gesTransformationUpstream?.field2) ||
			item?.gesTransformationUpstream ||
			0;

		// const gesTransformation = item?.gesTransformationUpstream || 0;

		// log && console.log(item, '\n', item.ingredient, '\n', gesTransformation, '\n', composition, ingredientModel.getIntensityN2(
		// 	configByIndicator?.gesTransformationUpstream?.field1,
		// ), ingredientModel.getIntensityN2(
		// 	configByIndicator?.gesTransformationUpstream?.field2,
		// ));

		upstreamProduct = upstreamProduct + ((composition as any)?.toFixed(2) * gesTransformation) / 100; // add fixed 2 because in the table it is 2
	});

	// log && console.log('data', data);
	// eslint-disable-next-line no-unexpected-multiline
	let gesTransformationUpstream = 0;
	(data?.ingredients || []).forEach((item: any) => {
		if (item?.isOriginal && !item?.isPresent) return;

		const ingredientModel = new IngredientModel(item.ingredient?.toJSON());
		const composition = parseFloat(item?.composition || 0);

		const gesTransformation =
			ingredientModel.getIntensityN2(configByIndicator?.gesTransformationUpstream?.field1) ||
			ingredientModel.getIntensityN2(configByIndicator?.gesTransformationUpstream?.field2) ||
			0;

		// if (log) {
		// 	console.log('A', ingredientModel.getIntensityN2(configByIndicator?.gesTransformationUpstream?.field1));
		// 	console.log('A', ingredientModel.getIntensityN2(configByIndicator?.gesTransformationUpstream?.field2));
		// }

		gesTransformationUpstream =
			gesTransformationUpstream + ((composition as any)?.toFixed(2) * gesTransformation) / 100; // add fixed 2 because in the table it is 2
	});

	// product ges transformation
	const productGesTransformationTotal = product.getIntensity(configByIndicator?.gesTransformation);

	const gesTransformationDownStream = productGesTransformationTotal - upstreamProduct;

	// log && console.log('#####', productGesTransformationTotal, upstreamProduct, gesTransformationDownStream, gesTransformationUpstream);

	// log && console.log('gesTransformationUpstream', gesTransformationUpstream);

	const gesTransformationTotal = gesTransformationUpstream + gesTransformationDownStream;

	return roundGesValue(mode === 'all' ? gesTransformationTotal : gesTransformationUpstream);
	// return roundGesValue(gesTransformationUpstream);
};

/**
 * ges transformation diff
 */
export const calculateGesTransformationDiff = (data: any, indicator: any) => {
	const gesTransformation = calculateGesTransformationPerKilo(data, indicator);
	const configByIndicator = FIELDS_CONFIG_BY_VIEW.get(indicator);
	const product = new ProductModel(data?.product?.toJSON());
	const gesTransformationProduct = product.getIntensity(configByIndicator?.gesTransformation);

	return gesTransformation - gesTransformationProduct;
};

/**
 * ges transformation diff percent
 * @param data
 * @param indicator
 * @returns
 */
export const calculateGesTransformationPercent = (data: any, indicator: any, withPercent = true, precision = 10) => {
	const configByIndicator = FIELDS_CONFIG_BY_VIEW.get(indicator);
	const product = new ProductModel(data?.product?.toJSON());
	const gesTransformationProduct = product.getIntensity(configByIndicator?.gesTransformation);

	const gesTransformation = calculateGesTransformationPerKilo(data, indicator);
	return calculateDiffPercentByIndicator(gesTransformation, gesTransformationProduct, withPercent, precision);
};

/**
 * ges packaging per kilo
 * @param data
 * @param indicator
 * @returns
 */
export const calculateGesPackagingPerKilo = (data: any, indicator: any) => {
	const product = new ProductModel(new ProductModel((data?.product || data[data?.pointer])?.toJSON()));
	const netProduct = parseFloat(data?.netWeight || 0);
	const configByIndicator = FIELDS_CONFIG_BY_VIEW.get(indicator);
	const dataPackaging = data?.emballages || [];

	// use product packaging when empty packaging data

	let gesPackaging = 0;

	dataPackaging.forEach((item: any) => {
		if (item?.isOriginal && !item?.isPresent) return;

		const composition = (item?.weight || 0) / netProduct; // ! diso ny chiffres ao @ base indraindray
		// const composition = parseFloat(item.composition || 0) / 100;
		// console.log('OOO', item?.weight, netProduct, configByIndicator?.ges, item?.packaging?.get(configByIndicator?.ges));
		// add fixed 2 because in the table it is 2
		gesPackaging = gesPackaging + (composition as any)?.toFixed(2) * item?.packaging?.get(configByIndicator?.ges);

		// gesPackaging = gesPackaging + (((item?.weight || 0) * item?.packaging?.get(configByIndicator?.ges)) / netProduct);
	});

	return roundGesValue(gesPackaging, 3);
};

export const roundGesValue = (ges: any, precision = 10) => {
	// return ges;
	return Number(isNaN(ges) ? 0 : ges?.toFixed(precision));
};

/**
 * ges transformation diff
 */
export const calculateGesPackagingDiff = (data: any, indicator: any) => {
	const gesPackaging = calculateGesPackagingPerKilo(data, indicator);
	const configByIndicator = FIELDS_CONFIG_BY_VIEW.get(indicator);
	const product = new ProductModel(data?.product?.toJSON());
	const gesPackagingProduct = product.getIntensity(configByIndicator?.gesPackaging);

	return gesPackaging - gesPackagingProduct;
};

/**
 *
 * @param gesScenario
 * @param gesProduct
 * @returns
 */
export const calculateDiffPercentByIndicator = (
	gesScenario: any,
	gesProduct: any,
	withPercent = true,
	precision = 10,
) => {
	const diff = roundGesValue(gesScenario - gesProduct, precision);
	if (!withPercent) return diff;
	return gesScenario || gesProduct ? (diff * 100) / Math.max(gesProduct, gesScenario) : 0;
};

/**
 * ges transformation diff percent
 * @param data
 * @param indicator
 * @returns
 */
export const calculateGesPackagingDiffPercent = (data: any, indicator: any, withPercent = true, precision = 10) => {
	const configByIndicator = FIELDS_CONFIG_BY_VIEW.get(indicator);
	const product = new ProductModel(data?.product?.toJSON());
	const gesPackagingProduct = product.getIntensity(configByIndicator?.gesPackaging);
	const gesPackaging = calculateGesPackagingPerKilo(data, indicator);
	return calculateDiffPercentByIndicator(
		roundGesValue(gesPackaging, 3),
		roundGesValue(gesPackagingProduct, 3),
		withPercent,
		precision,
	);
};

/**
 * ges transport per kilo
 * @param data
 * @param indicator
 * @returns
 */
export const calculateGesTransportPerKilo = (data: any, indicator: any) => {
	const product = new ProductModel((data?.product || data[data?.pointer])?.toJSON());
	const configByIndicator = FIELDS_CONFIG_BY_VIEW.get(indicator);
	return product.getIntensity(configByIndicator?.gesTransport);
};

/**
 * ges GesTransport diff
 */
export const calculateGesTransportDiff = (data: any, indicator: any) => {
	const gesTransport = calculateGesTransportPerKilo(data, indicator);
	const configByIndicator = FIELDS_CONFIG_BY_VIEW.get(indicator);
	const product = new ProductModel(data?.product?.toJSON());
	const gesTransportProduct = product.getIntensity(configByIndicator?.gesTransport);

	return gesTransport - gesTransportProduct;
};

/**
 * ges GesTransport diff percent
 * @param data
 * @param indicator
 * @returns
 */
export const calculateGesTransportDiffPercent = (data: any, indicator: any, withPercent = true, precision = 10) => {
	const configByIndicator = FIELDS_CONFIG_BY_VIEW.get(indicator);
	const product = new ProductModel(data?.product?.toJSON());
	const gesTransportProduct = product.getIntensity(configByIndicator?.gesTransport);
	const gesTransport = calculateGesTransportPerKilo(data, indicator);

	return calculateDiffPercentByIndicator(gesTransport, gesTransportProduct, withPercent, precision);
};

/**
 * ges Distribution per kilo
 * @param data
 * @param indicator
 * @returns
 */
export const calculateGesDistributionPerKilo = (data: any, indicator: any) => {
	const product = new ProductModel((data?.product || data[data?.pointer])?.toJSON());
	const configByIndicator = FIELDS_CONFIG_BY_VIEW.get(indicator);

	return roundGesValue(product.getIntensity(configByIndicator?.gesDistribution));
};

/**
 * ges ges distribution diff
 */
export const calculateGesDistributionDiff = (data: any, indicator: any) => {
	const gesDistribution = calculateGesDistributionPerKilo(data, indicator);
	const configByIndicator = FIELDS_CONFIG_BY_VIEW.get(indicator);
	const product = new ProductModel(data?.product?.toJSON());
	const gesDistributionProduct = product.getIntensity(configByIndicator?.gesDistribution);

	return gesDistribution - gesDistributionProduct;
};

/**
 * ges ges distribution diff percent
 * @param data
 * @param indicator
 * @returns
 */
export const calculateGesDistributionDiffPercent = (data: any, indicator: any, withPercent = true, precision = 10) => {
	const configByIndicator = FIELDS_CONFIG_BY_VIEW.get(indicator);
	const product = new ProductModel(data?.product?.toJSON());
	const gesDistributionProduct = product.getIntensity(configByIndicator?.gesDistribution);

	const gesDistribution = calculateGesDistributionPerKilo(data, indicator);

	return calculateDiffPercentByIndicator(gesDistribution, gesDistributionProduct, withPercent, precision);
};

/**
 * ges Distribution per kilo
 * @param data
 * @param indicator
 * @returns
 */
export const calculateGesConsumptionPerKilo = (data: any, indicator: any) => {
	const product = new ProductModel((data?.product || data[data?.pointer])?.toJSON());
	const configByIndicator = FIELDS_CONFIG_BY_VIEW.get(indicator);
	return product.getIntensity(configByIndicator?.gesConsumption) || 0;
};

/**
 * ges ges distribution diff
 */
export const calculateGesConsumptionDiff = (data: any, indicator: any) => {
	const gesConsumption = calculateGesConsumptionPerKilo(data, indicator);

	const configByIndicator = FIELDS_CONFIG_BY_VIEW.get(indicator);
	const product = new ProductModel(data?.product?.toJSON());
	const gesConsumptionProduct = product.getIntensity(configByIndicator?.gesConsumption) || 0;

	return gesConsumption - gesConsumptionProduct;
};

/**
 * ges GesConsumption diff percent
 * @param data
 * @param indicator
 * @returns
 */
export const calculateGesConsumptionDiffPercent = (data: any, indicator: any, withPercent = true, precision = 10) => {
	const configByIndicator = FIELDS_CONFIG_BY_VIEW.get(indicator);
	const product = new ProductModel(data?.product?.toJSON());
	const gesConsumptionProduct = product.getIntensity(configByIndicator?.gesConsumption);

	const gesConsumption = calculateGesConsumptionPerKilo(data, indicator);

	return calculateDiffPercentByIndicator(gesConsumption, gesConsumptionProduct, withPercent, precision);
};

/**
 * calculate ges per kilo
 * @param data
 * @param indicator
 * @returns
 */
export const calculateGesPerKilo = (data: any, indicator: any, isProduct = false /* , log = false */) => {
	let gesTotalPerKilo = 0;

	// add ges agriculture
	const gesAgriculture = calculateGesAgriculture(data, indicator, isProduct /* , log */);
	gesTotalPerKilo = gesTotalPerKilo + (gesAgriculture ?? 0);

	// add transformation
	const gesTransformation = calculateGesTransformationPerKilo(data, indicator, isProduct, 'all' /* , true */);
	gesTotalPerKilo = gesTotalPerKilo + (gesTransformation ?? 0);

	// add ges packaging
	const gesPackaging = calculateGesPackagingPerKilo(data, indicator);
	gesTotalPerKilo = gesTotalPerKilo + (gesPackaging ?? 0);

	// add ges transport
	const gesTransport = calculateGesTransportPerKilo(data, indicator);
	gesTotalPerKilo = gesTotalPerKilo + (gesTransport ?? 0);

	// add ges distribution
	const gesDistribution = calculateGesDistributionPerKilo(data, indicator);
	gesTotalPerKilo = gesTotalPerKilo + (gesDistribution ?? 0);

	// add ges Consumption
	const gesConsumption = calculateGesConsumptionPerKilo(data, indicator);
	gesTotalPerKilo = gesTotalPerKilo + (gesConsumption ?? 0);

	// log && console.log('😈😈', gesAgriculture, gesTransformation, gesPackaging, gesTransport, gesDistribution, gesConsumption);

	return roundGesValue(gesTotalPerKilo);
};

/**
 *  calculate percent diff for ges per kilo
 * @param data
 * @param indicator
 * @returns
 */
export const gesPerKiloDiffPercent = (data: any, indicator: any) => {
	const product = new ProductModel(data?.product?.toJSON());
	const gesProduct = product.getIntensity(FIELDS_CONFIG_BY_VIEW.get(indicator)?.gesTotal);
	const gesData = getGesPerKiloDiff(data, indicator, true) as any;
	return gesProduct || gesData?.gesPerKilo ? (gesData?.diff * 100) / Math.max(gesProduct, gesData?.gesPerKilo) : 0;
};

export const calculateDiffPercent = (gesPerKilo: any, gesProduct: any) => {
	const diff = gesPerKilo - gesProduct;
	return gesProduct || gesPerKilo ? (diff * 100) / Math.max(gesProduct, gesPerKilo) : 0;
};

export const getGesPerKiloDiff = (data: any, indicator: any, withPerKilo = false) => {
	const gesPerKilo = calculateGesPerKilo(data, indicator);
	const product = new ProductModel(data?.product?.toJSON());
	const gesProduct = product.getIntensity(FIELDS_CONFIG_BY_VIEW.get(indicator)?.gesTotal);

	const diff = roundGesValue(gesPerKilo, 2) - roundGesValue(gesProduct, 2);
	return withPerKilo ? { diff, gesPerKilo } : diff;
};

/**
 * calculate ges per  portion
 * @param data
 * @param indicator
 * @returns
 */
export const calculateGesPerPortion = (data: any, indicator: any) => {
	// gesPerPortion = gesPerKilo *  net weight product / 1000 ( unit to convert)
	const gesPerKilo = calculateGesPerKilo(data, indicator);
	// const product = new ProductModel(data?.product?.toJSON());
	const netWeightProduct = parseFloat(data?.netWeight || 0);

	return roundGesValue((gesPerKilo * netWeightProduct) / 1000);
};

/**
 * diff percent ges per portion
 * @param data
 * @param indicator
 * @returns
 */
export const gesPerPortionDiffPercent = (data: any, indicator: any) => {
	const gesPerPortion = calculateGesPerPortion(data, indicator);
	// calculate ges Per portion product
	const product = new ProductModel(data?.product?.toJSON());
	const gesProduct = product.getIntensity(FIELDS_CONFIG_BY_VIEW.get(indicator)?.gesTotal);
	const netWeightProduct = parseFloat(product.getNetWeightProduct() || 0);

	const gesPerPerPortionProduct = (gesProduct * netWeightProduct) / 1000;

	return gesPerPerPortionProduct || gesPerPortion
		? ((roundGesValue(gesPerPortion, 2) - roundGesValue(gesPerPerPortionProduct, 2)) * 100) /
		Math.max(gesPerPerPortionProduct, gesPerPortion)
		: 0;
};

export const getProductSimulation = (data: any) => {
	return {
		...data[data?.pointer]?.toJSON(),
		ingredients: data[data?.pointer]?.get('ingredients'),
		emballages: data[data?.pointer]?.get('emballages'),
		product: data[data?.pointer],
	};
};

/**
 * calculate indicator global simulation
 * @param data
 * @param indicator
 */
export const getGlobalIndicator = (data: any, indicator: any) => {
	// ges per kilo
	const gesPerKilo = calculateGesPerKilo(data, indicator, false /* , true */);

	const productData = getProductSimulation(data);

	const gesProduct = calculateGesPerKilo(productData, indicator);

	const gesPerKiloDiff = calculateDiffPercent(gesPerKilo, gesProduct);

	// ges per portion
	const gesPerPortion = calculateGesPerPortion(data, indicator);
	const gesPerPortionProduct = calculateGesPerPortion(productData, indicator);

	// diff ges per portion
	const gesPerPortionDiff = calculateDiffPercent(gesPerPortion, gesPerPortionProduct);

	return {
		gesPerKilo,
		gesPerKiloDiff,
		gesPerPortion,
		gesPerPortionDiff,
	};
};

/**
 * data life cycle
 * @param data
 * @param indicator
 */
export const getDataLifeCycle = (data: any, indicator: any) => {
	const productCopy = getProductSimulation(data);
	const gesTotalPerKilo = calculateGesPerKilo(data, indicator);
	const gesProductPerKilo = calculateGesPerKilo(productCopy, indicator);

	const gesDiff = Math.abs((gesTotalPerKilo - gesProductPerKilo) as number);

	//
	const dataMap = new Map();

	// ges per kilo + diff
	const total = gesTotalPerKilo; /*  + gesDiff */ // ! as hery why he added gesDiff here

	// ---- ges agriculture ---- //
	const gesAgriculture = calculateGesAgriculture(data, indicator);
	const gesAgricultureProduct = calculateGesAgriculture(productCopy, indicator);

	dataMap.set('gesAgriculture', {
		value: gesAgriculture,
		percentValue: (gesAgriculture * 100) / total,
		diff: calculateDiffPercent(gesAgriculture, gesAgricultureProduct),
		percentDiff: ((gesAgriculture - gesAgricultureProduct) * 100) / total,
	});

	// ---- ges transformation ---- //
	const gesTransformation = calculateGesTransformationPerKilo(data, indicator, true /* , 'all', true */);
	const gesTransformationProduct = calculateGesTransformationPerKilo(productCopy, indicator, true /* , 'upstream' */);

	dataMap.set('gesTransformation', {
		value: gesTransformation,
		percentValue: (gesTransformation * 100) / total,
		diff: calculateDiffPercent(gesTransformation, gesTransformationProduct),
		percentDiff: ((gesTransformation - gesTransformationProduct) * 100) / total,
	});

	// ---- ges packaging ---- //
	const gesPackaging = calculateGesPackagingPerKilo(data, indicator);
	const gesPackagingProduct = calculateGesPackagingPerKilo(productCopy, indicator);

	dataMap.set('gesPackaging', {
		value: gesPackaging,
		percentValue: (gesPackaging * 100) / total,
		diff: calculateDiffPercent(gesPackaging, gesPackagingProduct),
		// use only this percent to display graph diff
		percentDiff: ((gesPackaging - gesPackagingProduct) * 100) / total,
	});

	// ---- ges transport ---- //
	const gesTransport = calculateGesTransportPerKilo(data, indicator);
	const gesTransportProduct = calculateGesTransportPerKilo(productCopy, indicator);

	dataMap.set('gesTransport', {
		value: gesTransport,
		percentValue: (gesTransport * 100) / total,
		diff: calculateDiffPercent(gesTransport, gesTransportProduct),
		// use only this percent to display graph diff
		percentDiff: ((gesTransport - gesTransportProduct) * 100) / total,
	});

	// ---- ges distribution ---- //
	const gesDistribution = calculateGesDistributionPerKilo(data, indicator);
	const gesDistributionProduct = calculateGesDistributionPerKilo(productCopy, indicator);

	dataMap.set('gesDistribution', {
		value: gesDistribution,
		percentValue: (gesDistribution * 100) / total,
		diff: calculateDiffPercent(gesDistribution, gesDistributionProduct),
		// use only this percent to display graph diff
		percentDiff: ((gesDistribution - gesDistributionProduct) * 100) / total,
	});

	// ---- ges distribution ---- //
	const gesConsumption = calculateGesConsumptionPerKilo(data, indicator);
	const gesConsumptionProduct = calculateGesConsumptionPerKilo(productCopy, indicator);

	dataMap.set('gesConsumption', {
		value: gesConsumption,
		percentValue: (gesConsumption * 100) / total,
		diff: calculateDiffPercent(gesConsumption, gesConsumptionProduct),
		// use only this percent to display graph diff
		percentDiff: ((gesConsumption - gesConsumptionProduct) * 100) / total,
	});

	return dataMap;
};

/**
 *
 * @param data
 * @param indicator
 */
export const getMaterialBreakDownData = (data: any, indicator: any) => {

	// --------------------------------------------------------------- //
	// ---------------- calculate ges material weighted -------------- //
	// --------------------------------------------------------------- //
	// Formule:  gesMaterial = Sum(weightPackaging / netWeightProduct * gesWeightedMaterial)
	const packaging = data?.emballages || [];

	// calculate ges material for each packaging
	const materialsPackaging : Array<{
		codeCmaps: string;
		label: string;
		carbonWeighted: number;
		waterWeighted: number;
		netWeightPackaging: number;
		netWeightProduct: number;
	}>= [] ;

	// return empty data when no material ( in version 2.x)
	if (!packaging.sim_modelization?.materials) {
		return {
			dataGraph: [],
			gesMaterial: 0,
			diff: 0,
		};
	}

	 packaging.forEach((packaging: PackagingType) => {
			const materials = packaging.sim_modelization?.materials?.map((material) => {
				return {
					codeCmaps: material.codeCmaps,
					label: (material?.rawMaterial || material?.material)?.get('labelCmaps') || material.codeCmaps,
					carbonWeighted: (packaging.weight / packaging.netWeight) *material.gesWeighted,
					waterWeighted: (packaging.weight / packaging.netWeight) * material.waterUseWeighted,
					netWeightPackaging: packaging.weight,
					netWeightProduct: packaging.netWeight,
				};
			});

			if (materials?.length) {
				materialsPackaging.push(...materials);
			}

	});
	// group by material codeCmaps
	const groupedIMaterials = _.groupBy(materialsPackaging, 'codeCmaps');
	const materials = Object.values(_.mapValues(groupedIMaterials, (items) => {
		return {
			codeCmaps: items[0].codeCmaps,
			label: items[0].label,
			gesMaterial: _.sumBy(items, 'carbonWeighted'),
			waterUserMaterial: _.sumBy(items, 'waterWeighted'),
		};
	}));

	const gesMaterialTotal = _.sumBy(materials, 'gesMaterial');
	const  waterUseMaterialTotal = _.sumBy(materials, 'waterUserMaterial');

	const breakDownData = materials?.map((item, index) => {
		const keyField = indicator === CARBON_INDICATOR ? 'gesMaterial' : 'waterUserMaterial';

		const percentValue = (item[keyField] * 100) / (indicator === CARBON_INDICATOR ? gesMaterialTotal : waterUseMaterialTotal);
		return {
			value: percentValue,
				y: percentValue,
				name:item.label,
				color: randomColor(index),
		};
	});

	// ------------------------------------------------------------ //
	// ---------------- ges material for product origin ----------- //
	// ------------------------------------------------------------ //
	const product = new ProductModel((data?.product || data[data?.pointer])?.toJSON());

	const packagingProductOrigin =  product.getPackaging();
	const netWeightProduct = product.getNetWeightProduct();

	const materialsPackagingOrigin : Array<{
		codeCmaps: string;
		carbonWeighted: number;
		waterWeighted: number;
	}>= [] ;

	packagingProductOrigin.forEach((packaging: PackagingType) => {
				const materials = packaging.sim_modelization.materials.map((material) => {
          return {
						codeCmaps: material.codeCmaps,
						carbonWeighted: (packaging.weight / netWeightProduct) *material.gesWeighted,
						waterWeighted: (packaging.weight / netWeightProduct) * material.waterUseWeighted,

					};
				});
				materialsPackagingOrigin.push(...materials);
	});


	// group by material codeCmaps
	const groupedMaterialsOrigin = _.groupBy(materialsPackagingOrigin, 'codeCmaps');
	const materialsOrigin = Object.values(_.mapValues(groupedMaterialsOrigin, (items) => {
		return {
			codeCmaps: items[0].codeCmaps,
			gesMaterial: _.sumBy(items, 'carbonWeighted'),
			waterUserMaterial: _.sumBy(items, 'waterWeighted'),
		};
	}));

	const gesMaterialOriginTotal = _.sumBy(materialsOrigin, 'gesMaterial');
	const  waterUseMaterialOriginTotal = _.sumBy(materialsOrigin, 'waterUserMaterial');

	// -------------------------------------------------- //
	// ----------- Ges Diff percent for material -------- //
	// -------------------------------------------------- //
	const gesMaterial = indicator === CARBON_INDICATOR ?  gesMaterialTotal : waterUseMaterialTotal;
	const gesMaterialOrigin = indicator === CARBON_INDICATOR ?  gesMaterialOriginTotal : waterUseMaterialOriginTotal;
	const gesDiff = gesMaterial - gesMaterialOrigin;




	return {
		dataGraph: breakDownData,
		gesMaterial: indicator === CARBON_INDICATOR ? gesMaterialTotal : waterUseMaterialTotal,
		diff: gesMaterial || gesMaterialOrigin ?  (gesDiff * 100) / Math.max(gesMaterial, gesMaterialOrigin): 0,
	};

};

/**
 *  getDataPieChart
 */
export const getDataPieChart = (data: any, indicator: any, withColor = false, sortingBy = false) => {
	const product = new ProductModel((data?.product || data[data?.pointer])?.toJSON());

	const configByIndicator = FIELDS_CONFIG_BY_VIEW.get(indicator);

	const gesPerKilo = calculateGesPerKilo(data, indicator);

	let dataGraph = (data?.ingredients || [])
		.map((item: any, index: number) => {
			if (item.isOriginal && !item.isPresent) return null;

			const ingredientModel = new IngredientModel(item.ingredient?.toJSON());
			const composition = parseFloat(item?.composition || 0);

			// gesProductPerPortion: (gesIngredient * composition) / 100,

			const gesAgriculture = ingredientModel.getIntensityN2(configByIndicator?.gesAgriculture) || 0;

			const gesTransformation =
				ingredientModel.getIntensityN2(configByIndicator?.gesTransformationUpstream?.field1) ||
				ingredientModel.getIntensityN2(configByIndicator?.gesTransformationUpstream?.field2) ||
				0;
			const gesProductPerPortion = ((gesAgriculture + gesTransformation) * composition) / 100;
			const percentValue = (gesProductPerPortion * 100) / gesPerKilo;

			return {
				value: percentValue,
				y: percentValue,
				name: ingredientModel.getLabel(),
				isBlueCheck: ingredientModel.isN2ModelizationType(),
				color: withColor ? randomColor(index) : undefined,
			};
		})
		.filter(Boolean);

	if (dataGraph && sortingBy) {
		dataGraph = _.orderBy(dataGraph, ['value'], ['desc']);

		dataGraph = dataGraph.map((item: any, index: number) => {
			return {
				...item,
				color: withColor ? randomColor(index) : undefined,
			};
		});
	}

	let gesIngredient = 0;
	data?.ingredients?.forEach((ing: any, index: number) => {
		if (ing.isOriginal && !ing.isPresent) return null;

		const ingredientObject = new IngredientModel(ing?.ingredient?.toJSON());
		const composition = ing?.composition;

		const gesAgriculture =
			((ingredientObject.getIntensityN2(configByIndicator?.gesAgriculture) || 0) * composition) / 100;

		const gesTransformation =
			((ingredientObject.getIntensityN2(configByIndicator?.gesTransformationUpstream?.field1) ||
				ingredientObject.getIntensityN2(configByIndicator?.gesTransformationUpstream?.field2) ||
				0) *
				composition) /
			100;

		gesIngredient += gesAgriculture + gesTransformation;
	});

	let gesIngredientProduct = 0;

	(product?.getIngredients() || []).forEach((ing: any, index: number) => {
		const ingredientObject = new IngredientModel(ing?.ingredient);
		const composition = ing?.composition;

		const gesAgriculture =
			((ingredientObject.getIntensityN2(configByIndicator?.gesAgriculture) || 0) * composition) / 100;

		const gesTransformation =
			((ingredientObject.getIntensityN2(configByIndicator?.gesTransformationUpstream?.field1) ||
				ingredientObject.getIntensityN2(configByIndicator?.gesTransformationUpstream?.field2) ||
				0) *
				composition) /
			100;

		gesIngredientProduct += gesAgriculture + gesTransformation;
	});

	const diff = roundGesValue(gesIngredient, 2) - roundGesValue(gesIngredientProduct, 2);

	return {
		gesIngredient: gesIngredient,
		dataGraph,
		diff: gesIngredient || gesIngredientProduct ?  (diff * 100) / Math.max(gesIngredient, gesIngredientProduct): 0,
	};
};

export const checkErrorIngredient = (data: any) => {
	let composition = 0;

	data.forEach((item: any) => {
		//do not include deleted items in the calculations
		if (item.isOriginal === true && item.isPresent === false) {
			return;
		}

		composition = composition + parseFloat(item?.composition || 0);
	});

	composition = Math.round(composition);

	return {
		isErrorComposition: composition > 100 || composition < 100 || !data?.length,
		isErrorData: !data?.length,
		composition: !data?.length ? 0 : composition,
	};
};

export const checkErrorPackaging = (data: any) => {
	return {
		isError: !data?.length,
	};
};
