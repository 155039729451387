import { useCallback, useEffect, useState } from 'react';

import { css, cx } from '@emotion/css';
import { Skeleton, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { ArrowLeft, Edit2, Wallet } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToggle } from 'react-use';

import { formatSlugUrlSalesQuote } from '@carbonmaps/shared/utils/simulation';
import { updateAllQuantityAction, updateQuoteClientAction } from '@carbonmaps/ui/actions/quote.actions';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useSearchQueryParams } from '../../hooks/useSearchQueryParams';
import { useTranslation } from '../../hooks/useTranslation';
import { cn, siteColors } from '../../lib/colors';
import { getQuoteOptionsAutocompleteClientAction } from '../../lib/react-query/features/client/client.actions';
import QuoteModel from '../../models/Quote.model';
import { CARBON_INDICATOR, TOP_BAR_HEIGHT, WATER_INDICATOR } from '../../utils/constants';

import QuantityDialogModal from './QuantityDialogModal';
import QuoteEditModal from './QuoteEditModal';
// import { generatePDF } from './pdf/PDFDocument';

import ExportDialog from './pdf/ExportDialog';
import { useQuoteSheet, useQuoteStore } from './quote.hooks';

const styles = {
	container: css({
		// background: 'red!important',
		boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.50)',
		zIndex: 200,
		position: 'sticky',
		top: TOP_BAR_HEIGHT,
		padding: '20px 21px 20px 16px',
		background: 'white',
		minHeight: 80,
	}),
	iconBox: css({
		borderRadius: '8.89px',
		width: '40px',
		height: '40px',
		marginRight: '8px',
		marginLeft: '16px',
	}),

	simulationName: css({
		fontWeight: '600!important',
		fontSize: '16px!important',
		marginRight: '8px!important',
	}),

	productSourceLabel: css({
		'& .blue-check-icon-container': {
			width: '16px!important',
		},
		// '& svg': {
		// 	// width: '16px',
		// },
		'& .MuiTypography-h2:first-of-type': {
			fontSize: '14px!important',
			fontWeight: '400!important',
		},
	}),
	goBack: css({
		minWidth: '0px !important',
		padding: '0px !important',
	}),
	actionContainer: css({
		gap: 16,
	}),
	quoteButton: css({
		minWidth: 158,
		maxWidth: '100%',
	}),
	text: css({
		fontSize: '12px !important',
		color: cn(siteColors.text, '!important'),
		lineHeight: '1.2 !important',
	}),
	skeleton: css({
		height: '40px !important',
		width: '100% !important',
		transform: 'none !important',
	} as any),

	dropItemButton: css({
		gap: 8,
		fontSize: '14px!important',
		fontWeight: '400!important',
		color: cn(siteColors.text, '!important'),
		cursor: 'pointer',
		padding: '8px 16px 8px 16px',
		background: 'transparent!important',
		paddingInline: 0,
	}),

	width300: css({
		width: 300,
	}),
	pointerClic: css({
		cursor: 'pointer',
	} as any),
	more: css({
		cursor: 'pointer',
	}),
};

const QuoteSheetHeader = () => {
	// translation
	const { t } = useTranslation();
	const params = useParams();
	const [searchQueryParams] = useSearchQueryParams();

	const { indicator } = useApp();
	const { quote, setQuote } = useQuoteStore();
	const [open, setOpen] = useState(false);
	const client = (quote as any)?.getClient();
	const [search] = useState<any>();
	const [isQuantityDialogOpen, toggleQuantityDialog] = useToggle(false);
	const navigate = useNavigate();
	const [selectedOption, setSelectedOption] = useState({
		...client,
		label: client?.name ?? '',
	}) as any;
	const queryClient = useQueryClient();
	const [options, setOptions] = useState([]);
	const [paramsTable, setTableParams] = useState<any>(searchQueryParams);

	// ---- fetch data for quote ---- //
	const { data, isLoading } = useQuoteSheet({
		quoteId: params.quoteId || '',
		viewMode: indicator,
	});

	// ---- update zustand store  ---- //
	useEffect(() => {
		if (!data) return;
		setQuote(data);
	}, [data]);

	// ---- update quoteClient ---- //
	const { mutate: updateQuoteClient, isLoading: isLoadingUpdate } = useMutation({
		mutationKey: ['updateQuoteClient'],
		mutationFn: updateQuoteClientAction,
		onSuccess: async () => {
			queryClient.invalidateQueries({ queryKey: ['getQuoteSheet'] });
			setOpen(false);
		},
		onError: (error: any) => {
			queryClient.invalidateQueries({ queryKey: ['getQuoteSheet'] });
		},
	});

	// ---- update ll quantity ---- //
	const { mutate: updateAllQuantity, isLoading: isLoadingAllQuantity } = useMutation({
		mutationKey: ['updateAllQuantity'],
		mutationFn: updateAllQuantityAction,
		onSuccess: async () => {
			queryClient.invalidateQueries({ queryKey: ['getQuoteSheet'] });
			queryClient.invalidateQueries({ queryKey: ['getQuoteDataAction'] });
			queryClient.invalidateQueries({ queryKey: ['findDevisForClientTable'] });
			queryClient.invalidateQueries({ queryKey: ['findReportsClientTable'] });
			queryClient.invalidateQueries({ queryKey: ['findQuoteReportsClientTable'] });
			queryClient.invalidateQueries({ queryKey: ['getGroupQuote'] });
			toggleQuantityDialog();
		},
		onError: (error: any) => {
			queryClient.invalidateQueries({ queryKey: ['getQuoteSheet'] });
			queryClient.invalidateQueries({ queryKey: ['getQuoteDataAction'] });
			queryClient.invalidateQueries({ queryKey: ['findDevisForClientTable'] });
			queryClient.invalidateQueries({ queryKey: ['findReportsClientTable'] });
			queryClient.invalidateQueries({ queryKey: ['findQuoteReportsClientTable'] });
			queryClient.invalidateQueries({ queryKey: ['getGroupQuote'] });
		},
	});

	const onSaveData = (data: any) => {
		updateQuoteClient({ ...data, quoteId: params.quoteId });
		setOpen(false);
	};

	const onSaveDataQuantity = (data: any) => {
		const getEstimatedImpact = (quantity: number, indicator: any) => {
			let total = 0;

			const groups = (quote as QuoteModel | null)?.getGroup();

			_.forEach(groups, (item: any) => {
				const products = item?.products || [];
				products.map((p: any) => {
					const fieldKey = indicator === CARBON_INDICATOR ? 'gesImpactPortion' : 'waterUseImpactPortion';

					let intensity = Number(p[fieldKey] || 0);
					_.isNaN(intensity) && (intensity = 0);
					intensity = _.round(intensity, 2);

					total += intensity * quantity;
				});
			});

			return Math.round(total);
		};

		const qty = _.get(data, 'quantity', 0);
		const estimatedImpactCarbon = getEstimatedImpact(qty, CARBON_INDICATOR);
		const estimatedImpactWater = getEstimatedImpact(qty, WATER_INDICATOR);

		// console.log('estimatedImpactCarbon: ', estimatedImpactCarbon);
		// console.log('estimatedImpactWater: ', estimatedImpactWater);

		// return;

		updateAllQuantity({ ...data, quoteId: params.quoteId, estimatedImpactWater, estimatedImpactCarbon });
	};

	const handleGoBack: any = () => {
		queryClient.invalidateQueries({ queryKey: ['getQuoteDataAction'] });
		// navigate(`${FRONT_PATH_NAMES.quote}/client/${client.objectId}`);
		navigate((quote as any)?.getLinkPageParent());
	};

	//----- fetch data for autocomplete ----//
	const { data: dataOptions, isSuccess: isSuccessOptions } = useQuery({
		queryKey: [
			'getOptionsAutocompleteClient',
			{
				...paramsTable,
				input: search?.input ?? '',
			},
		] as any,
		queryFn: getQuoteOptionsAutocompleteClientAction,
	});

	useEffect(() => {
		const _options: any = [];

		if (isSuccessOptions && dataOptions?.length) {
			_options.push(...dataOptions);
		}

		setOptions(_options);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccessOptions, isSuccessOptions, dataOptions, search]);

	const [openModal, setOpenModal] = useState(false);

	const analytics = useJuneTrack();
	// ---- track event ---- //
	const trackEvent = useCallback(
		(eventName: string, options?: any) => {
			if (!analytics) return;
			analytics.track(eventName, options, {
				context: { category: 'Simulate' },
			});
		},
		[analytics],
	);

	useEffect(() => {
		if (!quote) return;

		trackEvent('Simulate Carbon Quote Land Quote', {
			QuoteName: (quote as any)?.getName(),
		});
	}, [quote, trackEvent]);

	return (
		<div className={cx('flexRow justifyBetween alignCenter width100', styles.container)}>
			{!quote || isLoading
			? (
				<Skeleton className={styles.skeleton}></Skeleton>
			)
			: (
				<>
					<div className={cx('flexRow alignCenter')}>
						<BButton
							label=""
							iconLeft={<ArrowLeft color={siteColors.grey700} />}
							variant="tertiary"
							onClick={handleGoBack}
							className={styles.goBack}
						/>

						<div className={cx('bgGrey300 flexCenter', styles.iconBox)}>
							<Wallet color={siteColors.grey700} />
						</div>

						<div
							className={cx(styles.pointerClic)}
							onClick={() => {
								return setOpen(true);
							}}
						>
							<div className={cx('flexRow')}>
								<Typography className={cx('black', styles.simulationName)}>{(quote as any)?.getName()}</Typography>
								<Edit2 color={siteColors.grey700} width={17.5} />
							</div>

							<Typography className={styles.text}>
								{t('quote-address-text')} {client?.name} {`${client?.address ? client?.address : ''}`}
							</Typography>
						</div>
					</div>

					<div className={cx('flexRow alignCenter', styles.actionContainer)}>
						<BButton
							// iconLeft={<Asterisk color={siteColors.grey700} size={20} />}
							label={t('same-quantity-quote-button')}
							variant="secondary"
							onClick={() => {
								trackEvent('Simulate Carbon Quote Set same quantity everywhere', {
									QuoteName: (quote as any)?.getName(),
								});
								toggleQuantityDialog();
							}}
							className={styles.quoteButton}
						/>

						<BButton
							label={t('generate-quote-button')}
							variant="primary"
							onClick={() => {
								trackEvent('Simulate Carbon Quote Generate Quote', {
									QuoteName: (quote as any)?.getName(),
								});
								setOpenModal(true);
							}}
							className={styles.quoteButton}
						/>

						{/* <BDropdown
							menus={[
								{
									title: '',
									value: '',
									icon: <MoreVertical className="grey700" size={20} />,
									customComponent: 'Filtrer',
								},
							]}
							renderItem={(menu) => {
								return (
									<div className={styles.width300}>
										<BButton
											onClick={toggleQuantityDialog}
											iconLeft={<Asterisk color={siteColors.grey700} size={20} />}
											label={t('same-quantity-quote-button')}
											variant="tertiary"
											className={styles.dropItemButton}
										/>
									</div>
								);
							}}
						>
							<div className={styles.more}>
								<MoreVertical color={siteColors.grey700} size={20} />
							</div>
						</BDropdown> */}
					</div>

					<QuantityDialogModal
						open={isQuantityDialogOpen}
						onClose={toggleQuantityDialog}
						quote={(quote as any)?.quote}
						onSaveData={onSaveDataQuantity}
					/>

					<QuoteEditModal
						open={open}
						onClose={() => {
							return setOpen(false);
						}}
						quote={(quote as any)?.quote}
						options={options}
						selectedOption={selectedOption}
						setSelectedOption={setSelectedOption}
						onSaveData={onSaveData}
					/>

					<ExportDialog openModal={openModal} setOpenModal={setOpenModal} />
				</>
			)}
		</div>
	);
};

export default QuoteSheetHeader;
