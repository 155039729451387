

import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import { Navigate } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';

import SplashScreen from '../../../components/SplashScreen';
import useHasRoles from '../../../hooks/useHasRoles';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { getUserProfileAction, loginSSOAction } from '../../../lib/react-query/features/auth/auth.actions';
import { useGetClientAuthQuery } from '../../../lib/react-query/features/auth/auth.hooks';


const SSOPostMessage = () => {

	const analytics = useJuneTrack();
	const hasRoles = useHasRoles();

	const [searchQueryParams] = useSearchQueryParams();
	console.log('searchQueryParams', searchQueryParams);

	// ---------------------------------------- //
	// ---------- current user  ---------------- //
	// ---------------------------------------- //
	const {
			result: { data: authData },
		} = useGetClientAuthQuery();

	// --------------------------------------------------- //
	// -------------- user by token  --------------------- //
	// --------------------------------------------------- //
	const { data, isLoading } = useQuery({
		queryKey: ['getUserProfile', { code: searchQueryParams?.code }],
		queryFn: getUserProfileAction,
	});

	// ---------------------------------------------------- //
	// ---------------- redirect to app ------------------- //
	// ---------------------------------------------------- //

	if (authData) {
			// track login event
			if (!authData.session.company) {
				analytics.track('SSO Login User Unknown', {}, { context: { category: 'SSO' } });
			} else {
				analytics.track('SSO Login Successful', {}, { context: { category: 'SSO' } });
			}


			// identify user
			analytics.identify(authData?.user?.objectId, {
				email: authData?.user?.email,
				role:
					authData?.roles
						?.map((role) => {
							return role.label;
						})
						.join(', ') || 'no role',
				name: `${authData?.user?.firstName} ${authData?.user?.lastName}`,
			});

			if (hasRoles(['super_admin'])) return <Navigate replace to={FRONT_PATH_NAMES.superAdminSettings} />;

			if (!authData.session.company) return <Navigate replace to={FRONT_PATH_NAMES.userNoCompany} />;

			return <Navigate to={FRONT_PATH_NAMES.home} />;
		}

	return (
		<SplashScreen />
	);
};

export default SSOPostMessage;
