import { useCallback, useEffect, useState } from 'react';

import { css, cx } from '@emotion/css';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Widget, WidgetProps } from '@typeform/embed-react';
import _ from 'lodash';
import { CheckCircle2 } from 'lucide-react';
import { Navigate } from 'react-router-dom';

import { classNames, FRONT_PATH_NAMES, functionName, roleSuffixName } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import GenericEmptyBlock from '../../components/GenericEmptyBlock';
import Container from '../../components/layout/list/Container';
import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';
import {
	getDataFormQuestionnaireAction,
	saveResponseQuestionnaireAction,
} from '../../lib/react-query/features/question/question.actions';

import QuestionnaireHeader from './QuestionnaireHeader';

const styles = {
	root: css({
		//
		width: 700,
		margin: '0 auto',
		padding: '48px 32px',

		gap: 32,
	}),

	text1: css({
		color: siteColors.text,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '24px',
		fontStyle: 'normal',
		fontWeight: 600,
		lineHeight: 'normal',

		marginLeft: 16,
	}),
};

const Questionnaire = (/* props: Props */) => {
	const { setBreadcrumbs } = useApp();
	const { t, i18n } = useTranslation();
	const languageCode = getIsoCountryCode(i18n.language);
	const [showTypeForm, setShowTypeForm] = useState(true);
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const analytics = useJuneTrack();
	const trackEvent = useCallback(
		(name: string, options?: any) => {
			if (!analytics) return;
			analytics.track(name, options, {
				context: { category: 'Survey' },
			});
		},
		[analytics],
	);

	// ---- breadCrumbs ---- //
	useEffect(() => {
		setBreadcrumbs([{ text: t('quiz-breadcrumbs') }]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t]);

	const { mutate: saveResponseQuestionnaire, isSuccess } = useMutation({
		mutationKey: ['saveResponseQuestionnaireCampaign'],
		mutationFn: saveResponseQuestionnaireAction,
		onSuccess: async () => {
			console.log('save Response Questionnaire');
		},
	});

	// ---- fetch data ---- //
	// get emailAdmin and formId
	const { data, isLoading: isLoadingTypeFormId } = useQuery({
		queryKey: ['getDataFormQuestionnaire'],
		queryFn: getDataFormQuestionnaireAction,
	});

	const companyId = _.toString(_.get(data, 'companyId'));
	const questionFormIdFromData = _.toString(_.get(data, 'questionFormId'));
	const supplierNameFromData = _.toString(_.get(data, 'supplierName'));
	const supplierUidFromData = _.toString(_.get(data, 'supplierUid'));
	const notInCompany = _.get(data, 'notInCompany');
	const campaignId = _.toString(_.get(data, 'campaignId'));

	const onSubmit = () => {
		setShowTypeForm(false);

		saveRSEObjectOnWidgetSubmitAction({
			companyId,
			questionFormId: questionFormIdFromData,
			supplierName: supplierNameFromData,
			supplierUid: supplierUidFromData,
		});
		saveStatusOnWidgetSubmitAction({
			companyId,
			questionFormId: questionFormIdFromData,
			supplierName: supplierNameFromData,
			supplierUid: supplierUidFromData,
			campaignId,
		});
		saveResponseQuestionnaire({ languageCode });

		trackEvent('Supplier Survey Completed', {
			Email: authData?.user?.email,
			Survey: 'Questionnaire maturité RSE',
		});
	};

	const hasAlreadyAnswered = _.get(authData, 'dataForm.hasAlreadyAnswered');

	const exists = _.isString(authData?.dataForm.answeringUserId);
	const otherUserIsAlreadyAnswering = exists && authData?.user.objectId !== authData?.dataForm.answeringUserId;

	const onReady: WidgetProps['onReady'] = () => {
		if (otherUserIsAlreadyAnswering) {
			return;
		}

		if (exists) {
			return;
		}

		saveRSEObjectOnWidgetReadyAction({
			companyId,
			questionFormId: questionFormIdFromData,
			supplierName: supplierNameFromData,
			supplierUid: supplierUidFromData,
		});
	};

	// track event 	when the user lands on the Questionnaire listing page
	useEffect(() => {
		trackEvent('Supplier Survey Land', {
			Email: authData?.user?.email,
			Survey: 'Questionnaire maturité RSE',
		});
	}, [trackEvent]);

	if (hasAlreadyAnswered) {
		return <Navigate to={`${FRONT_PATH_NAMES.contributor}/${authData?.supplier}/synthesis`} />;
	}

	// if (otherUserIsAlreadyAnswering) {
	// 	return (
	// 		<div css={{ padding: '50px 60px 0 60px' }}>
	// 			<GenericEmptyBlock text1={t('answering')} text2={t('answering-2')} />
	// 		</div>
	// 	);
	// }

	return (
		<Container header={<QuestionnaireHeader />} graph={<div />} seoText={`${t('Questionnaire')} - Carbon Maps`}>
			{showTypeForm &&
				data?.formId &&
				!isLoadingTypeFormId &&
				(notInCompany !== true && data?.published === true
? (
					<Widget
						id={data?.formId}
						hidden={{
							company_id: companyId,
							supplier_uid: supplierUidFromData,
							supplier_name: supplierNameFromData,
							questionform_id: questionFormIdFromData,
						}}
						style={{ width: '100%', height: '600px' }}
						className="my-form"
						onSubmit={onSubmit}
						onReady={onReady}
					/>
				)
: (
					<div className={cx('flexColumn', styles.root)}>
						<div className={cx('flexColumn alignCenter width100 textAlignCenter gap16')}>
							<div className={cx('', styles.text1)}>{t('Cette campagne est désormais fermée')}</div>
						</div>
					</div>
				))}
			{isSuccess && (
				<div className={cx('flexColumn', styles.root)}>
					<div className={cx('alignCenter width100 textAlignCenter')}>
						<CheckCircle2 size={33} color={siteColors.green500} />
					</div>
					<div className={cx('flexColumn alignCenter width100 textAlignCenter gap16')}>
						<div className={cx('', styles.text1)}>{t('Réponse enregistrée')}</div>
						<div> {t('questionnaire-thanks')}</div>
						<BButton
							onClick={() => {
								window.open(`${FRONT_PATH_NAMES.contributor}/${authData?.supplier}/synthesis`, '_self');
							}}
							label={t('Voir votre fiche fournisseur')}
							variant={'secondary'}
						/>
					</div>
				</div>
			)}
		</Container>
	);
};

export default Questionnaire;

const saveRSEObjectOnWidgetReadyAction = async ({
	questionFormId,
	supplierName,
	supplierUid,
	companyId,
}: {
	questionFormId: string;
	supplierName: string;
	supplierUid: string;
	companyId: string;
}) => {
	// return;
	const qFormObject = new Parse.Object(classNames.QUESTION_FORM);
	qFormObject.id = questionFormId;

	const companyObj = new Parse.Object(classNames.COMPANY);
	companyObj.id = companyId;

	const fetchPromise = companyObj.fetch();

	const rseQuery = new Parse.Query(classNames.CACHE_RSE_TABLE)
		.select(['answeringUserId'])
		.equalTo('company', companyObj)
		.equalTo('questionForm', qFormObject)
		.equalTo('supplierUid', supplierUid);

	let rseObj = await rseQuery.first();

	if (!rseObj) {
		rseObj = new Parse.Object(classNames.CACHE_RSE_TABLE, {
			company: companyObj,
			questionForm: qFormObject,
			supplierUid,
			supplierName,
		});

		const acl = new Parse.ACL();

		const fetchedCompany = await fetchPromise;
		const companyCode = fetchedCompany.get('code');

		acl.setRoleReadAccess(`${companyCode}_${roleSuffixName.CONTRIBUTOR}`, true);
		acl.setRoleWriteAccess(`${companyCode}_${roleSuffixName.EDITOR}`, true);

		rseObj.setACL(acl);
	}

	rseObj.set('answeringUserId', Parse.User.current()?.id);

	await rseObj.save();
};

const saveRSEObjectOnWidgetSubmitAction = async ({
	questionFormId,
	supplierName,
	supplierUid,
	companyId,
}: {
	questionFormId: string;
	supplierName: string;
	supplierUid: string;
	companyId: string;
}) => {
	// return;
	await Parse.Cloud.run(functionName.questions.saveRSEObjectOnWidgetSubmitAction, {
		questionFormId,
		supplierName,
		supplierUid,
		companyId,
	});
};

const saveStatusOnWidgetSubmitAction = async ({
	companyId,
	questionFormId,
	supplierName,
	supplierUid,
	campaignId,
}: {
	companyId: string;
	questionFormId: string;
	supplierName: string;
	supplierUid: string;
	campaignId: string;
}) => {
	await Parse.Cloud.run('saveStatusOnWidgetSubmit', {
		companyId,
		questionFormId,
		supplierName,
		supplierUid,
		campaignId,
	});
};
