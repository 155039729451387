import React, { MouseEventHandler, ReactNode } from 'react';

import { CircularProgress } from '@mui/material';
import MuiDialog, { type DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import useTheme from '@mui/material/styles/useTheme';
import { type TransitionProps } from '@mui/material/transitions';
import { XCircle } from 'lucide-react';

import BButton from '@carbonmaps/ui/components/saas/BButton';

import { siteColors } from '../../lib/colors';

type Props = {
	title?: ReactNode;
	description?: string;
	open?: boolean;
	toggle?: () => void;
	primaryButtonText?: ReactNode;
	secondaryButtonText?: string;
	onPrimaryButtonAction?: () => void;
	withCancelButton?: boolean;
	formId?: string;
	content?: ReactNode;
	children?: ReactNode;
	onClick?: MouseEventHandler<HTMLDivElement>;
	// =============
	isLoading?: boolean;
	paperProps?: any;
	actions?: any;
	closeButton?: boolean;
	className?: string;
	isDrawer?: boolean;
} & DialogProps;

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide
		direction="left"
		ref={ref}
		{...props}
		easing={{
			enter: 'cubic-bezier(0.15, 0.6, 0.5, 1)',
			exit: 'cubic-bezier(0.4, 0.3, 0.5, 1)',
		}}
	/>;
});

const Dialog = ({
	title,
	description,
	open,
	toggle,
	closeButton = false,
	primaryButtonText,
	secondaryButtonText,
	onPrimaryButtonAction,
	withCancelButton = true,
	formId,
	content,
	children,
	onClick,
	// ============
	isLoading = false,
	actions,
	paperProps = {},
	className,
	isDrawer = false,
	...dialogProps
}: Props) => {
	const theme = useTheme();

	const handlePrimaryButtonAction = () => {
		if (onPrimaryButtonAction) onPrimaryButtonAction();
		// if (!toggle) return;
		// toggle();
	};

	return (
		<MuiDialog
			{...dialogProps}
			open={open}
			onClose={toggle}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			TransitionComponent={isDrawer ? Transition : undefined}
			transitionDuration={{ enter: 400, exit: 400 }}
			PaperProps={{
				style: {
					borderRadius: isDrawer ? '0' : '16px',
					padding: '8px',
					...(isDrawer && {
						position: 'fixed',
						right: 0,
						margin: 0,
						height: '100%',
						maxHeight: '100%',
						maxWidth: '500px',
					}),
					...paperProps,
				},
			}}
			className={className}
			{...(isDrawer && {
				fullScreen: true,
				hideBackdrop: false,
			})}
		>
			{closeButton && (
				<DialogTitle textAlign="right">
					<IconButton
						aria-label="close"
						onClick={toggle}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => {
								return theme.palette.grey[500];
							},
						}}
					>
						<XCircle color={theme.palette.grey[700]} />
					</IconButton>
				</DialogTitle>
			)}

			{title && (
				<DialogTitle id="alert-dialog-title" variant="h1" textAlign="left">
					{title}
				</DialogTitle>
			)}
			<DialogContent
				sx={{
					fontWeight: 400,
					fontSize: 16,
					lineHeight: '21px',
					color: siteColors.text,
				}}
			>
				<DialogContentText id="alert-dialog-description" color={siteColors.text}>
					{description}
				</DialogContentText>
				{children ?? content}
			</DialogContent>

			{
				actions ?
					(
						actions
					) :
					(
						<DialogActions
							sx={{
								justifyContent: 'flex-start',
							}}
						>
							{/* primary button */}
							{onPrimaryButtonAction && (
								<BButton
									id={'btn-primary-action'}
									variant="primary"
									isDisabled={isLoading}
									onClick={handlePrimaryButtonAction}
									label={
										isLoading ? <CircularProgress sx={{ color: siteColors.common.white }} size={20} /> : primaryButtonText
									}
									addStyles={{ display: 'inline-flex ', marginLeft: '8px' }}
								/>
							)}

							{/* cancel button */}
							{withCancelButton && (
								<BButton
									id={'btn-secondary-action'}
									onClick={toggle}
									variant="secondary"
									label={secondaryButtonText}
									addStyles={{ display: 'inline-flex ', marginLeft: '8px' }}
								/>
							)}
						</DialogActions>
					)}
		</MuiDialog>
	);
};

export default Dialog;
