import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { CARBON_INDICATOR } from '@carbonmaps/ui/utils/constants';
import { css } from '@emotion/css';
import { Cloudy, Droplets, Weight } from 'lucide-react';
import Unit from '../../../../components/units/Unit';
import Card from '../../../../components/widget/Card';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';
import ImpactKpi from '../../components/ImpactKpi';
import KPICard from '../../components/KPICard';
import KpiValue from '../../components/KpiValue';
import TonnageKpi from '../../components/TonnageKpi';
import { usePackagingRecycled } from '../../packagings.hooks';

const styles = {
	impactValue: css({
		color: `var(--color)`,
	}),
	impactUnit: css({ paddingLeft: 2 }),
};

const GlobalIndicatorBlock = () => {
	const { indicator } = useApp();
	const { t } = useTranslation();

	const { data, isLoading } = usePackagingRecycled({ viewMode: indicator });

	return (
		<Card
			title={t('Indicateurs')}
			icon={<Cloudy color={siteColors.grey700} size={20} />}
			sx={{ padding: 0, flexWrap: 'nowrap', width: '100%' }}
			withBorder={false}
			center={false}
		>
			<ImpactKpi data={data} isLoading={isLoading} withHelp={data && !data[`${indicator}Impact`]} />
			<TonnageKpi tonnage={data?.tonnage} isLoading={isLoading} withHelp={data && !data?.tonnage} />
		</Card>
	);
};

export default GlobalIndicatorBlock;
