import { Fragment, SyntheticEvent, useEffect } from 'react';

import { css, cx } from '@emotion/css';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import TabsBar, { TabItem } from '../../../components/TabsBar';
import Container from '../../../components/layout/list/Container';
import { ITab } from '../../../components/layout/page/SheetPageLayout';
import { useTranslation } from '../../../hooks/useTranslation';
import { useGetQuestionFormById } from '../questionForms.hooks';

import QuestionFormDetailsHeader from './blocks/QuestionFormDetailsHeader';
import QuestionFromDetailsOverView from './tabs/overview/QuestionFromDetailsOverView';
import QuestionFormDetailsSuppliers from './tabs/suppliers/QuestionFormDetailsSuppliers';

const tabs: Record<string, ITab> = {
	overview: {
		title: 'overview',
		element: <QuestionFromDetailsOverView />,
	},
	suppliers: {
		title: 'suppliers',
		element: <QuestionFormDetailsSuppliers />,
	},
};

const styles = {
	tabContainer: css({
		top: 58,
		margin: '0 auto',
		marginTop: '17px !important',
		position: 'sticky',
		'&.sticky': { width: '100%', borderRadius: 0 },
	}),

	contentContainerClassName: css({
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	}),
};

const QuestionFormDetails = () => {
	const params = useParams();
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const { setBreadcrumbs } = useApp();
	const languageCode = getIsoCountryCode(i18n.language);

	const currentTab = params.tab || _.keys(tabs)[0];

	const { data: questionForm } = useGetQuestionFormById({ id: _.toString(params.questionFormId) });
	const qName = _.get(questionForm, `translation.${languageCode || 'fr'}.name`) || '---';

	useEffect(() => {
		const title2 = _.get(tabs[currentTab], 'title');
		setBreadcrumbs([{ text: t('Collecter'), link: `${FRONT_PATH_NAMES.questionForms}/general` },
		{ text: qName },
			...(title2 ? [{ text: t(title2) }] : [])]);
	}, [t, params.tab, currentTab, setBreadcrumbs, qName]);

	const tabEntries = Object.entries(tabs);

	const defaultTab = tabEntries[0];

	const onTabChange = (_: SyntheticEvent, value: string) => {
		navigate(`${FRONT_PATH_NAMES.questionForms}/details/${params.questionFormId}/${value}`);
	};

	return (
		// <QuestionFormDetailsHeader />
		<Container
			header={<QuestionFormDetailsHeader />}
			containedContent={false}
			contentContainerClassName={styles.contentContainerClassName}
		>
			<TabsBar
				value={params.tab || defaultTab[0]}
				onChange={onTabChange}
				className={cx('widthContainer', styles.tabContainer)}
			>
				{tabEntries.map(([tabName, { title, disabled }]) => {
					return <TabItem key={tabName} label={t(title)} value={tabName} disabled={disabled} />;
				})}
			</TabsBar>
			<div className={cx('widthContainer')}>
				{tabEntries.map(([tabName, { element }]) => {
					return tabName === (params.tab || defaultTab[0]) && <Fragment key={tabName}>{element}</Fragment>;
				})}
			</div>
		</Container>
	);
};

export default QuestionFormDetails;
