import { ComponentProps, useMemo } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { ShoppingCart } from 'lucide-react';

import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import BlockCard from '../../../../../../components/BlockCard';
import BlockListItem from '../../../../../../components/BlockListItem';
import BlockTitle from '../../../../../../components/BlockTitle';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import SupplierModel from '../../../../../../models/Supplier.model';

type Props = {
	supplier?: SupplierModel;
};

const SupplierQuantityBlock = ({ supplier }: Props) => {
	const theme = useTheme();

	const { t } = useTranslation();

	// const soldItemsProducts = supplier?.getSoldItemsProducts();
	const tonnage = supplier?.getTonnage();

	const formedData = useMemo(() => {
		const dataComponent: ComponentProps<typeof BlockListItem>[] = [
			{
				label: 'Tonnage total',
				value: `${formatNumber(tonnage, undefined, 1)} ${t('tonnes')}`,
				isNegativeNumber: tonnage ? tonnage < 0 : true,
			},
			// {
			// 	label: 'soldItems',
			// 	value: `${formatNumber(soldItemsProducts, undefined, 0)} ${t('units')}`,
			// 	isNegativeNumber: soldItemsProducts ? soldItemsProducts < 0 : true,
			// 	// isNegativeNumber: false,
			// },
		];
		return dataComponent;
	}, [t, tonnage]);

	return (
		<>
			<BlockTitle icon={<ShoppingCart size={20} color={theme.palette.grey[700]} />}>
				{t('product-quantity-card-title')}
			</BlockTitle>
			<BlockCard>
				{formedData.map(({ label, value }, index) => {
					return (
						<Box key={index}>
							<Box display="flex" alignItems="center" justifyContent="space-between">
								<Typography mr="6px" color={theme.palette.grey[800]} fontSize={'14px'} fontWeight={600}>
									{t(label as any)}
								</Typography>
								<Typography mr="6px" className="number">
									{value}
								</Typography>
							</Box>
						</Box>
					);
				})}
			</BlockCard>
		</>
	);
};

export default SupplierQuantityBlock;
