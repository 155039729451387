import { useCallback, useState } from 'react';

import { css, cx } from '@emotion/css';
import { DialogTitle, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { FileDown } from 'lucide-react';
import { useToggle } from 'react-use';
import { read, utils } from 'xlsx';

import { IQuotesCsv } from '@carbonmaps/shared/types/reportClient.types';
import { createQuotesAction } from '@carbonmaps/ui/actions/quote.actions';
import BButton from '@carbonmaps/ui/components/saas/BButton';

import Dialog from '../../../components/dialogs/Dialog';
import ImportDialog from '../../../components/dialogs/ImportDialog';
import LoadingDialog from '../../../components/dialogs/LoadingDialog';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { stylesPrepareQuestion } from '../../questionForms/details/_old/screens/suppliers-selection/QuestionFormSuppliers';

export const stylesFlashMessage = {
	flash: css({
		background: '#fff',
		color: siteColors.grey800,
		fontWeight: 400,
		minWidth: 400,
		maxHeight: 400,
	}),
};

const formatFlashMessage = (returnedData: any, t: any) => {
	return {
		successCount: returnedData?.successCount ?? 0,
		countQuotesCreated: returnedData?.countQuotesCreated ?? 0,
		countNewClient: returnedData?.countNewClient ?? 0,
		countNotImported: returnedData?.errorUids?.length ?? 0,
		errorUids: returnedData?.errorUids,
	};
};

const ImportButton = () => {
	const { t } = useTranslation();
	const [fileName, setFileName] = useState<string | null>(null);
	const [error, setError] = useState<string | null>(null);
	const [data, setData] = useState<any[]>([]);
	const queryClient = useQueryClient();
	const [openInfo, setOpenInfo] = useToggle(false);

	const [openImport, setOpenImport] = useState(false);
	const [infoMessage, setInfoMessage] = useState<any>(null);

	const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {

		const file = event.target.files?.[0];
		if (!file) return;
		// Récupérer le nom du fichier
		const fileName = file.name;
		setFileName(fileName);

		// Vérifier si le fichier est un CSV
		if (file.type !== 'text/csv') {
			setError(t('import-contact-error-not-csv'));
			setData([]);
			return;
		}

		if (file) {
			const reader = new FileReader();

			reader.onload = (e) => {
				if (e.target?.result) {
					const textDecoder = new TextDecoder('utf-8');
					const decodedText = textDecoder.decode(new Uint8Array(e.target.result as ArrayBuffer));
					const workbook = read(decodedText, { type: 'string' });
					const sheetName = workbook.SheetNames[0];
					const sheet = workbook.Sheets[sheetName];

					const sheetFormatted: any = {};

					for (const key of _.keys(sheet)) {
						sheetFormatted[key] =
							key === '!ref'
								? sheet['!ref']
								: {
									...sheet[key],
									v: sheet[key]?.w, // to avoid problem of date
								};
					}

					const parsedData = utils.sheet_to_json<any>(sheetFormatted, { header: 1 });

					// Vérifier le nombre de lignes sans compter la première ligne
					const varMaxRow = 5000;

					if (parsedData.length - 1 > varMaxRow) {
						setError(t('import-error-too-many-rows', { varMaxRow }));
						setData([]);
						return;
					}

					const formattedData = (parsedData.slice(1) as any)?.map((row: any, key: string) => {
						return {
							quoteName: row[0],
							groupName: row[1],
							uidClient: row[2],
							uidProduct: row[3],
							labelProduct: row[4],
							soldItems: row[5],
							order: key,
						};
					});

					setData(formattedData);
				}
			};

			reader.readAsArrayBuffer(file);
		}
	};

	const {
		mutate: createQuotes,
		isLoading,
	} = useMutation({
		mutationKey: ['createQuotes'],
		mutationFn: createQuotesAction,
		onSuccess: async (returnedData: any) => {
			// add setTimeout because there are a job who update the report client
			setTimeout(() => {
				queryClient.invalidateQueries({ queryKey: ['getOptionsAutocompleteClient'] });
				queryClient.invalidateQueries({ queryKey: ['getQuoteDataAction'] });
				queryClient.invalidateQueries({ queryKey: ['getClientSheetById'] });
				queryClient.invalidateQueries({ queryKey: ['findDevisClient'] });
				queryClient.invalidateQueries({ queryKey: ['findReportsClientTable'] });
				queryClient.invalidateQueries({ queryKey: ['findQuoteReportsClientTable'] });
				queryClient.invalidateQueries({ queryKey: ['findQuoteReportsClientGraph'] });
				setInfoMessage(formatFlashMessage(returnedData, t));
				setOpenInfo(true);

			}, 3000);
		},
		onError: (error: any) => {
			if (error.message) {
				console.log(error.message);
			}
		},
	});

	// ---- track event ---- //
	const analytics = useJuneTrack();

	const trackEvent = useCallback(
		(eventName: string, options?: any) => {
			if (!analytics) return;
			analytics.track(eventName, options, {
				context: { category: 'Simulate' },
			});
		},
		[analytics],
	);

	const submitHandler = () => {
		// grouper par quoteName
		createQuotes({
			data: data.filter((obj) => { return obj?.uidClient && obj?.uidProduct; }),
		} as IQuotesCsv);
		trackEvent('Import Quotes');
	};

	return (
		<>
			<div className="flexRow gap16">
				<BButton
					label={t('import-quote-title')}
					variant="primary"
					onClick={() => {
						setOpenImport(true);
					}}
				/>
			</div>

			<ImportDialog
				title={t('import-quote-title')}
				error={error}
				open={openImport}
				withCancelButton={false}
				closeButton
				toggle={() => {
					setOpenImport(!openImport);
				}}
				paperProps={{
					maxWidth: 1200,
					width: 482,
					minHeight: 250,
				}}
				description={
					<div>
						<Typography variant="body1" textAlign="left">
							{t('import-quote-description')}
						</Typography>
						<div>
							<a href="/template_quote.csv" download="template_quote.csv" className={stylesPrepareQuestion.link}>
								{t('import-quote-download-model')}
							</a>
						</div>
					</div>
				}
				primaryButtonText={t('import-quote-to-validate')}
				handlePrimaryButtonAction={() => {
					setOpenImport(false);
					submitHandler();
				}}
			>
				<div className={cx('flexColumn gap16', stylesPrepareQuestion.fileInput)}>
					<input type="file" accept=".csv" onChange={handleFileUpload} />
					<FileDown color={siteColors.grey700} />
					<Typography color={siteColors.grey700}>{t('import-quote-choose-file')}</Typography>
					{fileName && <div>{fileName}</div>}
				</div>
			</ImportDialog>
			<LoadingDialog open={isLoading} content={`${t('Import en cours')}...`} />

			<Dialog
				isLoading={false}
				description={''}
				open={openInfo}
				toggle={setOpenInfo}
				closeButton={true}
				withCancelButton={false}
				paperProps={{
					maxWidth: '100vw',
				}}
			>
				<div className="flexColumn gap24">
					<div className="flexColumn gap16">
						<DialogTitle
							id="alert-dialog-title"
							variant="h1"
							textAlign="left"
							sx={{ margin: 0, padding: 0, color: siteColors.primary, fontSize: '24px', fontWeight: 600 }}
						>
							{t('import-quote-title')}
						</DialogTitle>
					</div>
					<div className={cx('flexColumn nowrap gap16')}>
						<div className={cx('flexColumn nowrap gap16', stylesFlashMessage.flash)} >
							<div className={cx('flexColumn nowrap gap8')}>
								<div>✅ {t('alert-imported-rows', { nb: infoMessage?.successCount })}</div>
								<div>✅  {t('alert-quotes-created', { nb: infoMessage?.countQuotesCreated })}</div>
								<div>✅ {t('alert-created-clients', { nb: infoMessage?.countNewClient })}</div>
								<div>❌  {t('alert-rows-not-imported', { nb: infoMessage?.countNotImported })}</div>
							</div>
							<div>
								{infoMessage?.errorUids?.length ?
									<>	<div>{t('uid-error-not-exists')}:	</div>
										<ul>
											{infoMessage?.errorUids?.map((item: any, index: number,
											) => {
												return (
													<li key={`${index}quote`}>{item}</li>
												);
											})}
										</ul>
									</>
									: <></>
								}
							</div>
						</div>
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default ImportButton;
