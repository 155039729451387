import { useState } from 'react';

import { css, cx } from '@emotion/css';
import { IconButton, Typography } from '@mui/material';
import _ from 'lodash';
import { ArrowLeft, CheckCircle2, Circle, FileDown } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { read, utils } from 'xlsx';

import { InfoMessageContact, UserContact } from '@carbonmaps/shared/types/supplier.types';
import { formatDataSupplier, isValidEmail, isWhitespace } from '@carbonmaps/shared/utils/utils';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import AlertContactUpload from '../../../../../../components/dialogs/AlertContactUpload';
import Dialog from '../../../../../../components/dialogs/Dialog';
import ImportDialog from '../../../../../../components/dialogs/ImportDialog';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../../../lib/colors';
import { fetchSuppliersAndContact, useGetPreCampaignSuppliersContacts, useQuestionFormStore } from '../../../../questionForms.hooks';

import QuestionFormSuppliersTable, {
	getContactStringValue,
} from './QuestionFormSuppliersTable';
import QuestionFormSuppliersTableSelect from './QuestionFormSuppliersTableSelect';

export const stylesPrepareQuestion = {
	main: css({
		padding: '24px 32px',
		gap: 32,
	}),
	hidden: css({
		visibility: 'hidden',
	}),
	link: css({
		fontSize: '14px !important',
		color: cn(siteColors.primary, '!important'),
	}),
	fileInput: css({
		input: {
			opacity: 0,
			position: 'absolute',
			width: '402px',
			height: '200px',
			cursor: 'pointer',
		},
		fontSize: '14px !important',
		color: cn(siteColors.primary, '!important'),
		width: '402px',
		height: '200px',
		justifyContent: 'center !important',
		alignItems: 'center !important',
		textAlign: 'center',
		padding: '8px 16px',
		border: cn('1px dashed', siteColors.grey500),
		borderRadius: '8px',
		transition: 'all .2s',
		'&:hover': {
			border: cn('1px dashed', siteColors.primary),
			background: siteColors.primary10,
		},
	}),
};

type Props = {
	setScreen: (screen: number) => void;
};

const QuestionFormSuppliers = ({ setScreen }: Props) => {


	return (
		<div className={cx('')}>
			<TopBar setScreen={setScreen} />
			<div className={cx('flexColumn', stylesPrepareQuestion.main)}>
				<Note />
				<QuestionFormSuppliersTable />
			</div>
		</div>
	);
};

export default QuestionFormSuppliers;

export const topStyles = {
	main: css({
		height: 80,
		borderBottom: cn('1px solid', siteColors.grey500),
		paddingLeft: '24px',
		paddingRight: '24px',
	}),
	text1: css({
		color: siteColors.text,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 600,
		lineHeight: 'normal',
		marginBottom: 4,
	}),
	text2: css({
		color: siteColors.grey900,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontFamily: 'Inter',
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '140%',
	}),
	leftGroup: css({
		flexGrow: 1,
	}),
	text3: css({
		marginLeft: 12,
		color: siteColors.grey900,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '150%',
	}),
	text4: css({
		fontWeight: 'bold',
	}),
};

const TopBar = ({ setScreen }: { setScreen: (screen: number) => void }) => {
	const { t } = useTranslation();
	const { supplierContacts } = useQuestionFormStore();

	const contacts = supplierContacts
		.map((c) => {
			return c.subRows;
		})
		.flat();

	const filledContacts = contacts.filter((e) => {
		return !isWhitespace(getContactStringValue(e));
	});

	const allComplete = filledContacts.length === contacts.length;

	return (
		<div className={cx('flexRow alignCenter', topStyles.main)}>
			<div className={cx('flexRow alignCenter', topStyles.leftGroup)}>
				<div
					css={{
						marginRight: 21,
					}}
				>
					<IconButton
						disableRipple
						onClick={() => {
							setScreen(0);
						}}
					>
						<ArrowLeft color={siteColors.grey700} size={20} />
					</IconButton>
				</div>

				{supplierContacts.length <= 0
					? (
						<div className={cx('', topStyles.text1)}>{t('Fournisseurs à contacter')}</div>
					)
					: (
						<div className={cx('')}>
							<div className={cx('', topStyles.text1)}>{t('Fournisseurs à contacter')}</div>
							<div
								className={cx('flexRow alignCenter', supplierContacts.length <= 0 ? stylesPrepareQuestion.hidden : '')}
							>
								{allComplete
									? (
										<CheckCircle2 color={siteColors.green500} size={20} />
									)
									: (
										<Circle size={20} color="#F0BF41" className={cx('')} />
									)}

								<div className={cx('', topStyles.text3)}>
									<span className={cx('', topStyles.text4)}>
										{filledContacts.length}/{contacts.length}
									</span>{' '}
									{t('contact renseignés')}
								</div>
							</div>
						</div>
					)}
			</div>

			<div className={cx('flexRow alignCenter')}>
				<ToggleModal />
			</div>
		</div>
	);
};

const noteStyles = {
	main: css({
		background: siteColors.grey200,
		padding: 24,
		border: cn('1px solid', siteColors.grey500),
		borderRadius: 16,
	}),
};

const Note = () => {
	const { t } = useTranslation();

	const { supplierContacts } = useQuestionFormStore();

	return (
		<div
			className={cx('flexRow alignCenter width100', noteStyles.main)}
			dangerouslySetInnerHTML={{
				__html:
					supplierContacts.length > 0
						? t('question-forms.complete-suppliers-info')
						: t('question-forms.start-to-add-suppliers'),
			}}
		></div>
	);
};



interface iSupplierNotExist {
	uid: string;
	email: string;
}


const ToggleModal = () => {
	const [open, setOpen] = useState(false);
	const [openImport, setOpenImport] = useState(false);
	const { t, i18n } = useTranslation();
	const [data, setData] = useState<UserContact[]>([]);
	const [error, setError] = useState<string | null>(null);
	const { setSuppliersContacts } = useQuestionFormStore();
	const languageCode = getIsoCountryCode(i18n.language);
	const [loading, setLoading] = useState(false);
	const [fileName, setFileName] = useState<string | null>(null);
	const [openInfo, setOpenInfo] = useState(false);
	const params = useParams();

	const [infoMessage, setInfoMessage] = useState<InfoMessageContact>(
		{
			nbSupplier: 0,
			nbContact: 0,
			nbContactNotImported: 0,
			supplierNotExists: [],
			emailsInvalid: [],
			existingContact: [],
		},
	);

	const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0]; // Retrieve the first file from the input
		if (!file) return; // Exit if no file is selected

		const fileName = file.name; // Extract the file name
		setFileName(fileName); // Save the file name in state

		// Ensure the file is a CSV file
		if (file.type !== 'text/csv') {
			setError(t('import-contact-error-not-csv')); // Set error if the file is not a CSV
			setData([]); // Clear any existing data
			return;
		}

		// If a valid file is provided, initialize FileReader
		const reader = new FileReader();

		// Handle the file once it's loaded
		reader.onload = (e) => {
			if (!e.target?.result) return; // Exit if no result is found

			// Decode the file content as UTF-8 text
			const textDecoder = new TextDecoder('utf-8');
			const decodedText = textDecoder.decode(new Uint8Array(e.target.result as ArrayBuffer));

			// Read the decoded text as a workbook
			const workbook = read(decodedText, { type: 'string' });

			// Extract the first sheet from the workbook
			const sheetName = workbook.SheetNames[0];
			const sheet = workbook.Sheets[sheetName];

			// Convert the sheet data into a JSON array
			const parsedData = utils.sheet_to_json<UserContact>(sheet, { header: 1 });

			// Check if the number of rows (excluding the header) exceeds the maximum allowed
			const maxRows = 300;

			if (parsedData.length - 1 > maxRows) {
				setError(t('import-error-too-many-rows', { varMaxRow: maxRows })); // Set error for too many rows
				setData([]); // Clear data
				return;
			}

			// Format the parsed data, excluding the header row
			const formattedData = parsedData.slice(1).map((row: any) => {
				return {
					supplierName: row[0], // Column 1: Supplier Name
					contactFirstName: row[1], // Column 2: Contact First Name
					contactName: row[2], // Column 3: Contact Last Name
					contactEmail: row[3], // Column 4: Contact Email
					contactLanguage: getIsoCountryCode(row[4]), // Column 5: Contact Language
				};
			});

			setData(formattedData); // Update state with the formatted data
		};

		// Read the file as an ArrayBuffer for decoding
		reader.readAsArrayBuffer(file);
	};

	const handleSaveData = async () => {
		if (error) return; // Exit if there is an existing error

		setLoading(true); // Activate the loading state for the UI

		if (!data || data.length === 0) {
			// If no data is available, stop loading and exit
			setLoading(false);
			return;
		}

		const allContactsEmail = _.uniq(data.map((s) => { return s.contactEmail; }).filter(Boolean));
		// Fetch existing suppliers and associated contacts from an API or database
		const { suppliers, existingContact } = await fetchSuppliersAndContact({
			nameSuppliers: data.map((s) => { return s.supplierName; }).filter(Boolean),
			contactsEmail: allContactsEmail,
			questionFormId: params?.questionFormId,
		}) as never;

		// Create a Map for quick access to suppliers by their names
		const mapSuppliers = new Map<string, any>(
			(suppliers as any).map((result: any) => { return [result.name, result]; }),
		);

		// Initialize structures to store valid results and errors
		const _newData: Record<string, any>[] = []; // Holds valid data for saving
		const supplierNotExists: any[] = []; // Tracks suppliers not found in the database
		const emailsInvalid: any[] = []; // Tracks invalid email addresses
		let countErrorContact = 0; // Counter for invalid contact entries

		// Iterate through the data to validate and transform each entry
		data.forEach((s) => {
			if (!s.supplierName && !s.contactEmail) return; // Skip empty rows

			const itemMap = mapSuppliers.get(s.supplierName); // Look up supplier in the Map

			if (!itemMap) {
				// If the supplier doesn't exist in the database
				supplierNotExists.push({
					uid: s.supplierName,
					email: s.contactEmail,
				});
			}

			if (!s.contactEmail || !isValidEmail(s.contactEmail)) {
				// If the email is invalid or missing
				countErrorContact++; // Increment the error counter
				emailsInvalid.push({ email: s.contactEmail }); // Add to invalid emails list
				return; // Stop processing this entry, no further action needed
			}

			if (itemMap) {
				// If the supplier exists and the email is valid, build a valid entry
				_newData.push({
					id: itemMap.id,
					supplierName: s.supplierName || '---', // Default if supplier name is missing
					supplierUid: itemMap.uid || '---', // Default if supplier UID is missing
					contactEmail: s.contactEmail,
					contactFirstName: s.contactFirstName,
					contactLastName: s.contactName,
					contactLanguage: s.contactLanguage ?? languageCode, // Default language if not provided
				});
			}
		});

		// Final formatting of the data for saving
		const fData = formatDataSupplier(_newData);

		// Compute statistics for informational messages
		const uniqueSuppliers = _.uniqBy(_newData, 'supplierUid'); // Unique suppliers

		const uniqueContacts = _.uniqBy(_newData, 'contactEmail'); // Unique contacts


		setSuppliersContacts(fData); // Update the state with valid contacts

		// Build the information message for the user
		setInfoMessage({
			supplierNotExists, // List of suppliers not found
			emailsInvalid, // List of invalid email addresses
			existingContact, // Existing contacts from the database
			nbSupplier: uniqueSuppliers.length, // Count of unique suppliers
			nbContact: uniqueContacts.length, // Count of unique contacts
			nbContactNotImported: countErrorContact, // Count of contacts not imported
		} as never);

		setOpenImport(false); // Close the import modal
		setLoading(false); // Deactivate the loading state
		setError(null); // Reset any errors
		setOpenInfo(true); // Open the informational modal for the user
	};


	return (
		<>
			<div className="flexRow gap16">
				<BButton
					label={t('Importer des contacts')}
					variant="primary"
					onClick={() => {
						setOpenImport(true);
					}}
				/>

				<BButton
					label={t('Ajouter des fournisseurs')}
					variant="primary"
					onClick={() => {
						setOpen(true);
					}}
				/>
			</div>
			<Dialog
				open={open}
				withCancelButton={false}
				closeButton
				toggle={() => {
					setOpen(!open);
				}}
				paperProps={{
					maxWidth: 1200,
					width: 1200,
					minHeight: 600,
				}}
			>
				<QuestionFormSuppliersTableSelect />
			</Dialog>
			<ImportDialog
				title={t('import-contact-title')}
				error={error}
				isLoading={loading}
				open={openImport}
				withCancelButton={false}
				closeButton
				toggle={() => {
					setOpenImport(!openImport);
				}}
				paperProps={{
					maxWidth: 1200,
					width: 482,
					minHeight: 250,
				}}
				description={
					<div>
						<Typography variant="body1" textAlign="left">
							{t('import-contact-description')}
						</Typography>
						<div>
							<a href="/template_contact.csv" download="template_contact.csv" className={stylesPrepareQuestion.link}>
								{t('import-contact-download-model')}
							</a>
						</div>
					</div>
				}
				primaryButtonText={t('import-contact-to-validate')}
				handlePrimaryButtonAction={handleSaveData}
			>
				<div className={cx('flexColumn gap16', stylesPrepareQuestion.fileInput)}>
					<input type="file" accept=".csv" onChange={handleFileUpload} />
					<FileDown color={siteColors.grey700} />
					<Typography color={siteColors.grey700}>{t('import-contact-choose-file')}</Typography>
					{fileName && <div>{fileName}</div>}
				</div>
			</ImportDialog>

			<AlertContactUpload
			title={t('import-contact-title')}
			openInfo={openInfo} setOpenInfo={setOpenInfo} infoMessage={infoMessage} />

		</>
	);
};





