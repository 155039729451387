import dayjs from 'dayjs';
import { Workbook } from 'exceljs';
import _ from 'lodash';

import { classNames, functionName, WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { getObjScoring } from '@carbonmaps/shared/utils/scoring';

import { MODELIZATION_CONFIG_ITEMS } from '../../../../utils/supplier';

const HEADING = {
	uid: 'ID supplier',
	name: 'Label supplier',
	carbonIntensity: WORDING_TERMS.INTENSITY,
	carbonImpact: WORDING_TERMS.IMPACT,
	volume: 'Tonnage',
};

const getRowsValues = (item: any, headers: any, t: any) => {
	return Object.keys(headers).map((key: string) => {
		if (key.startsWith(`${classNames.QUESTION_FORM}$`)) {
			if (_.isObject(item[key]) && _.isEmpty(item[key])) {
				return t('En attente de réponse');
			}

			if (_.isEmpty(item[key])) {
				return t('Non contacté');
			}

			return `Note: ${item[key]?.note || '-'} | ${item[key]?.totalPoints || '0'} points | ${t('langue')}: ${item[key]?.languageCode || '-'}`;
		}

		return item[key] || '';
	});
};

export async function exportSupplierAction(params: any, t: any, languageCode: string) {
	try {
		const { suppliers: data, headings } = await Parse.Cloud.run(functionName.suppliers.exportSuppliers, { ...params, supplierIds: params?.ids });
		const workbook: any = new Workbook();
		const worksheet = workbook.addWorksheet('My Worksheet');

		const RSE_HEADING = {};

		_.entries(headings).forEach(([k, v]) => {
			return (RSE_HEADING as any)[k] = (v as any)[languageCode] || (v as any)['fr'];
		});

		const columnsName = Object.values({ ...HEADING, ...RSE_HEADING /* ...HEADING_INFO */ }).map((key) => {
			if (params?.selectedYear === -1 && key === 'per_period2') {
				return t('all_year');
			}

			return t(key, { period: params?.selectedYear });
		});

		worksheet.addRow(columnsName);

		data.forEach((item: any) => {
			const finalItem = {
				...item,
				carbonImpact: parseFloat(isNaN(item?.carbonImpact) ? 0 : item?.carbonImpact?.toFixed(2)),
				carbonIntensity: parseFloat(isNaN(item?.carbonIntensity) ? 0 : item?.carbonIntensity?.toFixed(2)),
				onboarding: MODELIZATION_CONFIG_ITEMS.get(item.onboarding)?.label,
				scoring: getObjScoring(item.scoring)?.label,
			};
			worksheet.addRow(getRowsValues(finalItem, { ...HEADING, ...RSE_HEADING/* , ...HEADING_INFO */ }, t));
		});

		// Change the height of the first row (column A)
		worksheet.getRow(1).height = 25;

		// Change the column width
		worksheet.getColumn(1).width = 25; // column A
		worksheet.getColumn(2).width = 35; // column B
		worksheet.getColumn(3).width = 25; // column C
		worksheet.getColumn(4).width = 50; // column D
		worksheet.getColumn(5).width = 30; // column E
		worksheet.getColumn(6).width = 20; // column F

		_.forEach(_.keys(headings), (heading: any, index: number) => {
			worksheet.getColumn(index + 6).width = 75; // column G
		});

		worksheet.eachRow((row: any, rowNumber: any) => {
			row.eachCell((cell: any, colNumber: any) => {
				if (rowNumber === 1) {
					cell.alignment = { vertical: 'middle' };
					cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
					cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'A4C2F4' } };
				}
			});
		});

		const companyName = params?.companyName || '__';
		const nameSheet = `${companyName}_${t('fournisseur')}_${dayjs(new Date())?.format('DD-MM-YYYY')}.${params.format}`;

		// Create a Blob to save the workbook
		workbook[params.format ?? 'xlsx'].writeBuffer().then((buffer: any) => {
			const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = nameSheet;
			a.click();
			window.URL.revokeObjectURL(url);
		});
	} catch (error) {
		return Promise.reject(error);
	}
}
