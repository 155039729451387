import { useEffect } from 'react';

import { css, cx } from '@emotion/css';
import { useQueryClient } from '@tanstack/react-query';
import { StringParam, useQueryParam } from 'use-query-params';

import {
	FRONT_PATH_NAMES,
} from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';


import MetaDataCard from '../../components/card/MetaDataCard';
import SEO from '../../components/SEO';
import { useTranslation } from '../../hooks/useTranslation';

import ScenarioContent from './content/ScenarioContent';
import ScenarioHeader from './content/ScenarioHeader';
import IndicatorsBlock from './IndicatorsBlock';
import LifeCycleBlock from './LifeCycleBlock';
import MaterialBreakDown from './MaterialBreakDown';
import RepartitionBlock from './RepartitionBlock';
import { useDataSimulation } from './useDataSimulation.hooks';

const styles = {
	button: css({
		padding: '18px 24px !important',
		borderRadius: '8px !important',
		textTransform: 'none !important',
	} as any),

	grid: css({
		display: 'grid',

		gridTemplateColumns: '7fr 5fr',
		'@media screen and (min-width: 1380px)': {
			gridTemplateColumns: '8fr 4fr',
		},
	}),

	sidebar: css({
		padding: '32px 26px',
	}),

	block: css({
		background: 'white',
		border: 'none!important',
	}),
};
const PACKAGING_TAB = 'packagings';

const Scenario = () => {
	const { setBreadcrumbs } = useApp();
	const queryClient = useQueryClient();
	const { simulation } = useDataSimulation();

	// translation
	const { t } = useTranslation();

	// ---- invalidateQueries getScenario at first  ---- //
	useEffect(() => {
		return () => {
			queryClient.invalidateQueries({ queryKey: ['getScenario'] });
		};
	}, []);

	useEffect(() => {
		setBreadcrumbs([{ text: t('Éco-concevoir'), link: FRONT_PATH_NAMES.simulation }]);
	}, [setBreadcrumbs, t]);

	const [scenarioSheetTab] = useQueryParam('scenarioSheetTab', StringParam);

	const renderValidScenario = (
		<div className={cx(styles.grid)}>
			<div
				css={{
					maxHeight: '84.5vh',
					// maxHeight: '100%',
					overflowY: 'scroll',
				}}
			>
				<ScenarioContent />
			</div>
			<div
				className={cx('bgGrey300', styles.sidebar)}
				css={{
					maxHeight: '84.5vh',
					// maxHeight: '100%',
					overflowY: 'scroll',
				}}
			>
				<IndicatorsBlock />
				<LifeCycleBlock />

				{ scenarioSheetTab !== PACKAGING_TAB ? 	<RepartitionBlock /> : <MaterialBreakDown /> }
				<MetaDataCard
					importedOn={(simulation as any)?.product?.createdAt} //date of import of product data
					updatedAt={(simulation as any)?.updatedAt} //date of the last modification of the simulation
					bdd={(simulation as any)?.product?.bddRefPortfolio || t('Non renseigné')}
				/>
			</div>
		</div>
	);

	return (
		<div>
			<SEO title={`${t('Éco-concevoir')} - CarbonMaps`} />
			<ScenarioHeader />
			{/* {isLoading ? renderValidScenario : isValidScenarioProductSource ? renderValidScenario : renderInvalidScenario} */}
			{renderValidScenario}
		</div>
	);
};

export default Scenario;
