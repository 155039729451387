import { useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import Box from '@mui/material/Box';
import _ from 'lodash';
import { ChevronDown, ChevronUp, Leaf } from 'lucide-react';
import { useLocation, useParams } from 'react-router-dom';

import { ReactComponent as AcidificationTerrestreEauDouceIcon } from '@carbonmaps/media/icons/other-indicator/AcidificationTerrestreEauDouce.svg';
import { ReactComponent as AppauvrissementCoucheOzoneIcon } from '@carbonmaps/media/icons/other-indicator/AppauvrissementCoucheOzone.svg';
import { ReactComponent as EcotoxiciteEauDouceIcon } from '@carbonmaps/media/icons/other-indicator/EcotoxiciteEauDouce.svg';
import { ReactComponent as EpuisementRessourcesEnergetiquesIcon } from '@carbonmaps/media/icons/other-indicator/EpuisementRessourcesEnergetiques.svg';
import { ReactComponent as EpuisementRessourcesMinerauxIcon } from '@carbonmaps/media/icons/other-indicator/EpuisementRessourcesMineraux.svg';
import { ReactComponent as EutrophisationEauDouceIcon } from '@carbonmaps/media/icons/other-indicator/EutrophisationEauDouce.svg';
import { ReactComponent as EutrophisationMarineIcon } from '@carbonmaps/media/icons/other-indicator/EutrophisationMarine.svg';
import { ReactComponent as EutrophisationTerrestreIcon } from '@carbonmaps/media/icons/other-indicator/EutrophisationTerrestre.svg';
import { ReactComponent as FormationPhotoChimiqueOzoneIcon } from '@carbonmaps/media/icons/other-indicator/FormationPhotoChimiqueOzone.svg';
import { ReactComponent as ParticulesFinesIcon } from '@carbonmaps/media/icons/other-indicator/ParticulesFine.svg';
import { ReactComponent as PEFBiodiversityNoteIcon } from '@carbonmaps/media/icons/other-indicator/PEF-biodiversity-note.svg';
import { ReactComponent as RayonnementsIonisantsIcon } from '@carbonmaps/media/icons/other-indicator/RayonnementsIonisants.svg';
import { ReactComponent as SubstanceCancerogeneIcon } from '@carbonmaps/media/icons/other-indicator/SubstanceCancerogene.svg';
import { ReactComponent as SubstanceNonCancerogeneIcon } from '@carbonmaps/media/icons/other-indicator/SubstanceNonCancerogene.svg';
import { ReactComponent as UtilisationSolIcon } from '@carbonmaps/media/icons/other-indicator/UtilisationSol.svg';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { useModelizationProduct } from '@carbonmaps/ui/hooks/useGetProductSheet';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import OtherIndicatorBloc from '../../../components/OtherIndicatorBloc';
import { useTranslation } from '../../../hooks/useTranslation';
import { cn, siteColors } from '../../../lib/colors';
import ProductModel from '../../../models/Product.model';

const toExponential = (num: number, digits = 2) => {
	return num.toExponential(digits);
};

export const stylesOtherIndicator = {
	hidden: css({
		display: 'none',
	}),
	otherStyle: css({
		display: 'flex',
		flexWrap: 'wrap',
		gap: 0,
		padding: '0!important',
		margin: 0,
		minWidth: '800px',
	}),
	blocBottom: css({
		padding: '24px',
		backgroundColor: siteColors.grey200,
		alignItems: 'center!important',
		borderRadius: '16px',

	}),
	divider: css({
		borderLeft: cn('1px solid', siteColors.grey500),
		// height: '75px',
		// width: '1px',
	}) as any,
	info: css({
		color: siteColors.grey800,
		fontSize: '12px',
		fontWeight: 400,
	}),
};

const OtherIndicatorsBlock = () => {
	const params = useParams();
	const location = useLocation();

	const { t } = useTranslation();

	const { data, isLoading } = useModelizationProduct({
		productId: params.productId!,
	});

	const toValueIndicatorByView = (value: any) => {
		if (value === 0) {
			return '-';
		}

		return toExponential(value);
	};

	const otherIndicatorsData = useMemo(() => {
		if (!data) {
			return [];
		}

		if (_.isString(data)) {
			return [];
		}

		const productObject = new ProductModel(data);
		return [
			{
				label: t('Particules fines'),
				measure: toValueIndicatorByView(productObject?.getIntensity('particulesTotal') || 0),
				unit: 'disease increase/kg',
				description: t('Particules fines - description'),
				icon: <ParticulesFinesIcon />,
			},
			{
				label: t('Epuisement des ressources énergétiques'),
				measure: toValueIndicatorByView(productObject?.getIntensity('fossilUseTotal') || 0),
				unit: 'MJ/kg',
				description: t('Épuisement des ressources énergétiques - description'),
				icon: <EpuisementRessourcesEnergetiquesIcon />,
			},
			{
				label: t('Utilisation du sol'),
				measure: toValueIndicatorByView(productObject?.getIntensity('landUseTotal') || 0),
				unit: 'Pt/kg',
				description: t('Utilisation du sol - description'),
				icon: <UtilisationSolIcon />,
			},
			{
				label: t('Epuisement des ressources minérales'),
				measure: toValueIndicatorByView(productObject?.getIntensity('mineralUseTotal') || 0),
				unit: 'kg Sb eq/kg',
				description: t('Épuisement des ressources minérales - description'),
				icon: <EpuisementRessourcesMinerauxIcon />,
			},
			{
				label: t("Appauvrissement de la couche d'ozone"),
				measure: toValueIndicatorByView(productObject?.getIntensity('ozone1Total') || 0),
				unit: 'kg CFC11 eq/kg',
				description: t("Appauvrissement de la couche d'ozone - description"),
				icon: <AppauvrissementCoucheOzoneIcon />,
			},
			{
				label: t('Acidification terrestre et eau douce'),
				measure: toValueIndicatorByView(productObject?.getIntensity('acidificationTotal') || 0),
				unit: 'mol H+ eq/kg',
				description: t('Acidification terrestre et eau douce - description'),
				icon: <AcidificationTerrestreEauDouceIcon />,
			},

			{
				label: t('Rayonnements ionisants'),
				measure: toValueIndicatorByView(productObject?.getIntensity('rayonnementsTotal') || 0),
				unit: 'kBq U235 eq/kg',
				description: t('Rayonnements ionisants - description'),
				icon: <RayonnementsIonisantsIcon />,
			},
			{
				label: t('Eutrophisation terrestre'),
				measure: toValueIndicatorByView(productObject?.getIntensity('eutrophisation3Total') || 0),
				unit: 'mol N eq/kg',
				description: t('Eutrophisation terrestre - description'),
				icon: <EutrophisationTerrestreIcon />,
			},
			{
				label: t('Eutrophisation eau douce'),
				measure: toValueIndicatorByView(productObject?.getIntensity('eutrophisation1Total') || 0),
				unit: 'kg P eq/kg',
				description: t('Eutrophisation eau douce - description'),
				icon: <EutrophisationEauDouceIcon />,
			},
			{
				label: t('Eutrophisation marine'),
				measure: toValueIndicatorByView(productObject?.getIntensity('eutrophisation2Total') || 0),
				unit: 'kg N eq/kg',
				description: t('Eutrophisation marine - description'),
				icon: <EutrophisationMarineIcon />,
			},
			{
				label: t("Formation photochimique d'ozone"),
				measure: toValueIndicatorByView(productObject?.getIntensity('ozone2Total') || 0),
				unit: 'kg NMVOC eq/kg',
				description: t("Formation photochimique d'ozone - description"),
				icon: <FormationPhotoChimiqueOzoneIcon />,
			},
			{
				label: t('Effets toxicologiques sur la santé humaine : substances cancérogènes'),
				measure: toValueIndicatorByView(productObject?.getIntensity('toxicite2Total') || 0),
				unit: 'CTUh/kg',
				description: t('Effets toxicologiques sur la santé humaine : substances cancérogènes - description'),
				icon: <SubstanceCancerogeneIcon />,
			},
			{
				label: t('Effets toxicologiques sur la santé humaine : substances non-cancérogènes'),
				measure: toValueIndicatorByView(productObject?.getIntensity('toxicite1Total') || 0),
				unit: 'CTUh/kg',
				description: t('Effets toxicologiques sur la santé humaine : substances non-cancérogènes - description'),
				icon: <SubstanceNonCancerogeneIcon />,
			},

			{
				label: t("Ecotoxicité pour écosystèmes aquatiques d'eau douce"),
				measure: toValueIndicatorByView(productObject?.getIntensity('ecotoxTotal') || 0),
				unit: 'CTUe/kg',
				description: t("Ecotoxicité pour écosystèmes aquatiques d'eau douce - description"),
				icon: <EcotoxiciteEauDouceIcon />,
			},

			{
				label: t('PEF-biodiversity-note'),
				measure: 0,
				unit: 'CTUe/kg',
				description: t('PEF-biodiversity-note - description'),
				icon: <PEFBiodiversityNoteIcon />,
				isDisabled: true,
			},
		];
	}, [data, t]);

	const [hidden, setHidden] = useState(true);

	return (
		<Box mb="40px" /* height="100%" */>
			{/*  eslint-disable-next-line quotes */}
			<BButton
				addStyles={{ width: '100%', marginBottom: '8px' }}
				variant="secondary"
				// eslint-disable-next-line quotes
				label={
					hidden ?
					 (
						<>
							{/* eslint-disable-next-line quotes */}
							{t("Voir plus d'indicateurs")}
							<ChevronDown size={18} />
						</>
					) :
					(
						<>
							{t('voir moins')}
							<ChevronUp size={18} />
						</>
					)
				}
				onClick={() => {
					return setHidden(!hidden);
				}}
			/>
			<Box mb={pxToRem(100)} className={cx(hidden ? stylesOtherIndicator.hidden : '')} >
				<OtherIndicatorBloc data={otherIndicatorsData} />
			</Box>
		</Box >
	);
};

export default OtherIndicatorsBlock;
