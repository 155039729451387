import { css } from '@emotion/css';
import { useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { ReactComponent as Carrots } from '@carbonmaps/media/icons/carrots.svg';
import { getIngredientCount } from '@carbonmaps/ui/actions/ingredient.actions';

import IconContainer from '../../components/layout/list/IconContainer';
import ListHeader from '../../components/layout/list/ListHeader';
import { useYearSelection } from '../../hooks/useImpactYear';
import { useTranslation } from '../../hooks/useTranslation';
import { cn, siteColors } from '../../lib/colors';
const styles = {
	container: css({
		// background: 'linear-gradient(90deg, #E4F3E3 0.53%, #E0F0F3 99.99%) !important'
		// background: cn(siteColors.green10, '!important'),
	}),
};

const IngredientHeader = () => {
	const theme = useTheme();
	const { t } = useTranslation();
	// ---- fetch count product ---- //
	// get count product for a company. this count will appear in header list
	// const { data: ingredientCount } = useQuery({
	// 	queryKey: ['getIngredientCount'],
	// 	queryFn: getIngredientCount,
	// });
	const { selectedYear } = useYearSelection();

	// ---- fetch data ---- //
	// use this count will appear in graph legend
	const { data: ingredientCount } = useQuery({
		queryKey: ['getIngredientCount', { period: selectedYear }],
		queryFn: getIngredientCount,
	});



	return (
		<ListHeader
			title={t('reference_ingredients', { ingredients: t('ingredients') })}
			subtitle={`${ingredientCount || ''} ${t('reference_ingredients', { ingredients: t('ingredients') })}`}
			icon={
				<IconContainer
					element={<Carrots color={siteColors.green500} width={32} height={32} />}
					color={siteColors.green500}
				/>
			}
			// className={styles.container}
		/>
	);
};

export default IngredientHeader;
