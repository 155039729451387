import { Dispatch, ReactNode, SetStateAction, useCallback, useState } from 'react';

import { css, cx } from '@emotion/css';
import type { AutocompleteChangeReason } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { ArrowLeft, FilterIcon, FolderIcon, X } from 'lucide-react';

import BButton from '@carbonmaps/ui/components/saas/BButton';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import { useSearchQueryParams } from '../hooks/useSearchQueryParams';
import { useTranslation } from '../hooks/useTranslation';
import { cn, siteColors } from '../lib/colors';

import Popper from './Popper';
import Skeleton from './Skeleton';
import MultiSelectCheckboxField, {
	type MultiSelectCheckboxFieldProps,
	type Option,
} from './form/fields/MultiSelectCheckboxField';

const styles = {
	container: css({
		borderRadius: '8px',
		border: cn('1px solid ', siteColors.grey500, ' !important'),
		background: '#FFF !important',
		height: '42px !important',
		'&:hover': {
			borderColor: cn(siteColors.grey500, '!important'),
			background: cn(siteColors.grey300, '!important'),
			cursor: 'pointer',
			'& .bucket-element': {
				color: cn(siteColors.primary, '#!important'),
			},
		},
	}),
	itemContainer: css({
		marginBottom: '8px !important',
		marginTop: '7px !important',
		background: 'none !important',
		padding: '8px 2px 8px 16px !important',
		gap: '4px !important',
		alignItems: 'center !important',
		'&:hover, &:focus': {
			background: 'none !important',
		},
	}),
	itemCount: css({
		color: siteColors.grey700,
		background: cn(siteColors.grey400, '!important'),
		padding: '0px 6px',
		fontWeight: '600 !important',
		fontSize: '11px !important',
		borderRadius: '4px',
	}),
};

type OnTriggerFilterParams = {
	allFoldersSelectedValues: Option[];
};

export type IFilterFolder = {
	label: string;
	labelNode?: ReactNode;
	options: Option[];
	showCount?: boolean;
	customIcon?: ReactNode;
} & (
	| {
			isFacet: true;
			path: string; // ? or index? // equiv of folderId below
			global?: boolean;
	  }
	| {
			isFacet: false;
			// folderId: string;
	  }
);

type Props = {
	skeleton?: boolean;
	category?: string;
	folders: IFilterFolder[];
	onTriggerFilter: (params: OnTriggerFilterParams) => void;
	isLoading: boolean;
	selectedValues: Option[];
	setSelectedValues: Dispatch<SetStateAction<Option[]>>;
	appliedValuesByFolders: IFilterFolder[];
	setAppliedValuesByFolders: Dispatch<SetStateAction<IFilterFolder[]>>;
	showCount?: boolean;
	className?: string;
	iconSize?: number;
	onClick?: () => void;
};

const Filter = ({
	folders,
	onTriggerFilter,
	isLoading,
	selectedValues,
	setSelectedValues,
	appliedValuesByFolders,
	setAppliedValuesByFolders,
	skeleton,
	className,
	iconSize,
	onClick,
	showCount = true,
}: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();
	const [openFolder, setOpenFolder] = useState<IFilterFolder | undefined>(undefined);
	const [searchQueryParams] = useSearchQueryParams();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const searchParams = searchQueryParams?.facetFilters ?? [];
	const supplierIdsParams = searchQueryParams?.supplierIds ?? [];
	const codeEmbCmapsListParams = searchQueryParams?.codeEmbCmapsList ?? [];

	const handleChangeFilter = useCallback(
		(_event: any, _value: Option[], reason: any, _option: { option: Option }) => {
			const { option } = _option;
			const _reason = reason as AutocompleteChangeReason;

			setSelectedValues((oldValues) => {
				const newValues: Option[] = [];

				if (_reason === 'removeOption') {
					for (const oldV of oldValues) {
						if (oldV.folderLabel === option.folderLabel && oldV.value === option.value && oldV.label === option.label) {
							continue;
						}

						newValues.push(oldV);
					}

					return newValues;
				}

				let isNew = true;

				for (const oldV of oldValues) {
					if (oldV.folderLabel === option.folderLabel && oldV.value === option.value && oldV.label === option.label) {
						isNew = false;
						continue;
						// break;
					}

					newValues.push(oldV);
				}

				if (isNew) {
					newValues.push(option);
				}

				return newValues;
			});
		},
		[setSelectedValues],
	);

	const onClosePopper = useCallback(() => {
		setOpenFolder(undefined);

		const selectedValuesByFolderMap = new Map<string, IFilterFolder>();

		selectedValues.forEach((s) => {
			folders.forEach((f) => {
				if (f.label !== s.folderLabel) {
					return;
				}

				const inMap = selectedValuesByFolderMap.get(f.label);

				if (!inMap) {
					selectedValuesByFolderMap.set(f.label, {
						...f,
						options: [s],
					});

					return;
				}

				inMap.options.push(s);
			});
		});

		setAppliedValuesByFolders([...selectedValuesByFolderMap.values()]);
		onTriggerFilter({ allFoldersSelectedValues: selectedValues });
	}, [folders, onTriggerFilter, selectedValues, setAppliedValuesByFolders]);
	return (
		<>
			{/* singles filters Poppers */}
			{appliedValuesByFolders.map((folder, index) => {
				const currentFolder = folders.find((f) => {
					return _.toLower(f.label) === _.toLower(folder.label);
				});

				return (
					folder.options?.length > 0 &&
					((searchParams && searchParams?.length > 0) ||
						(supplierIdsParams && supplierIdsParams?.length > 0) ||
						(codeEmbCmapsListParams && codeEmbCmapsListParams?.length > 0)) && (
						<div key={index} className={cx('flexRow nowrap alignCenter border', styles.container)}>
							<Popper
								onClose={onClosePopper}
								trigger={
									<div
										className={cx('flexRow nowrap', styles.itemContainer)}
										onClick={() => {
											setOpenFolder(currentFolder);
										}}
									>
										<Typography className="bucket-element fontWeight400">
											{t(folder.label, {
												products: t('products'),
												recipe: t('recipe'),
												recipe_lowercase: t('recipe'),
												ingredient: t('ingredient'),
												an_ingredient: t('an_ingredient'),
												ingredient_lowercase: toLowerCase(t('ingredient')),
												product: t('product'),
												of_product: t('of_product'),
												ingredients: t('ingredients'),
											})}
											&nbsp;
										</Typography>
										<Typography className={cx('bucket-element', styles.itemCount)}>{folder.options?.length}</Typography>
									</div>
								}
								renderContent={({ close }) => {
									return (
										<div>
											<FilterFolderMenu
												title={currentFolder?.label || ''}
												onReturnBack={onClosePopper}
												options={currentFolder?.options || []}
												currentFolderSelectedValues={selectedValues.filter((e) => {
													return _.toLower(e.folderLabel) === _.toLower(currentFolder?.label);
												})}
												allFoldersSelectedValues={selectedValues}
												setAllFoldersSelectedValues={setSelectedValues}
												onChangeFilter={handleChangeFilter}
												onTriggerFilter={onTriggerFilter}
												closeFilter={close}
												setAppliedValuesByFolders={setAppliedValuesByFolders}
												folders={folders}
												setOpenFolder={setOpenFolder}
												//	showCount={currentFolder?.showCount || false}
												showCount={showCount}
												onClick={onClick}
											/>
										</div>
									);
								}}
							/>

							{/* remove category filter */}
							<BButton
								onClick={() => {
									const newValues = selectedValues.filter((e) => {
										return e.folderLabel !== currentFolder?.label;
									});

									setSelectedValues(newValues);

									const selectedValuesByFolder: IFilterFolder[] = folders.map((e) => {
										return {
											...e,
											options: [],
										};
									});
									newValues.forEach((option, optionIndex) => {
										selectedValuesByFolder.forEach((folder, folderIndex) => {
											// newValues
											if (_.toLower(folder.label) === _.toLower(option.folderLabel)) {
												folder.options.push(option);
											}
										});
									});
									setAppliedValuesByFolders(selectedValuesByFolder);

									onTriggerFilter({
										allFoldersSelectedValues: newValues,
									});
								}}
								label={
									isLoading
? (
										<CircularProgress color="inherit" size={20} />
									)
: (
										<X color={theme.palette.grey[700]} height={20} />
									)
								}
								variant="tertiary"
								addStyles={{
									'&:hover': {
										background: 'none',
										opacity: 1,
									},
									textAlign: 'left',
									opacity: 0.5,
									background: 'none',
									// padding: '0px 16px 0px 16px',
									paddingInline: '5px',
									minWidth: 'unset',
									paddingTop: '6px',
									height: '42px',
								}}
							/>
						</div>
					)
				);
			})}

			{/* New Filter Popper */}
			<Popper
				onClose={onClosePopper}
				trigger={<FilterButton skeleton={skeleton} isLoading={isLoading} className={className} iconSize={iconSize} />}
				renderContent={({ close }) => {
					return (
						<div>
							{!openFolder
? (
								folders
									.filter((o: any) => {
										return o.options?.length;
									})
									.map((folder, index) => {
										return (
											<FilterFolder
												key={index}
												label={folder.labelNode ?? folder.label}
												onClick={() => {
													setOpenFolder(folder);
												}}
												customIcon={folder.customIcon}
											/>
										);
									})
							)
: (
								<>
									<FilterFolderMenu
										title={openFolder.label}
										onReturnBack={onClosePopper}
										options={openFolder.options}
										currentFolderSelectedValues={selectedValues.filter((e) => {
											return _.toLower(e.folderLabel) === _.toLower(openFolder.label);
										})}
										allFoldersSelectedValues={selectedValues}
										setAllFoldersSelectedValues={setSelectedValues}
										onChangeFilter={handleChangeFilter}
										onTriggerFilter={onTriggerFilter}
										closeFilter={close}
										setAppliedValuesByFolders={setAppliedValuesByFolders}
										folders={folders}
										setOpenFolder={setOpenFolder}
										showCount={openFolder.showCount ?? false}
										onClick={onClick}
									/>
								</>
							)}
						</div>
					);
				}}
			/>
		</>
	);
};

export default Filter;

type FilterButtonProps = {
	onClick?: () => void;
	isLoading: boolean;
	skeleton?: boolean;
	className?: string;
	iconSize?: number;
};

const stylesFilterButton = {
	container: css({
		cursor: 'pointer',
		borderRadius: '8px !important',
		background: cn(siteColors.grey400, '!important'),
		color: siteColors.text,
		padding: '9px 16px 8px 16px !important',
		height: '42px !important',
		'&:hover, &:focus': {
			background: cn(siteColors.grey500, '!important'),
		},
		gap: '8px !important',
	}),
};

const FilterButton = ({ onClick, isLoading, className, iconSize = 24, skeleton = false }: FilterButtonProps) => {
	const theme = useTheme();

	const { t } = useTranslation();

	const buttonElement = (
		<div onClick={onClick} className={cx('flexRow', stylesFilterButton.container, className)} id='btn-filter'>
			{isLoading ? <CircularProgress size={20} /> : <FilterIcon color={theme.palette.grey[700]} size={iconSize} />}
			<Typography fontSize={'14px'} fontWeight={600}>
				{t('filter-button')}
			</Typography>
		</div>
	);

	return skeleton ? <Skeleton>{buttonElement}</Skeleton> : buttonElement;
};

type FilterFolderProps = {
	onClick: () => void;
	label: ReactNode;
	customIcon?: ReactNode;
};

const filterFolderStyles = {
	container: css({
		width: '300px !important',
		gap: 12,
		fontSize: '14px !important',
		fontWeight: 400,
		cursor: 'pointer',
		padding: '8px 16px 8px 16px !important',
	}),
};

const FilterFolder = ({ onClick, label, customIcon }: FilterFolderProps) => {
	const theme = useTheme();

	const { t } = useTranslation();

	return (
		<MenuItem className={cx('flexRow alignCenter', filterFolderStyles.container)} onClick={onClick}>
			{customIcon ? customIcon : <FolderIcon color={theme.palette.grey[700]} />}{' '}
			<Typography color={theme.palette.grey[900]}>
				{t(label as any, {
					ingredient: t('ingredient'),
					an_ingredient: t('an_ingredient'),
					ingredient_lowercase: toLowerCase(t('ingredient')),
					product: t('product'),
					ingredients: t('ingredients'),
					of_product: t('of_product'),
					products: t('products'),
					recipe: t('recipe'),
					recipe_lowercase: t('recipe'),
				})}
			</Typography>
		</MenuItem>
	);
};

type FilterFolderMenuProps = {
	title: string;
	onReturnBack: () => void;
	options: Option[];
	currentFolderSelectedValues: Option[];
	allFoldersSelectedValues: Option[];
	setAllFoldersSelectedValues: (value: Option[]) => void;
	onChangeFilter: MultiSelectCheckboxFieldProps['handleChange'];
	onTriggerFilter: Props['onTriggerFilter'];
	closeFilter: () => void;
	setAppliedValuesByFolders: (value: IFilterFolder[]) => void;
	folders: IFilterFolder[];
	setOpenFolder: (v: IFilterFolder | undefined) => void;
	showCount: boolean;
	onClick?: () => void;
};

const filterFolderMenuStyles = {
	container: css({
		gap: '8px',

		cursor: 'pointer',
		padding: '16px 0px 16px 16px',
	}),
	title: css({
		fontSize: '16px !important',
	}),
	actionContainer: css({
		zIndex: 3,
		marginTop: '335px !important',
		justifyContent: 'space-between',
		paddingBottom: '8px !important',
		background: siteColors.common.white,
	}),
	button: css({
		marginLeft: '10px',
		textTransform: 'none',
	}),
};

const FilterFolderMenu = ({
	title,
	onReturnBack,
	options,
	currentFolderSelectedValues,
	setAllFoldersSelectedValues,
	onChangeFilter,
	onTriggerFilter,
	closeFilter,
	setAppliedValuesByFolders,
	showCount,
	onClick,
}: FilterFolderMenuProps) => {
	const theme = useTheme();

	const { t } = useTranslation();

	return (
		<ClickAwayListener onClickAway={onReturnBack}>
			<>
				<div
					className={cx('flexRow alignCenter bgGrey300', filterFolderMenuStyles.container)}
					onClick={() => {
						onReturnBack();
					}}
				>
					<ArrowLeft color={theme.palette.grey[700]} />
					<Typography className={cx('grey900 fontWeight600', filterFolderMenuStyles.title)}>
						{t(title, {
							ingredient: t('ingredient'),
							an_ingredient: t('an_ingredient'),
							ingredient_lowercase: toLowerCase(t('ingredient')),
							product: t('product'),
							of_product: t('of_product'),
							ingredients: t('ingredients'),
							products: t('products'),
							recipe: t('recipe'),
							recipe_lowercase: t('recipe'),
						})}
					</Typography>
				</div>

				<MultiSelectCheckboxField
					name={'WTF'}
					selectedValues={currentFolderSelectedValues}
					showOptionListDefault={true}
					handleFilterClick={() => {
						// do nothing
					}}
					handleChange={onChangeFilter}
					options={options}
					showCount={showCount}
				/>

				<div className={cx('flexRow', filterFolderMenuStyles.actionContainer)}>
					<BButton
						onClick={() => {
							closeFilter && closeFilter();
							setAllFoldersSelectedValues([]);
							setAppliedValuesByFolders([]);
							onTriggerFilter({ allFoldersSelectedValues: [] });
						}}
						addStyles={{ borderRadius: '8px', padding: '8px 12px', marginLeft: '5px' }}
						label={t('remove-all-button')}
						variant="tertiary"
					/>

					<BButton
						onClick={() => {
							closeFilter && closeFilter();
							onReturnBack();
							onClick && onClick();
						}}
						addStyles={{ borderRadius: '8px', padding: ' 8px 12px', marginRight: '5px' }}
						label={t('filter-button')}
						variant="primary"
					/>
				</div>
			</>
		</ClickAwayListener>
	);
};
