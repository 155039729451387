import { useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { ChevronDown, ChevronUp } from 'lucide-react';

import BButton from '@carbonmaps/ui/components/saas/BButton';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import Card from '../../../../components/Card';
import SoldItemsPopover from '../../../../components/table/popover/SoldItemsPopover';
import { useTranslation } from '../../../../hooks/useTranslation';
import { CARBONE_IMPACT_VIEW, INTENSITY_VIEW } from '../../../../utils/constants';
import OtherIndicatorsTable from '../../../productSheet/synthesis/OtherIndicatorsTable';
import { useOtherIndicator } from '../../analyse.hooks';

const toExponential = (num: number, digits = 2) => {
	return num.toExponential(digits);
};

const styles = {
	hidden: css({
		display: 'none',
	}),
};

const OtherIndicatorCard = ({ viewMode }: { viewMode?: string }) => {
	const theme = useTheme();

	// translation
	const { t } = useTranslation();

	// fetch data

	const { data, isLoading } = useOtherIndicator({}) as any;

	const otherIndicatorsColumns = useMemo(() => {
		return [
			{
				accessor: 'label',
				Header: (props: any) => {
					return (
						<Box padding="16px" bgcolor={theme.palette.grey[400]}>
							<Typography fontSize="14px" color={theme.palette.grey[900]} sx={{ opacity: 0 }}>
								invisible
							</Typography>
						</Box>
					);
				},
				Cell: (props: any) => {
					return (
						<Box padding="16px">
							<Typography fontSize="14px" color={theme.palette.grey[900]}>
								{props.value}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessor: 'measure',
				Header: (props: any) => {
					return (
						<Box padding="16px" bgcolor={theme.palette.grey[400]}>
							<Typography fontSize="14px" color={theme.palette.grey[900]} textAlign="right">
								{t('product-other-indicator-mesure')}
							</Typography>
						</Box>
					);
				},
				Cell: (props: any) => {
					return (
						<Box padding="16px">
							<Typography fontSize="14px" color={theme.palette.grey[900]} textAlign="right">
								{typeof props.value === 'string' ? props.value.replace('.', ',') : props.value}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessor: 'unit',
				Header: (props: any) => {
					return (
						<Box padding="16px" bgcolor={theme.palette.grey[400]}>
							<Typography fontSize="14px" color={theme.palette.grey[900]} textAlign="left">
								{t('product-other-indicator-unit')}
							</Typography>
						</Box>
					);
				},
				Cell: (props: any) => {
					return (
						<Box padding="16px">
							<Typography fontSize="14px" color={theme.palette.grey[900]} textAlign="left">
								{props.value}
							</Typography>
						</Box>
					);
				},
			},
		];
	}, [theme.palette.grey, t]);

	const toValueIndicatorByView = (value: any) => {
		// add popover when empty soldItems or 0
		if (data?.volume === 0 && viewMode === CARBONE_IMPACT_VIEW) {
			return <SoldItemsPopover />;
		}

		if (data?.tonnage <= 0 && viewMode === CARBONE_IMPACT_VIEW) {
			return <SoldItemsPopover value={-1} />;
		}

		if (value === 0 && viewMode === INTENSITY_VIEW) {
			return '-';
		}

		return toExponential((viewMode === CARBONE_IMPACT_VIEW ? value * data?.tonnage : value) || 0);
	};

	const otherIndicatorsData = useMemo(() => {
		/* eslint-disable quotes */
		return [
			{
				label: t("Appauvrissement de la couche d'ozone"),
				measure: toValueIndicatorByView(data?.ozone1Total),
				unit: viewMode === CARBONE_IMPACT_VIEW ? 'kg CFC11 eq' : 'kg CFC11 eq/kg',
			},
			{
				label: t('Rayonnements ionisants'),
				measure: toValueIndicatorByView(data?.rayonnementsTotal),
				unit: viewMode === CARBONE_IMPACT_VIEW ? 'kBq U235 eq' : 'kBq U235 eq/kg',
			},
			{
				label: t("Formation photochimique d'ozone"),
				measure: toValueIndicatorByView(data?.ozone2Total),
				unit: viewMode === CARBONE_IMPACT_VIEW ? 'kg NMVOC eq' : 'kg NMVOC eq/kg',
			},
			{
				label: t('Particules fines'),
				measure: toValueIndicatorByView(data?.particulesTotal || 0),
				unit: viewMode === CARBONE_IMPACT_VIEW ? 'disease increase' : 'disease increase/kg',
			},
			{
				label: t('Effets toxicologiques sur la santé humaine : substances non-cancérogènes'),
				measure: toValueIndicatorByView(data?.toxicite1Total || 0),
				unit: viewMode === CARBONE_IMPACT_VIEW ? 'CTUh' : 'CTUh/kg',
			},
			{
				label: t('Effets toxicologiques sur la santé humaine : substances cancérogènes'),
				measure: toValueIndicatorByView(data?.toxicite2Total || 0),
				unit: viewMode === CARBONE_IMPACT_VIEW ? 'CTUh' : 'CTUh/kg',
			},
			{
				label: t('Acidification terrestre et eau douce'),
				measure: toValueIndicatorByView(data?.acidificationTotal || 0),
				unit: viewMode === CARBONE_IMPACT_VIEW ? 'mol H+ eq' : 'mol H+ eq/kg',
			},
			{
				label: t('Eutrophisation eau douce'),
				measure: toValueIndicatorByView(data?.eutrophisation1Total || 0),
				unit: viewMode === CARBONE_IMPACT_VIEW ? 'kg P eq' : 'kg P eq/kg',
			},
			{
				label: t('Eutrophisation marine'),
				measure: toValueIndicatorByView(data?.eutrophisation2Total || 0),
				unit: viewMode === CARBONE_IMPACT_VIEW ? 'kg N eq' : 'kg N eq/kg',
			},
			{
				label: t('Eutrophisation terrestre'),
				measure: toValueIndicatorByView(data?.eutrophisation3Total || 0),
				unit: viewMode === CARBONE_IMPACT_VIEW ? 'mol N eq' : 'mol N eq/kg',
			},
			{
				label: t("Ecotoxicité pour écosystèmes aquatiques d'eau douce"),
				measure: toValueIndicatorByView(data?.ecotoxTotal || 0),
				unit: viewMode === CARBONE_IMPACT_VIEW ? 'CTUe' : 'CTUe/kg',
			},
			{ label: t('Utilisation du sol'), measure: toValueIndicatorByView(data?.landUseTotal || 0), unit: 'Pt/kg' },
			{
				label: t('Epuisement des ressources énergétiques'),
				measure: toValueIndicatorByView(data?.fossilUseTotal || 0),
				unit: viewMode === CARBONE_IMPACT_VIEW ? 'MJ' : 'MJ/kg',
			},
			{
				label: t('Epuisement des ressources minérales'),
				measure: toValueIndicatorByView(data?.mineralUseTotal || 0),
				unit: viewMode === CARBONE_IMPACT_VIEW ? 'kg Sb eq' : 'kg Sb eq/kg',
			},
		];
		/* eslint-enable quotes */
	}, [data, viewMode, t]);

	const [hidden, setHidden] = useState(true);

	return (
		// <Card title={t('product-other-indicator')}>
		<>
			<BButton
				addStyles={{ width: '100%', marginBottom: '8px' }}
				variant="secondary"
				// eslint-disable-next-line quotes
				label={
					hidden ?
					(
						<>
							{/* eslint-disable-next-line quotes */}
							{t("Voir plus d'indicateurs")}
							<ChevronDown size={18} />
						</>
					) :
					(
						<>
							{t('voir moins')}
							<ChevronUp size={18} />
						</>
					)
				}
				onClick={() => {
					return setHidden(!hidden);
				}}
			/>
			<div css={{ marginBottom: 100 }}>
				<div className={cx(hidden ? styles.hidden : '')}>
					<OtherIndicatorsTable columns={otherIndicatorsColumns} data={otherIndicatorsData} />
				</div>
			</div>
		</>
		// </Card>
	);
};

export default OtherIndicatorCard;
