import { css } from '@emotion/css';
import { Typography } from '@mui/material';

import Dialog from '../../../components/dialogs/Dialog';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';

import QuoteForm from './QuoteForm';

const styles = {
	title: css({
		fontSize: '24px !important',
		fontWeight: '600 !important',
		margin: 0,
		color: siteColors.primary,
	}),
	subTitle: css({
		marginTop: '0px',
		fontSize: '14px !important',
		fontWeight: '400 !important',
		width: '100% !important',
		color: `${siteColors.grey800} !important`,
	}),
	container: css({
		maxWidth: 528,
		margin: 'auto',
	}),
};

type Props = {
	openModal: boolean;
	setOpenModal: (v: boolean) => void;
};

const ExportDialog = ({ openModal, setOpenModal }: Props) => {
	const { t } = useTranslation();

	return (
		<Dialog
			open={openModal}
			isDrawer
			toggle={() => {
				setOpenModal(false);
			}}
			withCancelButton={false}
			title={(<div>
				<Typography className={styles.title}>{t('quote-detail-title')}</Typography>
			</div>) as never}
		>
			<div className={styles.container}>
				<QuoteForm
					onCloseDialog={() => {
						setOpenModal(false);
					}}
				/>
			</div>
		</Dialog>
	);
};

export default ExportDialog;
