import { css, cx } from '@emotion/css';
import { emphasize } from '@mui/material/styles';
import { ArrowDownRight, ArrowUpRight, BarChart2, Cloud, Edit2, Equal, FileEdit } from 'lucide-react';

import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { siteColors } from '../../lib/colors';

const negativeColor = '#009F36';
const positiveColor = '#C70000';

const styles = {
	box: css({
		padding: '4px',
	}),
	span: css({
		display: 'inline-block',
		marginRight: '3px',
	}),
	negative: css({
		color: negativeColor,
		background: emphasize(negativeColor, 0.9),
	}),
	positive: css({
		color: positiveColor,
		background: emphasize(positiveColor, 0.9),
	}),
	equals: css({
		// background: siteColors.grey200,
		background: 'none',
		color: siteColors.grey700,
	}),
};

type DifferenceProps = {
	value?: number;
	decimal?: number;
	diffMainClass?: string;
	className?: string;
	withEquals?: boolean;
	showZero?: boolean; // for affecting 0 value
	withPercent?: boolean;
	showLte?: boolean;
};

const Difference = ({
	value = 30,
	decimal = 2,
	diffMainClass = '',
	className,
	withEquals = true,
	showZero = false,
	withPercent = true,
	showLte = false,
}: DifferenceProps) => {
	const isNegative = value <= 0;
	const isEqual = formatNumber(Math.abs(value), '', decimal) === '0.00' || value === 0;

	return (
		<div
			className={cx(
				styles.box,
				diffMainClass,
				isEqual ? styles.equals : isNegative ? styles.negative : styles.positive,
				'flexRow alignCenter justifyEnd',
				className,
			)}
		>
			{isEqual ?
			(
				<>
					{/* {withEquals && <Equal />} */}
					<Equal style={{ visibility: withEquals ? 'visible' : 'hidden' }} size={22} />
				</>
			) :
			(
				<>
					<span className={cx(styles.span, 'number')}>
						{value < 0.9 && value >= -0.9
? (
							showZero ?
								(showLte && Math.abs(value) > -0.01 && Math.abs(value) < 0.01 && value !== 0
? '< 0,01' :
									(
										`${isNegative ? '-' : '+'} ${formatNumber(Math.abs(value), '', decimal)}`
									))
: (
									0
								)
						)
: (
							<>
								{' '}
								{isNegative ? '-' : '+'} {formatNumber(Math.abs(value), '', decimal)}{' '}
							</>
						)}{' '}
						{withPercent ? '%' : null}
					</span>
					{value < 0
? (
						<ArrowDownRight color={negativeColor} width={20} height={20} />
					)
: (
						<ArrowUpRight color={positiveColor} width={20} height={20} />
					)}
				</>
			)}
		</div>
	);
};

export default Difference;
