import { useCallback, useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { CircularProgress } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { type TFunction } from 'i18next';
import _ from 'lodash';
import { Minus, Plus } from 'lucide-react';
import { nanoid } from 'nanoid';
import { useParams } from 'react-router-dom';

import BButton from '@carbonmaps/ui/components/saas/BButton';
import { displayValue } from '@carbonmaps/ui/utils/numberFormat';

import ImpactCell from '../../../../../../components/_comp/ImpactCell';
import IntensityCell from '../../../../../../components/_comp/IntensityCell';
import { SupplierIcon } from '../../../../../../components/_comp/ItemIcon';
import Table from '../../../../../../components/_comp/Table';
import TableHeadCell from '../../../../../../components/_comp/TableHeadCell';
import LabelLogo from '../../../../../../components/question/LabelLogo';
import { useYearSelection } from '../../../../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../../../lib/colors';
import { useFindSuppliersTable } from '../../../../../../lib/react-query/features/supplier/supplier.hooks';
import SuppliersFilter from '../../../../../supplier/general/tabs/impacts/SuppliersFilter';
import { fetchPreCampaignSuppliersContact, fetchSuppliersContacts, RemoveSupplierById, useQuestionFormStore } from '../../../../questionForms.hooks';

import { SupplierContactRowData } from './QuestionFormSuppliersTable';

type SupplierRowData = {
	id: string;
	name: string;
	uid: string;

	intensity?: number;
	intensityPercent?: number;
	impact?: number;
	impactPercent?: number;

	volume?: number;

	isAdded?: boolean;
};

const tableStyles = {
	main: css({
		width: '100%',
		borderCollapse: 'collapse',
		'& tr': {
			height: '60px',
			alignSelf: 'stretch',
		},
		'& tbody tr': {
			borderTop: cn('1px solid', siteColors.grey500),
		},
		'.labelLogoText': {
			maxWidth: '200px',
		},
		'& tr > th:nth-of-type(1), & tr > td:nth-of-type(1)': {
			width: 300,
		},
		'& tr > th:not(:nth-of-type(1)) .alignRight': {
			display: 'flex!important',
			justifyContent: 'flex-end',
		},
	}),
	loading: css({
		opacity: 0.5,
		pointerEvents: 'none',
	}),

	text1: css({
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '24px',
		fontStyle: 'normal',
		fontWeight: 600,
		lineHeight: 'normal',
	}),
	hiddenVisibility: css({
		visibility: 'hidden',
	}),
};

const columnHelper = createColumnHelper<SupplierRowData>();

type UpdateSupplierDataById = (supplierUid: string, data: Pick<SupplierRowData, 'isAdded'>) => void;
export type AddSupplierWithContacts = (value: SupplierContactRowData) => void;
export type RemoveSupplierContactById = (options: { supplierId: string; contactId: string }) => void;

type GetColumnsProps = {
	updateSupplierDataById: UpdateSupplierDataById;
	addSupplierWithContacts: AddSupplierWithContacts;
	removeSupplierById: RemoveSupplierById;
	fetchSuppliersContacts: (...args: any[]) => Promise<Record<string, any>[] | undefined>;
	t: TFunction;
	period: number;
	questionFormId: string;
};

const toContactRowData = (input: SupplierRowData) => {
	return {
		id: input.id,
		supplierName: input.name,
		supplierUid: input.uid,
	};
};

const getColumns = ({
	updateSupplierDataById,
	addSupplierWithContacts,
	removeSupplierById,
	fetchSuppliersContacts,
	questionFormId,
	t,
}: GetColumnsProps) => {
	return [
		columnHelper.accessor('name', {
			header: (info) => {
				return <TableHeadCell info={info} label={t('supplier-label')} valueType="string" />;
			},
			cell: (info) => {
				return <LabelLogo label={info.row.original.name} uid={info.row.original.uid} icon={<SupplierIcon />} />;
			},
		}),
		columnHelper.accessor('intensity', {
			header: (info) => {
				return <TableHeadCell info={info} label={t('Par kilo')} valueType="number" />;
			},
			cell: (info) => {
				return <IntensityCell value={info.getValue()} percentage={info.row.original.intensityPercent} />;
			},
		}),
		columnHelper.accessor('impact', {
			header: (info) => {
				return (
					<TableHeadCell
						info={info}
						label={t('Toutes les années')}
						valueType="number"
						variant="measure"
						measure="carbonImpact"
					/>
				);
			},
			cell: (info) => {
				return (
					<div className={cx('flexRow justifyEnd')}>
						<ImpactCell<SupplierRowData> info={info} value={_.toNumber(info.getValue())} />
					</div>
				);
			},
		}),
		columnHelper.accessor('volume', {
			header: (info) => {
				return <TableHeadCell info={info} label={t('volume')} valueType="number" variant="measure" measure="massTon" />;
			},
			cell: (info) => {
				return (
					<div className={cx('flexRow justifyEnd')}>
						<div>{displayValue(info.getValue())}</div>
					</div>
				);
			},
		}),
		columnHelper.accessor('isAdded', {
			header: (info) => {
				return <TableHeadCell info={info} label={t('ajouté')} valueType="string" />;
			},
			cell: (info) => {
				return (
					<div className={cx('flexRow justifyEnd')}>
						{info.row.original.isAdded
							? (
								<BButton
									variant="primary"
									iconLeft={<Minus />}
									label={t('Retirer')}
									onClick={() => {
										updateSupplierDataById(info.row.original.id, { isAdded: false });
										removeSupplierById({ supplierId: info.row.original.id });
									}}
								/>
							)
							: (
								<BButton
									variant="secondary"
									iconLeft={<Plus />}
									label={t('Ajouter')}
									onClick={async () => {
										updateSupplierDataById(info.row.original.id, { isAdded: true });
										const uid = info.row.original.uid;
										// const result = await fetchSuppliersContacts({ supplierUids: [uid] });
										const result = await fetchPreCampaignSuppliersContact({ supplierUid: uid, questionFormId });
										const supplierData = toContactRowData(info.row.original);

										let subRows: any[] = [
											{
												...supplierData,
												id: nanoid(),
												contactEmail: '',
												contactFirstName: '',
												contactLastName: '',
												contactLanguage: '',
												isEdited: true,
											},
										];

										if (!_.isEmpty(result)) {
											subRows = _.get(result, `${uid}`, []).map((e: Record<string, any>) => {
												return {
													...supplierData,
													id: _.get(e, 'objectId'),
													contactEmail: _.get(e, 'email'),
													contactFirstName: _.get(e, 'firstName'),
													contactLastName: _.get(e, 'lastName'),
													contactLanguage: _.get(e, 'emailLanguage'),
												};
											});
										}

										addSupplierWithContacts({
											...supplierData,
											subRows,
										});
									}}
								/>
							)}
					</div>
				);
			},
		}),
	];
};

const QuestionFormSuppliersTableSelect = () => {
	const [data, setData] = useState<SupplierRowData[]>([]);

	const params = useParams();
	const updateSupplierDataById: UpdateSupplierDataById = useCallback(
		(supplierId, newData) => {
			const index = data.findIndex((iData) => {
				return iData.id === supplierId;
			});

			if (index !== -1) {
				data[index] = {
					...data[index],
					...newData,
				};
			}

			setData([...data]);
			return;
		},
		[data],
	);

	const { addSupplierWithContacts, /* removeSupplierContactById, */ removeSupplierById, supplierContacts } =
		useQuestionFormStore();

	const { t } = useTranslation();
	const { selectedYear } = useYearSelection();

	const columns = useMemo(() => {
		return getColumns({
			updateSupplierDataById,
			addSupplierWithContacts,
			removeSupplierById,
			fetchSuppliersContacts,
			t: t as never,
			period: selectedYear,
			questionFormId: params.questionFormId ?? '',
		});
	}, [addSupplierWithContacts, removeSupplierById, updateSupplierDataById, t]);

	// init filters values
	const [searchQueryParams] = useSearchQueryParams();

	const {
		result: { data: findSuppliersData, isLoading: isTableLoading },
	} = useFindSuppliersTable({
		paramsTable: {
			input: searchQueryParams?.input || '',
			facetFilters: searchQueryParams?.facetFilters || [],
			selectedYear: -1, // get all supplier for all year
			isCampaign: true,
			questionFormId: _.toString(params.questionFormId),
		},
	});

	useEffect(() => {
		if (!findSuppliersData) {
			return;
		}

		const maxIntensity = _.maxBy(findSuppliersData.suppliers as any[], 'carbonIntensity')?.carbonIntensity;

		const newData: SupplierRowData[] = _.get(findSuppliersData, 'suppliers', []).map((item: any) => {
			const isAdded = supplierContacts.some((contact) => {
				return contact.id === item.objectId;
			});

			return {
				id: item.objectId,
				name: item.name,
				uid: item.uid,

				intensity: item.carbonIntensity,
				intensityPercent: ((item.carbonIntensity || 0) * 100) / maxIntensity,

				impact: item.carbonImpact,
				impactPercent: item.carbonImpactPercent,

				volume: item.volume ?? item.tonnage,

				isAdded,
			};
		});

		setData(newData);
	}, [findSuppliersData, supplierContacts]);

	return (
		<div>
			<div className={cx('', tableStyles.text1)}>{t('Ajouter des fournisseurs')}</div>
			<div
				className={cx('flexRow alignCenter')}
				css={{
					'& .filterContainer-wrapper': {
						paddingLeft: 0,
						paddingRight: 0,
					},
				}}
			>
				<SuppliersFilter withMore={false} />
			</div>

			<div css={{ position: 'relative' }}>
				{isTableLoading
					? (
						<CircularProgress
							sx={{
								position: 'absolute',
								top: 100,
								left: '0',
								right: '0',
								marginLeft: 'auto',
								marginRight: 'auto',
								width: '100px',
							}}
						/>
					)
					: null}
				<Table<SupplierRowData>
					data={data}
					columns={columns}
					tableProps={{ className: cx('', tableStyles.main, isTableLoading ? tableStyles.loading : '') }}
				/>
				{!isTableLoading && _.isEmpty(findSuppliersData)
? (
					<div className={cx('flexRow justifyCenter')}>
						<div>{t('empty-suppliers')}</div>
					</div>
				)
: null}
			</div>
		</div>
	);
};

export default QuestionFormSuppliersTableSelect;
