import { useState } from 'react';

import { cx } from '@emotion/css';
import AddIcon from '@mui/icons-material/Add';
import { IconButton, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Edit, Info } from 'lucide-react';

import { functionName } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';

import Popover from '../../../../../../../components/Popover';
import Skeleton from '../../../../../../../components/Skeleton';
import { useTranslation } from '../../../../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../../../../lib/colors';
import StatusChip from '../StatusChip';

import SuperModal from './modal/SuperModal';

const ContactsList = ({ objectIds, supplier }: { objectIds: string[]; supplier: Record<string, any> }) => {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);

	const supplierName = _.get(supplier, 'name');

	const queryKey = ['contacts', { objectIds }] as const;
	const { data, isLoading } = useQuery({
		queryKey,
		queryFn: async (ctx) => {
			try {
				const { objectIds } = ctx.queryKey[1];
				return Parse.Cloud.run(functionName.questions.findContactsListForSupplierTracking, { objectIds });
			} catch (error) {
				console.log('--- fetchContacts action error ----', error);
				return Promise.reject(error);
			}
		},
		enabled: _.isArray(objectIds) && !_.isEmpty(objectIds),
	});

	const renderModal = <SuperModal open={isOpen} setOpen={setIsOpen} supplier={supplier} data={data} />;

	if (isLoading) {
		return (
			<>
				<Skeleton height={40} />
				{renderModal}
			</>
		);
	}

	return (
		<>
			<div className="flexRow" css={{ gap: 12 }}>
				{objectIds.length === 1 && _.get(data[0], 'statusType') === 'no_contact'
					? (
						<BButton
							onClick={() => {
								setIsOpen(true);
							}}
							iconLeft={<AddIcon />}
							variant="tertiary"
							label={
								<Typography color={siteColors.grey9} fontWeight={400} fontSize={14}>
									{t('Ajouter un contact')}
								</Typography>
							}
							addStyles={{
								flexGrow: 1,
								border: cn('1px solid', siteColors.grey400),
								boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
								borderRadius: '4px',
								justifyContent: 'left',
								['& > .contact-item' as never]: {
									padding: '6px 6px 6px 12px',
									borderBottom: cn('1px solid', siteColors.grey400),
									'&:last-child': {
										borderBottom: 'none',
									},
								},
							}}
						/>
					)
					: (
						<div
							css={{
								flexGrow: 1,
								border: cn('1px solid', siteColors.grey400),
								boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
								borderRadius: '4px',
								['& > .contact-item' as never]: {
									padding: '6px 6px 6px 12px',
									borderBottom: cn('1px solid', siteColors.grey400),
									'&:last-child': {
										borderBottom: 'none',
									},
								},
							}}
						>
							{_.map(data, (item, index) => {
								return (
									<div className={cx('contact-item flexRow spaceBetween alignCenter')} key={index}>
										<div>{_.get(item, 'contact.email')}</div>

										<div className={cx('flexRow alignCenter grey700')} css={{ gap: 16 }}>
											{_.get(item, 'isError')
												? (
													<div css={{ position: 'relative', top: 4 }}>
														<Info color={siteColors.orange500} size={20} />
													</div>
												)
												: null}
											<div>{dayjs(_.get(item, 'actionDate')).format('DD/MM/YYYY')}</div>
											<div>
												<StatusChip statusType={_.get(item, 'statusType')} />
											</div>
										</div>
									</div>
								);
							})}
						</div>
					)}
				<Popover
					css={{ marginRight: 34 }}
					anchorOrigin={{
						horizontal: 'left',
						vertical: 'top',
					}}
					transformOrigin={{
						horizontal: 'right',
						vertical: 'top',
					}}
					trigger={
						<IconButton disableRipple>
							<Edit size={20} />
						</IconButton>
					}
					renderContent={({ close }) => {
						return (
							<div
								css={{
									padding: '8px',
								}}
							>
								<div css={{ color: siteColors.text, fontSize: 16, fontWeight: 600, paddingLeft: 10 }}>
									{supplierName}
								</div>
								<BButton
									variant="tertiary"
									iconLeft={<Edit size={20} />}
									disableRipple
									label="Modifier les contacts du fournisseur"
									onClick={() => {
										close();
										setIsOpen(true);
									}}
								/>
							</div>
						);
					}}
				/>
			</div>

			{renderModal}
		</>
	);
};

export default ContactsList;
