
import { css, cx } from '@emotion/css';
import { useMutation } from '@tanstack/react-query';
import { Key } from 'lucide-react';


import { ReactComponent as GoogleIcon } from '@carbonmaps/media/icons/google.svg';
import BButton from '@carbonmaps/ui/components/saas/BButton';

import Typography from '../../../components/Typography';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { loginSSOAction } from '../../../lib/react-query/features/auth/auth.actions';
import { SSO_PROVIDER } from '../../../utils/constants';

const styles = {
	line: css({
	flex:1,
	background: siteColors.grey5,
	height:1,
}),
button: css({
	background: `${siteColors.grey2} !important`,
	color:  `${siteColors.text} !important`,
	':hover': {
		background: `${siteColors.grey3} !important`,
	},
}),
};

const SSOForm = () => {
	const { t }= useTranslation();

	// --------------------------------------------------- //
	// -------------- handle login SSO ------------------- //
	// --------------------------------------------------- //
	const { mutate: handleLoginSSO, isLoading } = useMutation({
			mutationKey: ['loginSSO'],
			mutationFn: loginSSOAction,
			onSuccess: async (res) => {
				window.location.href = res.url;
			},
	});

	return (
		<div className=" flexColumn width100 gap24">
			<div className="flexRow width100 alignCenter gap4">
				<div className={styles.line} />
				<Typography>{t('Ou se connecter avec')}</Typography>
				<div className={styles.line} />
			</div>
			<div className="flexRow width100 gap12">
				<BButton
						variant="tertiary"
						label={t('login_google')}
						onClick={() =>{return handleLoginSSO(SSO_PROVIDER.GOOGLE);}}
						className={cx(styles.button,'flex1' )}
						iconLeft={<GoogleIcon />}
					/>
					<BButton
						variant="tertiary"
						label={t('login_sso')}
						onClick={() =>{return handleLoginSSO(SSO_PROVIDER.OIDC);}}
						className={cx(styles.button,'flex1' )}
						iconLeft={<Key size={20} color={siteColors.grey700} />}

					/>
			</div>
		</div>
	);

};

export default SSOForm;
