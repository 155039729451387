import { useState } from 'react';

import { css, cx } from '@emotion/css';
import { Paper, Typography } from '@mui/material';
import { Widget } from '@typeform/embed-react';
import { ChevronDown } from 'lucide-react';

import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import SelectField from '../form/fields/SelectField';

type QuestionPreviewProps = {
	title?: string;
	translation: {
		en: {
			typeFormId: string;
			name: string;
			url: string;
		};
		fr: {
			typeFormId: string;
			name: string;
			url: string;
		};
		it: {
			typeFormId: string;
			name: string;
			url: string;
		};
		es: {
			typeFormId: string;
			name: string;
			url: string;
		};
		de: {
			typeFormId: string;
			name: string;
			url: string;
		};
	};
	lang?: string;
};

const styles = {
	container: css({
		minWidth: '1100px',
	}),
	label: css({
		fontWeight: 600,
		fontSize: 24,
		color: siteColors.grey900,
	}),
	selectedContainer: css({
		width: '100%',
		background: '#fff !important',
		color: '#9587AE !important',
		border: '1px solid rgb(228, 204, 247)',

		'.MuiInputBase-input': {
			// paddingLeft: '0px !important',
			// paddingTop: '0px !important',
			paddingBottom: '0px !important',
			minHeight: '32px !important',
			width: '100%',
		},
		svg: {
			width: '20px',
			height: '20px',
			color: '#9587AE !important',
		},
	}),
	typeform: css({
		width: '100%',
		height: '400px',
	}),
};

const QuestionPreview = ({ title, translation }: QuestionPreviewProps) => {
	const [lang, setLang] = useState(() => {
		return (
			Object.entries(translation).find((e) => {
				const id = e[1].typeFormId;
				return id;
			})?.[0] || 'fr'
		);
	});

	const { t } = useTranslation();

	const _translation = {
		en: {
			label: t('Anglais'),
		},
		fr: {
			label: t('Français'),
		},
		it: {
			label: t('Italien'),
		},
		es: {
			label: t('Espagnol'),
		},
		de: {
			label: t('Allemand'),
		},
	};

	const options = Object.entries(translation)
		.filter((e) => {
			const id = e[1].typeFormId;
			return id;
		})
		.map(([key, v]) => {
			return {
				value: key,
				label: t((_translation as any)[key].label),
			};
		});

	return (
		<div className={cx('flexColumn gap24', styles.container)}>
			<div className={cx('fontWeight600 fontSize24', styles.label)}>{title}</div>
			<div className="width100">
				<SelectField
					items={options}
					value={lang}
					onChange={(v: any) => {
						setLang(v);
					}}
					className={styles.selectedContainer}
					renderValue={(item: any) => {
						return <Typography>{item?.label}</Typography>;
					}}
					ExpandMoreRoundedIcon={ChevronDown}
					MenuProps={{
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'left',
						},
						transformOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
					}}
				/>
			</div>
			<div className={cx(styles.typeform)}>
				<Widget id={(translation as any)?.[lang]?.typeFormId} style={{ width: '100%', height: '400px' }} />
			</div>
		</div>
	);
};

export default QuestionPreview;
