import { useEffect, useState } from 'react';

import { css } from '@emotion/css';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useModelizationProduct } from '@carbonmaps/ui/hooks/useGetProductSheet';
import { displayValue } from '@carbonmaps/ui/utils/numberFormat';

import Skeleton from '../../../components/Skeleton';
import Unit from '../../../components/units/Unit';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import ProductModel from '../../../models/Product.model';
import { CARBON_INDICATOR, WATER_INDICATOR } from '../../../utils/constants';

const styles = {
	skeleton: css({
		height: '50px !important',
		width: '100px !important',
	}),
};

const TopOverView = () => {
	const theme = useTheme();
	const params = useParams();
	const { t } = useTranslation();
	const { indicator } = useApp();
	const [intensity, setIntensity] = useState(0);
	const [percentage, setPercentage] = useState(0);

	const indicatorConfigItems = new Map();

	indicatorConfigItems.set(CARBON_INDICATOR, {
		intensityUnity: <Unit measure="carbonIntensityKg" color={theme.palette.grey[700]} fontWeight={500} />,
		color: theme.palette.primary.main,
	});
	indicatorConfigItems.set(WATER_INDICATOR, {
		color: siteColors.water500,
		intensityUnity: <Unit measure="waterIntensity" color={theme.palette.grey[700]} fontWeight={500} />,
	});

	const { data, isLoading } = useModelizationProduct({
		productId: _.toString(params.productId),
	});

	const productObject = new ProductModel(_.isString(data) ? {} : data);
	const isRecetteType = ['n1_semantic', 'n1_typical', 'typical_ingredients'].includes(
		_.toLower(data?.typeModelizationCompoIngredient),
	);

	const isRecetteCmaps = ['cmaps_ingredients'].includes( // cf scm3821
		_.toLower(data?.typeModelizationCompoIngredient),
	);

	useEffect(() => {
		if (indicator === CARBON_INDICATOR) {
			setPercentage(carbonIntensityPercent() ?? 0);
			setIntensity(carbonIntensity() ?? 0);
		} else {
			setPercentage(waterIntensityPercent() ?? 0);
			setIntensity(waterIntensity() ?? 0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [indicator, data]);

	const carbonIntensityPercent = () => {
		if (!data || _.isString(data)) return;
		return (
			(((isRecetteType ? productObject.getGesIngredient(false) : productObject.getGesIngredientV2()) ?? 0) * 100) /
			(productObject.getIntensity('gesTotal') || 1)
		);
	};

	const waterIntensityPercent = () => {
		if (!data || _.isString(data)) return;
		return (
			(((isRecetteType ? productObject.getWaterIngredient(false) : productObject.getWaterIngredientV2()) ?? 0) * 100) /
			(productObject.getIntensity('waterUseTotal') || 1)
		);
	};

	const carbonIntensity = () => {
		if (!data || _.isString(data)) return;
		return (isRecetteType ? productObject.getIntensity('gesAgriculture') : productObject.getGesIngredientV2()) ?? 0;
	};

	const waterIntensity = () => {
		if (!data || _.isString(data)) return;
		return (
			(isRecetteType ? productObject.getIntensity('waterUseAgriculture') : productObject.getWaterIngredientV2()) ?? 0
		);
	};

	return (
		<Box className="flexRow alignCenter" marginBottom="32px">
			{isLoading
				?
				(<Skeleton className={styles.skeleton} />)
				: (
					<Box>
						<Typography fontSize="23px" color={indicatorConfigItems.get(indicator).color} className="number">
							{isRecetteType || intensity === 0 || isRecetteCmaps
								? '-'
								:
								displayValue(intensity)}
						</Typography>
						<Typography color={theme.palette.grey[700]} fontSize="12px">
							{indicatorConfigItems.get(indicator).intensityUnity}
						</Typography>
					</Box>
				)}
			<Divider
				orientation="vertical"
				flexItem
				sx={{ border: `1px solid ${theme.palette.grey[500]}`, marginX: '24px' }}
			/>
			{isLoading
				? (<Skeleton className={styles.skeleton} />)
				: (
					<Box>
						<Typography fontSize="23px" color={indicatorConfigItems.get(indicator).color} className="number">
							{isRecetteType || intensity === 0 || isRecetteCmaps
								? '-'
								:
								displayValue(percentage, undefined, 1)}{' '}
							%
						</Typography>
						<Typography textTransform="uppercase" color={theme.palette.grey[700]} fontSize="12px">
							{t('agriculture-transformation-amont-lifecycle')}
						</Typography>
					</Box>
				)}
		</Box>
	);
};

export default TopOverView;
