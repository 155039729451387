import { useMemo, useRef } from 'react';

import { css, cx } from '@emotion/css';
import { useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { Atom, Factory, PackageOpen, Trash2, Truck } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { WATER_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import BlockTitle from '../../../components/BlockTitle';
import LifeCycleChartTooltip from '../../../components/charts/LifeCycleChartTooltip';
import Skeleton from '../../../components/Skeleton';
import Unit from '../../../components/units/Unit';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useSize } from '../../../hooks/useSize';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { getPackagingDetailsLifeCycleAction } from '../../../lib/react-query/features/packaging/packaging.actions';

import PLifecycleChart from './PLifecycleChart';

const styles = {
	container: css({
		padding: 24,
		borderRadius: '16px',
		border: `1px solid ${siteColors.grey500}`,
		marginBottom: 32,
	}),
	title: css({
		marginBottom: '24px !important',
	}),
	divTitle: css({
		display: 'inline-block',
		cursor: 'pointer',
	}),

	skeleton: css({
		// marginBottom: 32,
		height: '200px !important',
		width: '100% !important',
	} as any),
	noDataContainer: css({
		display: 'flex',
		flexDirection: 'column',
	}),
	axisContainer: css({
		paddingTop: 10,
	}),

	displayNone: css({
		display: 'none',
	}),
};

const getConfigMap = ({
	t,
	indicator,
	iconSize = 24,
	iconColor = siteColors.grey700,
}: {
	t: TFunction;
	indicator: string;
	iconSize?: number;
	iconColor?: string;
}) => {
	const config = new Map([
		[
			'materials',
			{
				icon: <Atom width={iconSize} height={iconSize} color={iconColor} />,
				getToolTip: ({ value }: { value: number }) => {return (
					<LifeCycleChartTooltip
						value={value}
						label={t('materials')}
						unit={<Unit measure={indicator === WATER_INDICATOR ? 'waterImpact' : 'carbonImpact'} />}
						precision={2}
					/>
				);},
				xLabel: t('materials'),
			},
		],
		[
			'transformation',
			{
				icon: <Factory width={iconSize} height={iconSize} color={iconColor} />,
				getToolTip: ({ value }: { value: number }) => {return (
					<LifeCycleChartTooltip
						value={value}
						label={t('transformation')}
						unit={<Unit measure={indicator === WATER_INDICATOR ? 'waterImpact' : 'carbonImpact'} />}
						precision={2}
						withMinValue
					/>
				);},
				xLabel: t('transformation'),
			},
		],
		[
			'transport',
			{
				icon: <Truck width={iconSize} height={iconSize} color={iconColor} />,
				getToolTip: ({ value }: { value: number }) => {return (
					<LifeCycleChartTooltip
						value={value}
						label={t('transport')}
						unit={<Unit measure={indicator === WATER_INDICATOR ? 'waterImpact' : 'carbonImpact'} />}
						precision={2}
					/>
				);},
				xLabel: t('transport'),
			},
		],
		[
			'endOfLife',
			{
				icon: <Trash2 width={iconSize} height={iconSize} color={iconColor} />,
				getToolTip: ({ value }: { value: number }) => {return (
					<LifeCycleChartTooltip
						value={value}
						label={t('end-of-life')}
						unit={<Unit measure={indicator === WATER_INDICATOR ? 'waterImpact' : 'carbonImpact'} />}
						precision={2}
					/>
				);},
				xLabel: t('end-of-life'),
			},
		],
	]);

	return config;
};

const formatData = ({ data, config }: { data?: any[]; config: Map<string, any> }) => {
	if (!data || !_.isArray(data)) {
		return [];
	}

	return data.map((d) => {
		const key = _.get(d, 'key');
		const configItem = config.get(key);

		return {
			...d,
			...configItem,
			value: _.get(d, 'percentage'),
			actualValue: _.get(d, 'value'),
			tooltip: configItem?.getToolTip({ value: _.get(d, 'value') }),
		};
	});
};

const PackagingLifeCycle = ({
	heightGraph = 220,
	bottomPosition = 55,
}: {
	heightGraph?: number;
	bottomPosition?: number;
	iconSize?: number;
}) => {
	const theme = useTheme();
	const { indicator: viewMode } = useApp();
	const params = useParams();

	const { selectedYear } = useYearSelection();

	// translation
	const { t } = useTranslation();

	// size
	const containerRef = useRef(null);
	const size = useSize(containerRef);

	const { indicator } = useApp();

	// ---- fetch data graph for this period selected ---- //
	const { data, isLoading } = useQuery({
		queryKey: [
			'getPackagingDetailsLifeCycle',
			{
				selectedYear,
				packagingId: _.toString(params.packagingId),
				indicator,
			},
		] as const,
		queryFn: getPackagingDetailsLifeCycleAction,
	});

	const svgParentSize = useMemo(() => {
		if (!size) {
			return {
				width: 730,
				height: 230,
			};
		}

		const height = heightGraph || 220;

		return {
			width: (size as any)?.width || 730,
			height,
		};
	}, [size, heightGraph]);

	const config = getConfigMap({ t: t as never, indicator });

	const finalData = formatData({ data: _.get(data, 'data'), config });

	const noDataContent = (
		<div className={cx('flexCenter textCenter')} css={{ color: siteColors.grey700 }}>
			<div>{t('packaging-lifecycle-no-data-message')}</div>
		</div>
	);

	const hasNoData = _.get(data, 'status') !== 'SUCCESS';
	// const hasNoData = true;

	const mainContent = (
		<div
			className={cx('', isLoading || hasNoData ? styles.displayNone : '')}
			css={{ width: '100%' }}
			ref={containerRef}
		>
			<PLifecycleChart
				datas={finalData}
				viewBox={`0 0 ${svgParentSize.width} ${svgParentSize.height}`}
				withLabel
				colors={
					viewMode === WATER_INDICATOR
						? {
								main: siteColors.water500,
								line: siteColors.water100,
								polygonGradient: [siteColors.water100, siteColors.water100],
						  }
						: {
								main: siteColors.carbon500,
								line: siteColors.carbon100,
								polygonGradient: [siteColors.carbon100, siteColors.carbon100],
						  }
				}
				areaHeight={svgParentSize.height}
				areaWidth={svgParentSize.width}
				labelColor={viewMode === WATER_INDICATOR ? siteColors.water500 : siteColors.carbon500}
				bottomPosition={bottomPosition}
				strokeWidth={1.5}
				lineWidth={2}
				fontStyles={{
					title: 12,
					subtitle: 12,
				}}
				topValueContainer={{
					width: 70,
					height: 30,
				}}
				axisClassName={styles.axisContainer}
			/>
		</div>
	);

	const loadingContent = (
		<Skeleton className={styles.skeleton}>
			<div>{t('packaging-lifecycle-no-data-message')}</div>
		</Skeleton>
	);

	return (
		<>
			<BlockTitle className={cx('flexRow alignCenter')}>
				{t('life-cycle-graph-title')}
				&nbsp;
				<Unit measure={indicator === WATER_INDICATOR ? 'waterIntensity' : 'carbonIntensityKg'} />
			</BlockTitle>
			<div className={cx('flex1 flexCenter', styles.container)}>
				{isLoading ? loadingContent : null}
				{!isLoading && hasNoData ? noDataContent : null}
				{mainContent}
			</div>
		</>
	);
};

export default PackagingLifeCycle;
