import { useCallback, useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { useMutation, useQuery } from '@tanstack/react-query';
import { eachOfLimit } from 'async';

import { ReactComponent as BlueCheckOK } from '@carbonmaps/media/icons/bluecheckok.svg';
import { getFacetConfig, getFacetLabel, getLabelValue } from '@carbonmaps/shared/utils/utils';
import { getCategoriesProduct } from '@carbonmaps/ui/actions/product.actions';
import { deleteSimulationAction } from '@carbonmaps/ui/actions/simulation.actions';
import { getCompanySuppliers } from '@carbonmaps/ui/actions/supplier.actions';
import { useRowTableSelection } from '@carbonmaps/ui/hooks/useRowTableSelection';

import { IFilterFolder } from '../../../components/Filter';
import { Option } from '../../../components/form/fields/MultiSelectCheckboxField';
import FilterContainer from '../../../components/layout/list/FilterContainer';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { useGetClientAuthQuery } from '../../../lib/react-query/features/auth/auth.hooks';
import { exportProductAction } from '../../../lib/react-query/features/export/product.actions';
import { queryClient } from '../../../providers/QueryClientProvider';
import { getLabelNodeFacet, SUPPLIER_LABEL_FOLDER } from '../../../utils/utils';

import CreateScenario from './CreateScenario';

const suppliersFolderLabel = SUPPLIER_LABEL_FOLDER;

type FilterProps = {
	setOpenModal: any;
	setSelectedOption: any;
	setIsNew: any;
	options: any[];
	isLoadingDataOption: boolean;
	setSearch: any;
	selectedOption: any;
};

const styles = {
	button: css({
		width: '240px !important',
	}),
};

const EcoConceptionFilter = ({
	selectedOption,
	setSearch,
	options,
	setOpenModal,
	setSelectedOption,
	setIsNew,
	isLoadingDataOption,
}: FilterProps) => {
	const [searchQueryParams, setSearchQueryParams] = useSearchQueryParams();
	// eslint-disable-next-line react-hooks/exhaustive-deps

	const [isExportLoading, setExportLoading] = useState(false);

	// translation
	const { t } = useTranslation();

	// custom facets suppliers
	const [filterFolders, setFilterFolders] = useState<IFilterFolder[]>([]);

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	// filters values
	const [appliedValuesByFolders, setAppliedValuesByFolders] = useState<IFilterFolder[]>([]);
	const [selectedValues, setSelectedValues] = useState<Option[]>([]);

	// -------------------------------------------------------------------------------------- //
	// -------------------------------- Use query ------------------------------------------- //
	// -------------------------------------------------------------------------------------- //
	// ---- fetch suppliers filter ---- //
	const { data: suppliersData, isLoading: isSUppliersLoading } = useQuery({
		queryKey: ['getAllSuppliersForCompany', { itemType: 'product', isFilter: true }],
		queryFn: getCompanySuppliers,
	});

	// ---- fetch facets ---- //
	const { data: facets, isLoading: isFacetsLoading } = useQuery({
		queryKey: [
			'getCategory',
			{
				input: selectedOption ? '' : searchQueryParams.input,
				facetFilters: searchQueryParams?.facetFilters || [],
			},
		],
		queryFn: getCategoriesProduct,
	});

	// ---- create custom facet for supplier ----
	useEffect(() => {
		if (!suppliersData) return;

		const suppliersFolder: IFilterFolder = {
			label: suppliersFolderLabel,
			isFacet: false,
			showCount: false,
			options: suppliersData.map((sup) => {
				return {
					label: sup.name,
					value: sup.id,
					folderLabel: suppliersFolderLabel,
				};
			}),
		};

		setFilterFolders((folders) => {
			const supplierFolderIndex = folders.findIndex((e) => {
				return e.label === suppliersFolderLabel;
			});

			if (supplierFolderIndex === -1) {
				const n2FolderIndex = folders.findIndex((e) => {
					if (e.isFacet) {
						return e.path === 'tagAdvancedModelization';
					}

					return false;
				});

				const n2Folder = folders[n2FolderIndex];
				return [...(n2Folder ? [n2Folder] : []), suppliersFolder, ...folders];
			}

			folders[supplierFolderIndex] = suppliersFolder;
			return folders;
		});
	}, [suppliersData]);

	useEffect(() => {
		const searchParams = searchQueryParams?.facetFilters ?? [];
		const supplierIdsParams = searchQueryParams?.supplierIds ?? [];

		//	if there are filters in the url
		if ((searchParams && searchParams.length) || (supplierIdsParams && supplierIdsParams.length)) {
			const currentSelectedValues = [] as any;
			const currentAppliedValuesFolders = [] as any;

			// for facet
			if (searchParams && searchParams.length) {
				searchParams.forEach((item: any) => {
					const arrayValues = item.values.map((v: any) => {
						const value = {
							facetPath: item.path,
							value: v,
							folderLabel: item?.folderLabel ?? item.path,
							label: getLabelValue(item.path, v),
							isFacetBucket: true,
							type: typeof v,
							global: item.global === true ? true : false,
							itemType: item?.itemType?.toLowerCase() || 'product',
						};

						currentSelectedValues.push(value);
						return value;
					});

					currentAppliedValuesFolders.push({
						isFacet: true,
						label: item?.folderLabel ?? item.path,
						options: arrayValues,
						showCount: true,
					});
				});
			}

			//for supplier
			if (supplierIdsParams && supplierIdsParams.length) {
				if (!suppliersData) return;
				const values = suppliersData
					.filter((item: any) => {
						return supplierIdsParams.includes(item.id);
					})
					.map((sup) => {
						return {
							label: sup.name,
							value: sup.id,
							folderLabel: suppliersFolderLabel,
						};
					});
				const suppliersFolder: IFilterFolder = {
					label: suppliersFolderLabel,
					isFacet: false,
					showCount: false,
					options: values,
				};
				currentAppliedValuesFolders.push(suppliersFolder); // each one filter
				currentSelectedValues.push(...values); // in the base filter
			}

			setSelectedValues(currentSelectedValues);
			setAppliedValuesByFolders(currentAppliedValuesFolders);
		}
	}, [searchQueryParams, suppliersData]);

	useEffect(() => {
		const asyncWrapper = async () => {
			// don't change current filter
			if (!facets) return;

			const otherFacetFilterFolders: IFilterFolder[] = [];
			let N2FacetFolder: IFilterFolder;

			for await (const entry of Object.entries(facets.facet)) {
				const [key, value] = entry;
				const { buckets } = value as any;
				const { label, type, global, itemType } = getFacetConfig(key, authData?.session.company) ?? {};

				const facetLabel = label || getFacetLabel(key, authData?.session.company);

				const currentFacetFolder: IFilterFolder = {
					label: facetLabel,
					options: [],
					isFacet: true,
					path: key,
					showCount: true,
					global,
					customIcon:
						key === 'tagAdvancedModelization' ? <BlueCheckOK css={{ 'path:first-of-type': { fill: 'grey' } }} /> : null,
				};

				await eachOfLimit(buckets as any[], 100, async (bucket, k) => {
					if (key !== 'tagAdvancedModelization' && !bucket.count) {
						return;
					}

					currentFacetFolder.options.push({
						value: bucket._id,
						label: getLabelValue(key, bucket._id),
						labelNode: ['tagAdvanced', 'tagAdvancedModelization'].includes(key)
							? getLabelNodeFacet(key, bucket._id, t)
							: undefined,
						count: bucket.count,
						folderLabel: facetLabel,
						isFacetBucket: true,
						facetPath: key,
						type: type ?? 'hardcoded-undefined-facet-type',
						global,
						itemType,
					});

					return;
				});

				if (currentFacetFolder.path === 'tagAdvancedModelization') {
					N2FacetFolder = currentFacetFolder;
				} else {
					otherFacetFilterFolders.push(currentFacetFolder);
				}
			}

			setFilterFolders((folders) => {
				const notFacetFolders = folders.filter((e) => {
					return !e.isFacet;
				});

				return [...(N2FacetFolder ? [N2FacetFolder] : []), ...notFacetFolders, ...otherFacetFilterFolders];
			});
		};

		asyncWrapper();
	}, [facets, authData?.session.company]);

	// ---- select rows selected in store ---- //
	const { selectedRows } = useRowTableSelection();

	const _selectedSubRows = useMemo(() => {
		return selectedRows.filter((row: any) => {
			return row.depth === 1;
		});
	}, [selectedRows]);

	// ---- handle export  data ---- //
	const handleExport = useCallback(
		async ({ exportFormat }: { exportFormat: string }) => {
			try {
				setExportLoading(true);
				await exportProductAction(
					{
						ids: selectedRows.map((e: any) => {
							return e.original.objectId;
						}),
						facetFilters: searchQueryParams.facetFilters,
						supplierIds: searchQueryParams?.supplierIds ?? [],
						input: searchQueryParams.input ?? '',
						format: exportFormat,
						companyCode: authData?.session.company?.code,
					},
					t,
				);
				setExportLoading(false);
			} catch (error) {
				setExportLoading(false);
				console.error(error);
			}
		},
		[
			authData?.session.company?.code,
			searchQueryParams.facetFilters,
			searchQueryParams.input,
			searchQueryParams?.supplierIds,
			selectedRows,
		],
	);

	const {
		mutate: deleteSimulations,
		isLoading: isLoadingDelete,
	} = useMutation({
		mutationKey: ['deleteFacetFieldSelectedRow'],
		mutationFn: deleteSimulationAction,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['getProductSimulationDataAction'] });
		},
	});

	const handleDeleteMany = useCallback(() => {
		const productSourceIds: string[] = [];
		const simulationIds: string[] = [];

		selectedRows.forEach((row: any) => {
			if (row.depth === 0) {
				productSourceIds.push(row.original.objectId);
				return;
			}

			if (row.depth === 1) {
				simulationIds.push(row.original.objectId);
			}
		});

		deleteSimulations({ productSourceIds, simulationIds });
	}, [deleteSimulations, selectedRows]);

	return (
		<FilterContainer
			skeleton={isFacetsLoading || isSUppliersLoading}
			isLoading={false}
			appliedValuesByFolders={appliedValuesByFolders}
			setAppliedValuesByFolders={setAppliedValuesByFolders}
			selectedValues={selectedValues}
			setSelectedValues={setSelectedValues}
			folders={filterFolders}
			setSearchQueryParams={setSearchQueryParams}
			suppliersFolderLabel={suppliersFolderLabel}
			selectedRow={_selectedSubRows}
			handleExport={handleExport}
			isTableLoading={false}
			isExportLoading={isExportLoading}
			withMore
			tag={'scénario'}
			subMessageConfirmDelete={t('confirm-message-delete-scenario')}
			count={options?.length || 0}
			rightContent={
				<div className={cx(styles.button)}>
					<CreateScenario
						setSearch={setSearch}
						isLoading={isLoadingDataOption}
						setOpenModal={setOpenModal}
						setSelectedOption={setSelectedOption}
						setIsNew={setIsNew}
					/>
				</div>
			}
			moreActionExport={false}
			handleDelete={handleDeleteMany}
			loadingDelete={isLoadingDelete}
			searchTrackName="Simulate Eco Design Search"
			filterTrackName="Simulate Eco Design Click Scenario"
			trackCategory="Simulate"
		/>
	);
};

export default EcoConceptionFilter;
