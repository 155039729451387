import { useCallback } from 'react';

import { css, cx } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';
import { useNavigate, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import LightTooltipGlossary from '../../../components/LightTooltipGlossary';
import { useGlossary } from '../../../hooks/useGlossary';
import { useTranslation } from '../../../hooks/useTranslation';
import { cn, siteColors } from '../../../lib/colors';
import { useGetOneProductModelization } from '../../../lib/react-query/features/modelization/modelization.hooks';

const styles = {
	container: css({
		background: cn(siteColors.grey200, '!important'),
		gap: '16px',
		border: cn('1px solid', siteColors.grey500),
		borderRadius: '16px',
		padding: '24px',
		color: siteColors.grey900,
		marginTop: '16px',
		width: '100%',
		alignItems: 'center!important',
	}),
	linkButton: css({ width: '300px' }),
};

const ModelizationBlock = () => {
	const { t } = useTranslation();
	const { g } = useGlossary();
	const params = useParams();
	const navigate = useNavigate();
	const { indicator } = useApp();

	const { data, isLoading, isError, error } = useGetOneProductModelization({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		productId: params.modelizationId!,
		viewMode: indicator,
	});

	const handleClick = useCallback(() => {
		navigate(`${FRONT_PATH_NAMES.products.details}/${data?.objectId}/synthesis/`);
	}, [data, navigate]);

	return (
		<div className={cx('flexRow nowrap spaceBetween', styles.container)}>
			<div>
				{data?.modelizationActive && (
					<>
						{t('modelization-detail-info-bloc-part1', {
							product: t('product').toLowerCase(),
							recipe: t('recipe'),
							recipe_lowercase: t('recipe').toLowerCase(),
						})}{' '}
						<LightTooltipGlossary title={g(`modelization-${data?.modelizationActive?.toLowerCase()}`)}>
							<span className="underline">
								{t(`modelization-${data?.modelizationActive?.toLowerCase()}`, {
									product: t('product').toLowerCase(),
									recipe: t('recipe'),
									recipe_lowercase: t('recipe').toLowerCase(),
								})}
							</span>
						</LightTooltipGlossary>{' '}
						{t(`modelization-detail-info-bloc-${data?.modelizationActive?.toLowerCase()}-part2`, {
							product: t('product').toLowerCase(),
							recipe: t('recipe'),
							recipe_lowercase: t('recipe').toLowerCase(),
						})}
					</>
				)}
			</div>

			<BButton
				isDisabled={false}
				variant="secondary"
				label={t('modelization-button-product-detail')}
				onClick={handleClick}
				className={cx(styles.linkButton)}
			/>
		</div>
	);
};

export default ModelizationBlock;
