import { useEffect, useState } from 'react';

import { css, cx } from '@emotion/css';
import { zodResolver } from '@hookform/resolvers/zod';
import { Divider, Typography } from '@mui/material';
import _ from 'lodash';
import { AlertCircle, ArrowLeftRight, Check, Pencil, Plus, Search } from 'lucide-react';
import { useForm } from 'react-hook-form';

import {
	QuoteClientFormInput,
	QuoteClientFormValidationSchema,
} from '@carbonmaps/shared/validations/quoteClientEdit.validations';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import BInput from '@carbonmaps/ui/components/saas/BInput';
import FormContainer from '@carbonmaps/ui/components/saas/Form/FormContainer';

import SearchAutocomplete from '../../components/SearchAutocompleteV2';
import AlertStatic from '../../components/Toast/AlertStatic';
import { useTranslation } from '../../hooks/useTranslation';
import { cn, siteColors } from '../../lib/colors';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';

const styles = {
	title: css({
		fontSize: '24px !important',
		fontWeight: '600 !important',
		margin: 0,
	}) as any,
	subTitle: css({
		marginTop: '0px',
		fontSize: '14px !important',
		fontWeight: '400 !important',
		width: '100% !important',
		color: siteColors.grey900,
	}) as any,

	formContainer: css({
		maxWidth: 500,
		gap: '24px',
		'.input-container': {
			width: '100%',
		},
		marginBottom: '16px',
	}) as any,

	header: css({
		gap: '16px',
	}) as any,
	label1: css({
		fontSize: '14px !important',
		fontWeight: '600 !important',
		color: siteColors.grey900,
		marginBottom: '8px',
	}) as any,
	label: css({
		fontSize: '14px !important',
		fontWeight: '600 !important',
		color: siteColors.grey900,
	}) as any,
	searchClassName: css({
		width: '100%',
		'& .MuiOutlinedInput-root': {
			width: '100% !important',
			height: '42px!important',
			borderRadius: '11px !important',
		},
	}) as any,
	netWeightContainer: css({
		'& .MuiInputBase-root': {
			width: '120px !important',
		},
	}),

	chipStyles: css({
		textTransform: 'capitalize',
		background: 'var(--color-background)!important',
		uppercase: 'none',
		borderRadius: '8px !important',
		border: '1px solid rgba(0, 0, 0, 0.10)!important',
		span: {
			color: cn(siteColors.grey900, '!important'),
			fontSize: '1rem !important',
			padding: '8px 8px !important',
		},
	}),
	button: css({
		background: '#fff !important',
		minWidth: '36px !important',
		width: '36px !important',
		height: '36px !important',
		padding: '8px !important',
		border: cn('1px solid', siteColors.grey500, '!important'),
		borderRadius: '8px',
	}),
	actionContainer: css({
		padding: '0px 24px 16px 24px !important',
	}),
	confirmButton: css({
		fontSize: '16px !important',
		fontWeight: 600,
		textTransform: 'none !important',
		gap: 10,
		color: '#C74600 !important',
		background: '#FFE4CB !important',
		'& :hover': {
			background: '#FFCA99 !important',
		},
		'& .Mui-disabled': {
			// color: '#C74600',
			color: '#C74600 !important',
			background: '#FFE4CB !important',
		},
		borderRadius: '8px !important',
	} as any),
	dialogTitle: css({
		textTransform: 'capitalize',
	}),

	blocForm: css({
		gap: '16px',
	}) as any,

	renderOptionRoot: css({
		padding: '8px',
		cursor: 'pointer',
		'&:hover': {
			background: siteColors.grey300,
		},
	}),
	renderOptionLabel: css({
		fontWeight: 'bold',
	}),

	editAutocomplete: css({
		cursor: 'pointer',
		borderRadius: '8px',
		border: cn('1px solid', siteColors.grey700),
		padding: '10.5px 10px',
		background: siteColors.common.white,
	}),

	titleAutocomplete: css({
		fontSize: '14px',
		color: siteColors.grey900,
		fontWeight: 600,
		border: 'none',
	}),

	sectionTitle: css({
		fontSize: '16px',
		fontWeight: 600,
		borderBottom: `1px solid ${siteColors.grey700}`,
		paddingBottom: '8px',
		marginBottom: '16px',
		width: '100%',
	}) as any,
};

const QuoteClientForm = ({
	selectedOption,
	setSelectedOption,
	isLoading,
	isSuccess,
	isError,
	onSaveData,
	quote,
	title,
	setSearch,
	options,
	message,
	onClose,
}: any) => {
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	// translation
	const { t } = useTranslation();

	const [step, setStep] = useState(1);

	const {
		register,
		control,
		handleSubmit,
		setValue,
		reset,
		getValues,
		formState: { errors },
	} = useForm<QuoteClientFormInput>({
		resolver: zodResolver(QuoteClientFormValidationSchema),
		defaultValues: async () => {
			return {
				quoteId: quote?.objectId,
				name: quote?.name,
				customMessage: quote?.customMessage,
				clientId: selectedOption?.objectId ?? quote?.client?.objectId,
				enterpriseName: selectedOption?.name ?? quote?.client?.name,
				siretNumber: selectedOption?.siretNumber ?? quote?.client?.siretNumber,
				address: selectedOption?.address ?? quote?.client?.address,
				postalCode: selectedOption?.postalCode ?? quote?.client?.postalCode,
				city: selectedOption?.city ?? quote?.client?.city,
				interlocutorName: selectedOption?.interlocutorName ?? quote?.client?.interlocutorName,
				interlocutorFirstName: selectedOption?.interlocutorFirstName ?? quote?.client?.interlocutorFirstName,
				interlocutorEmail: selectedOption?.interlocutorEmail ?? quote?.client?.interlocutorEmail,
				interlocutorUserName: quote?.interlocutorUserName ?? `${authData?.user?.firstName} ${authData?.user?.lastName}`,
				interlocutorUserEmail: quote?.interlocutorUserEmail ?? authData?.user?.email,
				interlocutorUserTel: quote?.interlocutorUserTel ?? authData?.user?.tel,
			};
		},
		mode: 'onChange',
	});

	const handleSubmitForm = (data: any) => {
		onSaveData(data);
	};

	useEffect(() => {
		if (selectedOption) {
			const clientFields = [
				{ name: 'enterpriseName', value: selectedOption.name },
				{ name: 'siretNumber', value: selectedOption.siretNumber },
				{ name: 'address', value: selectedOption.address },
				{ name: 'postalCode', value: selectedOption.postalCode },
				{ name: 'city', value: selectedOption.city },
				{ name: 'interlocutorName', value: selectedOption.interlocutorName },
				{ name: 'interlocutorFirstName', value: selectedOption.interlocutorFirstName },
				{ name: 'interlocutorEmail', value: selectedOption.interlocutorEmail },
				{ name: 'clientId', value: selectedOption.objectId },
			];

			clientFields.forEach(({ name, value }) => setValue(name as any, value));
		}
	}, [selectedOption, setValue]);
console.log(getValues('enterpriseName'),'selectedOption')
	return (
		<div css={{ marginBottom: 24 }}>
			<FormContainer
				loading={isLoading}
				title=""
				buttonTitle={t('save-button')}
				onSubmit={handleSubmit(handleSubmitForm)}
				isSuccess={isSuccess}
				isError={isError}
				withSave
				withCancel
				onCancel={() => {
					onClose();
				}}
				alertSuccess={<AlertStatic text={message} type={'success'} icon={<Check color={siteColors.green500} />} />}
				alertError={<AlertStatic text={message} type={'error'} icon={<AlertCircle color="#C74600" />} />}
				addStyles={{
					maxWidth: 528,
					margin: 'auto',
				}}
				t={t}
			>
				<div className={cx('flexColumn width100', styles.formContainer)}>
					<div className={cx('flexColumn width100', styles.header)}>
						<p className={cx(styles.title)}>{title}</p>
						<p className={cx(styles.subTitle)}>
							{t('quote-form-update-subtitle1')}
							<br />
							{t('quote-form-update-subtitle2')}
						</p>
					</div>

					<div className={cx('flexColumn width100', styles.blocForm)}>
						<div className={cx(styles.sectionTitle)}>{t('quote')}</div>

						<BInput
							required
							id="name"
							label={<div className={cx(styles.label)}>{t('quote-name')}</div>}
							fullWidth
							margin="dense"
							{...register('name')}
							name="name"
							error={errors.name ? true : false}
							errorMessage={errors?.name?.message}
							autoComplete="off"
						/>
						<BInput
							required
							id="customMessage"
							label={<div className={cx(styles.label)}>{t('custom-message-quote')}</div>}
							fullWidth
							margin="dense"
							{...register('customMessage')}
							name="customMessage"
							error={errors.customMessage ? true : false}
							errorMessage={errors?.customMessage?.message}
							autoComplete="off"
							isTextarea={true}
						/>
					</div>

					<div className={cx('flexColumn width100', styles.blocForm)}>
						<div className={cx(styles.sectionTitle)}>{t('Interlocuteur')}</div>

						<BInput
							required
							id="interlocutorUserName"
							label={<div className={cx(styles.label)}>{t('client-name')}</div>}
							fullWidth
							margin="dense"
							{...register('interlocutorUserName')}
							error={errors.interlocutorUserName ? true : false}
							errorMessage={errors?.interlocutorUserName?.message}
							autoComplete="off"
						/>
						<BInput
							required
							id="interlocutorUserEmail"
							label={<div className={cx(styles.label)}>{t('client-email')}</div>}
							fullWidth
							margin="dense"
							{...register('interlocutorUserEmail')}
							error={errors.interlocutorUserEmail ? true : false}
							errorMessage={errors?.interlocutorUserEmail?.message}
							autoComplete="off"
						/>

						<BInput
							required
							id="interlocutorUserTel"
							label={<div className={cx(styles.label)}>{t('phoneNumber')}</div>}
							fullWidth
							margin="dense"
							{...register('interlocutorUserTel')}
							error={errors.interlocutorUserTel ? true : false}
							errorMessage={errors?.interlocutorUserTel?.message}
							autoComplete="off"
						/>
					</div>
					<div className={cx('flexColumn width100', styles.blocForm)}>
						<div className={cx(styles.sectionTitle)}>{t('client')}</div>

						<BButton
							iconLeft={<ArrowLeftRight color={siteColors.grey700} size={20} />}
							onClick={() => {
								return setStep(2);
							}}
								label={t('change-client-button')}
								variant="secondary"
						/>
						{step === 2 && (
							<>
								<SearchAutocomplete
									setParamsAutocomplete={setSearch && _.debounce(setSearch, 500)}
									options={options}
									open={true}
									setSelectedOption={setSelectedOption}
									selectedOption={selectedOption}
									className={cx(styles.searchClassName)}
									isLoading={false}
									renderOption={(props, option: any) => {
										return (
											<div
												{...(props as any)}
												className={cx(styles.renderOptionRoot)}
												onClick={() => {
													setSelectedOption && setSelectedOption(option);
													setStep(1);
												}}
											>
												<div className={cx(styles.renderOptionLabel, 'grey900')}>{option.label}</div>
												<div>{option.address}</div>
												<div>
													{option.postalCode} {option.city}
												</div>
											</div>
										);
									}}
								/>

								<BButton
									onClick={() => {
										setStep(1);
										//todo optimize after test
										setValue('enterpriseName', '');
										setValue('siretNumber', '');
										setValue('address', '');
										setValue('postalCode', '');
										setValue('city', '');
										setValue('interlocutorName', '');
										setValue('interlocutorFirstName', '');
										setValue('interlocutorEmail', '');
										setValue('clientId', '');
									}}
									iconLeft={<Plus color={siteColors.grey700} size={20} />}
									variant="tertiary"
									css={{
										padding: '0',
									}}
									label={
										<Typography fontWeight={600}>
											{t('form-quote-client-new-button')}
										</Typography>
									}
								/>
							</>
						)}

						{step === 3 && (
							<div
								onClick={() => {
									return setStep(2);
								}}
								className={cx('flexRow width100 justifyBetween alignCenter  ', styles.editAutocomplete)}
							>
								<div>
									<div className={cx(styles.titleAutocomplete)}>{selectedOption?.label}</div>
									{selectedOption?.siretNumber}
									<br />
									{selectedOption?.address}
									<br />
									{selectedOption?.postalCode} {selectedOption?.city}
									<br />
								</div>
								<Pencil color={siteColors.grey700} size={20} />
							</div>
						)}

						{step === 1 && (
							<>
								<BInput
									required
									id="enterpriseName"
									label={<div className={cx(styles.label)}>{t('Raison sociale (obligatoire)')}</div>}
									fullWidth
									margin="dense"
									{...register('enterpriseName')}
									name="enterpriseName"
									error={errors.enterpriseName ? true : false}
									errorMessage={errors?.enterpriseName?.message}
									autoComplete="off"
									disabled={getValues('enterpriseName') === '' ? false : true}
								/>
								<BInput
									required
									id="siretNumber"
									label={<div className={cx(styles.label)}>{t('Numéro du siret')}</div>}
									fullWidth
									margin="dense"
									{...register('siretNumber')}
									error={errors.siretNumber ? true : false}
									errorMessage={errors?.siretNumber?.message}
									autoComplete="off"
								/>
								<BInput
									required
									id="address"
									label={<div className={cx(styles.label)}>{t('Adresse')}</div>}
									fullWidth
									margin="dense"
									{...register('address')}
									error={errors.address ? true : false}
									errorMessage={errors?.address?.message}
									autoComplete="off"
								/>
								<div css={{ display: 'grid', gridTemplateColumns: '1fr 2.5fr', gap: '8px' }}>
									<div>
										<BInput
											required
											id="postalCode"
											label={<div className={cx(styles.label)}>{t('client-postal-code')}</div>}
											fullWidth
											margin="dense"
											{...register('postalCode')}
											error={errors.postalCode ? true : false}
											errorMessage={errors?.postalCode?.message}
											autoComplete="off"
										/>
									</div>
									<div>
										<BInput
											required
											id="city"
											label={<div className={cx(styles.label)}>{t('client-city')}</div>}
											fullWidth
											margin="dense"
											{...register('city')}
											error={errors.city ? true : false}
											errorMessage={errors?.city?.message}
											autoComplete="off"
										/>
									</div>
								</div>
								<BInput
									required
									id="interlocutorFirstName"
									label={<div className={cx(styles.label)}>{t('client-lastName')}</div>}
									fullWidth
									margin="dense"
									{...register('interlocutorFirstName')}
									error={errors.interlocutorFirstName ? true : false}
									errorMessage={errors?.interlocutorFirstName?.message}
									autoComplete="off"
								/>
								<BInput
									required
									id="interlocutorName"
									label={<div className={cx(styles.label)}>{t('quote-client-name')}</div>}
									fullWidth
									margin="dense"
									{...register('interlocutorName')}
									error={errors.interlocutorName ? true : false}
									errorMessage={errors?.interlocutorName?.message}
									autoComplete="off"
								/>
								<BInput
									required
									id="interlocutorEmail"
									label={<div className={cx(styles.label)}>{t('client-email')}</div>}
									fullWidth
									margin="dense"
									{...register('interlocutorEmail')}
									error={errors.interlocutorEmail ? true : false}
									errorMessage={errors?.interlocutorEmail?.message}
									autoComplete="off"
								/>
							</>
						)}
					</div>
				</div>
			</FormContainer>
		</div>
	);
};

export default QuoteClientForm;
