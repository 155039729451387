import { ComponentProps, Fragment, useCallback, useEffect, useState } from 'react';

import { css, cx } from '@emotion/css';
import Autocomplete, { type AutocompleteProps } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import type { Theme } from '@mui/material/styles';
import styled from '@mui/material/styles/styled';
import { Search } from 'lucide-react';

import { useJuneTrack } from '../hooks/useJuneTrack';
import { useSearchQueryParams } from '../hooks/useSearchQueryParams';
import { useTranslation } from '../hooks/useTranslation';
import { cn, siteColors } from '../lib/colors';

import Skeleton from './Skeleton';

const AUTOCOMPLETE_WIDTH = 297; // px
const AUTOCOMPLETE_HEIGHT = 42; // px

const styles = {
	innerContainer: css({
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: '12px 16px',
		gap: '8px',
		background: '#FFFFFF',
		borderBottom: cn('1px solid', siteColors.grey500),
		flex: 'none',
		order: 0,
		alignSelf: 'stretch',
		flexGrow: 0,
		cursor: 'pointer',
		color: siteColors.text,
		'&:hover': {
			background: siteColors.grey300,
		},
	}),
	content: css({
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		width: '90%',
	}),
	autoCompleteContainer: css({
		'& .MuiAutocomplete-popper .MuiAutocomplete-option': {
			backgroundColor: 'red',
		},
		'& label.Mui-focused': {
			color: 'transparent !important',
			border: `1px solid ${siteColors.primary} !important`,
			boxShadow: `0px 0px 0px 2px ${siteColors.carbon100}`,
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: 'transparent !important',
		},
		'& .MuiAutocomplete-popupIndicator': {
			display: 'none !important',
		},
		'& .MuiOutlinedInput-root': {
			padding: '0px !important',
			width: `${AUTOCOMPLETE_WIDTH}px !important`,
			height: `${AUTOCOMPLETE_HEIGHT}px !important`,
			background: '#FFFFFF !important',
			border: cn('1px solid', siteColors.grey500, '!important'),
			borderRadius: '8px !important',
			flex: 'none !important',
			order: 2,
			alignSelf: 'stretch !important',
			flexGrow: 0,
			'& fieldset': {
				borderColor: 'transparent !important',
				transition: '100ms',
			},
			'&:hover fieldset': {
				borderColor: 'transparent !important',
			},
			'&.Mui-focused fieldset': {
				border: `1px solid ${siteColors.carbon300} !important`,
				boxShadow: `0px 0px 0px 2px ${siteColors.carbon100}`,
			},
		},
	}),
	search: css({
		marginLeft: '6px !important',
	}),
	skeletonContainer: css({
		borderRadius: '8px',
	}),
	optionList: css({
		// boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: '12px 16px',
		gap: '8px',
		background: '#FFFFFF',
		borderBottom: cn('1px solid', siteColors.grey500),
		flex: 'none',
		order: 0,
		alignSelf: 'stretch',
		flexGrow: 0,
		cursor: 'pointer',
		color: siteColors.text,
		'&:hover': {
			background: siteColors.grey300,
		},
	}),
};

const CustomPaper = styled(Paper)(({ theme }) => {
	return {
		border: cn('1px solid', siteColors.primary),
		borderTop: 'none',
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0,
		position: 'relative',
		top: -10,
	};
});

interface SearchAutocompleteProps {
	isLoading?: boolean;
	skeleton?: boolean;
	setParamsAutocomplete?: (...params: any[]) => any;
	options?: any[];
	placeholder?: string;
	setParams?: (...params: any[]) => any;
	size?: number;
	isClearInputValue?: boolean;
	setIsClearInputValue?: (e: boolean) => void;
	className?: string;
	// ! wtf is this if it aint used at all?
	// params?: any;
	// data?: any[];
	open?: boolean;
	setSelectedOption?: any;
	selectedOption?: any;
	renderOption?: ComponentProps<typeof Autocomplete>['renderOption'];
	disabled?: boolean;
	getInputFromUrl?: boolean;
	trackName?: string;
	trackCategory?: string;
	trackingOptions?: any;
}

const SearchAutocomplete = ({
	isLoading,
	skeleton = false,
	setParamsAutocomplete,
	options = [],
	placeholder,
	size = 10,
	isClearInputValue = false,
	setIsClearInputValue,
	className = '',
	open = false,
	setSelectedOption,
	selectedOption,
	renderOption,
	disabled,
	getInputFromUrl = true,
	trackName,
	trackCategory,
	trackingOptions,
}: SearchAutocompleteProps) => {
	// translation
	const { t } = useTranslation();
	const analytics = useJuneTrack();

	const [searchQueryParams] = useSearchQueryParams();
	const [inputValue, setInputValue] = useState(getInputFromUrl ? searchQueryParams?.input ?? '' : '');
	const [params, setParams] = useState({});
	const [openOption, setOpenOption] = useState(false);
	useEffect(() => {
		if (isClearInputValue || !searchQueryParams?.input) {
			setInputValue('');
			setIsClearInputValue && setIsClearInputValue(false);
		}
	}, [isClearInputValue, searchQueryParams, setIsClearInputValue]);

	useEffect(() => {
		if (selectedOption) {
			setInputValue(selectedOption?.label);
		}
	}, [selectedOption, setSelectedOption]);

	const handleButtonClick = () => {
		setOpenOption(false); // Close the options menu
		// Perform any other actions you need when the button is clicked
	};

	const defaultRenderOption = useCallback(
		(props: any, option: any) => {
			return (
				<div
					onClick={() => {
						setInputValue(option.label);
						setSelectedOption && setSelectedOption(option);
						return handleButtonClick();
					}}
				>
					<span className={styles.optionList}>{option.label}</span>
				</div>
			);
		},
		[setSelectedOption],
	);
	return (
		<Autocomplete
			className={cx(styles.autoCompleteContainer, className)}
			disabled={disabled}
			id="search-bar-controllable"
			PaperComponent={CustomPaper}
			noOptionsText={isLoading ? t('Recherche en cours') : t('Aucun résultat')}
			filterOptions={(x) => {
				return x;
			}}
			// open={true}
			open={open === true ? openOption : false}
			//	open={openOption}
			onOpen={() => {
				if (open === true) return setOpenOption(true);
				return undefined;
			}}
			onClose={() => {
				if (open === true) return setOpenOption(false);
				return undefined;
			}}
			openOnFocus={open === true ? open : undefined}
			options={options}
			// options={iOptions}
			getOptionLabel={(option) => {
				return (option as any)?.label ?? option;
			}}
			onChange={(event, value: any, reason) => {
				setInputValue(value);
			}}
			autoComplete={false}
			value={inputValue}
			onInputChange={(event, newInputValue, reason) => {
				setParams &&
					setParams({
						...params,
						page: 1,
						size: size,
						input: newInputValue,
					});
				setParamsAutocomplete &&
					setParamsAutocomplete((oldValue: any) => {
						return {
							...oldValue,
							input: newInputValue,
							page: 1,
						};
					});
				setInputValue(newInputValue);

				// add tracking
				if (trackName) {
					analytics?.track(trackName, trackingOptions || {}, {
						// Add the GROUP_ID here to track this event on behalf of a workspace
						context: { category: trackCategory },
					});
				}
			}}
			renderInput={(params) => {
				return (
					<TextField
						{...params}
						placeholder={placeholder}
						InputProps={{
							...params.InputProps,
							startAdornment: (
								<InputAdornment position="start">
									{' '}
									<Search className={styles.search} />
								</InputAdornment>
							),
							endAdornment: (
								<Fragment>
									{isLoading ? <CircularProgress color="inherit" size={15} /> : params.InputProps.endAdornment}
								</Fragment>
							),
						}}
					/>
				);
			}}
			renderOption={renderOption || defaultRenderOption}
			// autoHighlight
			// clearOnBlur
		/>
	);
	// return autoCompleteElement;
};

export default SearchAutocomplete;
