import { z } from 'zod';

export const QuoteClientFormValidationSchema = z.object({
	quoteId: z.string({ required_error: 'Devis obligatoire' }),
	name: z
		.string({ required_error: 'Nom du devis obligatoire' })
		.min(1, 'Nom du devis obligatoire')
		.max(255, 'Le nom du devis doit avoir 255 caractères au maximum'),
	customMessage: z.string().optional(),
	clientId: z.string({ required_error: 'Client obligatoire' }),
	enterpriseName: z
		.string({ required_error: 'Raison social obligatoire' })
		.min(1, 'Raison social  obligatoire')
		.max(255, 'Raison social  doit avoir 255 caractères au maximum'),
	siretNumber: z.string({ required_error: 'Numéro de SIRET obligatoire' }) /* .optional() */,
	address: z.string().optional(),
	postalCode: z.string().optional(),
	objectId: z.string().optional(),
	city: z.string().optional(),
	interlocutorName: z.string().optional(),
	interlocutorFirstName: z.string().optional(),
	interlocutorEmail: z
		.string({ required_error: 'email obligatoire' })
		.email('Mettre une adresse email valide')
		.optional(),
	interlocutorTel: z.string().optional(),

	interlocutorUserName: z.string().optional(),
	interlocutorUserEmail: z
		.string({ required_error: 'email obligatoire' })
		.email('Mettre une adresse email valide')
		.optional(),
	interlocutorUserTel: z.string().optional(),
});

export type QuoteClientFormInput = z.infer<typeof QuoteClientFormValidationSchema>;

export const QuantityFormValidationSchema = z.object({
	quoteId: z.string({ required_error: 'Devis obligatoire' }),
	quantity: z.any({ required_error: 'Quantité obligatoire' }),
	// estimatedImpactCarbon: z.any(),
	// estimatedImpactWater:	z.any().optional(),
});

export type QuantityFormInput = z.infer<typeof QuantityFormValidationSchema>;

export const CommentFormValidationSchema = z.object({
	message: z.string({ required_error: 'message obligatoire' }),
});

export type CommentFormInput = z.infer<typeof CommentFormValidationSchema>;
