import React from 'react';

import { type Theme } from '@emotion/react';
import { type Interpolation } from '@emotion/serialize';
import Button from '@mui/material/Button';
import type { SxProps } from '@mui/system';

import LightTooltip from '../../../../apps/front/src/components/LightTooltip';
import { siteColors } from '../../../../apps/front/src/lib/colors';

const paletteColorsCustom = {
	white: '#FFFFFF',
	// black: "#000",
	darkBrown: '#312029',
	lightBrown: '#854D27',
	yellowMain: '#F8B93A',

	// Colors from the Design System
	darkGreen: '#0C2721',
	darkerGreen: '#4D8073',

	// lightBlue: "#DAFAFA",
	// mainBlue: "#4AB5B0",
	lightBlue: '#F4FAFF',
	mainBlue: '#046FF1',

	lightRed: '#F9DFDC',
	mainRed: '#EA8C81',

	lightPurple: '#DADCF1',
	mainPurple: '#4452BB',

	// lightGreen: "#D5F6D9",
	// mainGreen: "#1D846B",
	lightGreen: '#F5F9EC',
	mainGreen: '#557A06',

	lightTeal: '#F0F9FC',
	mainTeal: siteColors.teal500,

	lightOrange: '#FEF7F1',
	mainOrange: '#D14900',

	lightGreyBlue: '#F0F5F4',
	mainGreyBlue: '#C2D6D1',

	grey100: '#FBFDFC',
	lightGrey: '#E0EBE8',
	mainGrey: '#1D292A',

	grey: '#F9FBFA',
	grey2: '#F6F9F8',
	black: siteColors.text,

	primary: siteColors.primary,
	secondary: siteColors.grey400,
	tertiary: siteColors.common.white,
	warning: '#FFE4CB',
	orange: '#C74600',
	primaryDark: siteColors.primaryHover,
	secondaryDark: siteColors.grey500,
	tertiaryDark: siteColors.common.white,
	warningDark: '#FFCA99',
	purple: siteColors.primary,
	grey7: siteColors.grey700,
	grey9: siteColors.grey900,
	grey4: siteColors.grey400,
	red: 'red',
	green: siteColors.green500,
	// lightOrange: "#FAF4ED",
};

const palette = {
	// yellow: createColor(paletteColorsCustom.yellowMain),
	black: paletteColorsCustom.black,
	white: paletteColorsCustom.white,
	disabled: 'rgba(198, 202, 204, 1)',
	disabledLight: '#F2F2F2',
	yellowMain: paletteColorsCustom.yellowMain,
	lightBrown: paletteColorsCustom.lightBrown,
	darkBrown: paletteColorsCustom.darkBrown,
	lightGreen: paletteColorsCustom.lightGreen,
	darkGreen: paletteColorsCustom.darkGreen,
	purple: paletteColorsCustom.purple, // ! Use primary instead
	grey7: paletteColorsCustom.grey7,
	grey9: paletteColorsCustom.grey9,
	green: paletteColorsCustom.green,
	grey4: paletteColorsCustom.grey4,
	red: paletteColorsCustom.red,
	// darkGreenBtn: createColor(paletteColorsCustom.darkGreen),
	boxShadow:
		'0px 2px 20px rgba(227, 232, 231, 0.5), 0px 1px 2px rgba(227, 232, 231, 0.5), 0px 1px 2px rgba(227, 232, 231, 0.5)',
	btnCustom: {
		// yellow: createColor(paletteColorsCustom.yellowMain),
	},
	raw: {
		white: paletteColorsCustom.white,
		black: paletteColorsCustom.black,

		darkGreen: paletteColorsCustom.darkGreen,

		grey: paletteColorsCustom.grey,

		lightBlue: paletteColorsCustom.lightBlue,
		mainBlue: paletteColorsCustom.mainBlue,

		lightRed: paletteColorsCustom.lightRed,
		mainRed: paletteColorsCustom.mainRed,

		lightPurple: paletteColorsCustom.lightPurple,
		mainPurple: paletteColorsCustom.mainPurple,

		lightGreen: paletteColorsCustom.lightGreen,
		mainGreen: paletteColorsCustom.mainGreen,

		lightGreyBlue: paletteColorsCustom.lightGreyBlue,
		mainGreyBlue: paletteColorsCustom.mainGreyBlue,

		lightGrey: paletteColorsCustom.lightGrey,
		mainGrey: paletteColorsCustom.mainGrey,

		lightOrange: paletteColorsCustom.lightOrange,
		mainOrange: paletteColorsCustom.mainOrange,

		lightTeal: paletteColorsCustom.lightTeal,
		mainTeal: paletteColorsCustom.mainTeal,

		grey2: paletteColorsCustom.grey2,
		grey100: paletteColorsCustom.grey100,

		darkerGreen: paletteColorsCustom.darkerGreen,
	},
	//--------------------------------------------------------------------------------------//
	//                                     REcuperable                                      //
	//--------------------------------------------------------------------------------------//
	text: {
		primary: '#000',
		secondary: '#6e97bb',
		disabled: 'rgba(0, 0, 0, 0.26)',
		link: 'rgba(77, 79, 92, 1)',
	},
	button: {
		background: {
			primary: paletteColorsCustom.primary,
			secondary: paletteColorsCustom.secondary,
			tertiary: paletteColorsCustom.tertiary,
			warning: paletteColorsCustom.warning,
		},
		backgroundHover: {
			primary: paletteColorsCustom.primaryDark,
			secondary: paletteColorsCustom.secondaryDark,
			tertiary: paletteColorsCustom.tertiaryDark,
			warning: paletteColorsCustom.warningDark,
		},
		color: {
			primary: paletteColorsCustom.white,
			secondary: paletteColorsCustom.black,
			tertiary: paletteColorsCustom.grey7,
			warning: paletteColorsCustom.orange,
		},
	},
};

// import palette from '../muiTheme/palette/palette';
interface BButtonProps {
	label: string | React.ReactNode;
	variant: 'primary' | 'secondary' | 'tertiary' | 'warning';
	iconLeft?: React.ReactNode;
	iconRight?: React.ReactNode;
	size?: 'medium' | 'large';
	onClick?: () => void;
	//addStyles?: CSSProperties
	addStyles?: Interpolation<Theme>;
	isDisabled?: true | false;
	variantDisabled?: 'primary' | 'secondary' | 'tertiary' | 'warning';
	sx?: SxProps;
	className?: string;
	ref?: any;
	disableRipple?: boolean;
	tooltip?: string;
	id?: string;
}

interface ClassProps {
	variant: 'primary' | 'secondary' | 'tertiary' | 'warning';
	size: 'medium' | 'large';
	variantDisabled?: 'primary' | 'secondary' | 'tertiary' | 'warning';
}

const classes = (props: ClassProps) => {
	return {
		button: {
			textTransform: 'none',
			// display: 'flex',
			// alignItems: 'center',
			gap: 10,
			color: palette.button.color[props.variant],
			background: palette.button.background[props.variant],
			height: props.size == 'large' ? 56 : 42,
			paddingInline: 16,
			':hover': {
				background: palette.button.backgroundHover[props.variant],
			},

			'&.Mui-disabled': {
				color: palette.button.color[props.variant],
				background: palette.button.background[props.variantDisabled || props.variant],
			},
			borderRadius: 8,
		},
	};
};

const BButton = ({
	id,
	isDisabled = false,
	label,
	variant,
	variantDisabled,
	onClick,
	size = 'medium',
	iconLeft,
	iconRight,
	addStyles,
	className = '',
	sx,
	ref,
	disableRipple,
	tooltip = '',
}: BButtonProps) => {
	const styles = classes({ variant, size, variantDisabled });

	const button = (
		<Button
			id={id}
			disabled={isDisabled ? true : false}
			css={{ ...styles.button, ...(addStyles as any), ...sx } as any}
			onClick={() => {
				return onClick && onClick();
			}}
			className={className}
			ref={ref}
			disableRipple={disableRipple}
		>
			{iconLeft}
			{label}
			{iconRight}
		</Button>
	);

	if (tooltip) {
		return (
			<LightTooltip title={tooltip} placement="top" arrow>
				{button}
			</LightTooltip>
		);
	}

	return button;
};

export default BButton;
