import { useMemo } from 'react';

import type { Theme } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import { Cloud, Cloudy, Droplet, Droplets, ShoppingCart } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { ALL_YEAR_OPTION_VALUE, WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import TradeOffChart from '../../components/TradeOffChart';
import Unit from '../../components/units/Unit';
import { useYearSelection } from '../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../hooks/useSearchQueryParams';
import { useSkeleton } from '../../hooks/useSkeleton';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import { CARBON_INDICATOR } from '../../utils/constants';

import { useAnalyseSetup, useGetTradeoffAnalyseData } from './analyse.hooks';

const classes = {
	text: {
		color: siteColors.primary,
		fontWeight: 600,
	},
};

const getIndicators = (theme: Theme, t: any, period: number) => {
	return [
		{
			label: t(WORDING_TERMS.INTENSITY),
			value: 'carbonIntensity',
			unity: <Unit measure="carbonIntensityKg" color={theme.palette.grey[700]} fontWeight={500} />,
			precision: 2,
			popoverKeys: [
				{
					key: 'carbonImpact',
					label:
						period === ALL_YEAR_OPTION_VALUE ? t(WORDING_TERMS.IMPACT_ALL_YEAR) : t(WORDING_TERMS.IMPACT, { period }),
					icon: <Cloudy color={theme.palette.primary.main} />,
					unit: <Unit measure="carbonImpactTon" color={theme.palette.grey[700]} fontWeight={500} />,
					style: classes.text,
					precision: 0,
				},
				{
					key: 'carbonIntensity',
					label: t(WORDING_TERMS.INTENSITY),
					icon: <Cloud color={theme.palette.primary.main} />,
					unit: <Unit measure="carbonIntensityKg" color={theme.palette.grey[700]} fontWeight={500} />,
					style: classes.text,
					precision: 2,
				},
			],
		},
		{
			label: t(WORDING_TERMS.INTENSITY),
			value: 'waterIntensity',
			unity: <Unit measure="waterIntensity" color={theme.palette.grey[700]} fontWeight={500} />,
			precision: 2,
			popoverKeys: [
				{
					key: 'waterImpact',
					label:
						period === ALL_YEAR_OPTION_VALUE ? t(WORDING_TERMS.IMPACT_ALL_YEAR) : t(WORDING_TERMS.IMPACT, { period }),
					icon: <Droplets color={siteColors.water500} />,
					unit: <Unit measure="waterImpact" color={theme.palette.grey[700]} fontWeight={500} />,
					style: { ...classes.text, color: siteColors.water500 },
					precision: 0,
				},
				{
					key: 'waterIntensity',
					label: t(WORDING_TERMS.INTENSITY),
					icon: <Droplet color={siteColors.water500} />,
					unit: <Unit measure="waterIntensity" color={theme.palette.grey[700]} fontWeight={500} />,
					style: { ...classes.text, color: siteColors.water500 },
					precision: 2,
				},
			],
		},
		{
			label: t('soldItems'),
			value: 'volume',
			precision: 0,
			unity: <Unit measure="uvc" color={theme.palette.grey[700]} fontWeight={500} />,
			popoverKeys: [
				{
					key: 'volume',
					label: t('soldItems'),
					icon: <ShoppingCart />,
					unit: <Unit measure="uvc" color={theme.palette.grey[700]} fontWeight={500} />,
					precision: 0,
				},
				{
					key: 'volumeMass',
					label: t('Volume'),
					icon: <ShoppingCart />,
					unit: <Unit measure="massTon" color={theme.palette.grey[700]} fontWeight={500} />,
					precision: 0,
				},
			],
		},
	];
};

const formatDataGraph = (segment: string, data: any, useValue: any, indicator: any, t: any) => {
	const finalArray = (data || [])?.map((d: any, index: number) => {
		return {
			...d,
			y: d[useValue.y.value] || 0,
			x: d[useValue.x.value] || 0,

			name: d.label,
			value: d[useValue.x.value],
			[useValue.x.value]: d[useValue.x.value] || 0,
			[useValue.y.value]: d[useValue.y.value] || 0,
			key: d?.value || d?._id || d?.objectId,
			objectId: d?.value || d?._id || d?.objectId,
			uid: segment,
			// labelTypeModelizationIngredient: (TYPE_MODELIZATION_INGREDIENT as any)[
			// 	d.typeModelization?.toLowerCase() || ''
			// ],
			labelTypeModelizationIngredient: t(d.typeModelization?.toLowerCase() || '', {
				recipe: t('recipe'),
				recipe_lowercase: toLowerCase(t('recipe')),
			}),
			typeModelization: d.typeModelization,
			color:
				indicator === CARBON_INDICATOR
					? d.typeModelization && d.typeModelization.toLowerCase() === 'n1_gamme'
						? siteColors.grey700
						: siteColors.primary
					: d.typeModelization && d.typeModelization.toLowerCase() === 'n1_gamme'
					? siteColors.water100
					: siteColors.water500,
			subtitle: 'Test',
			path: `analyse/${segment}`,
		};
	});

	return finalArray;
};

type Props = {
	totalCount?: number;
};

/* { products, segment, indicator, displayLine, loading, skeleton }: AnalyseTradeOffProps */
const TradeOffAnalyse = ({ totalCount }: Props) => {
	const { indicator } = useApp();

	// translation

	const { t } = useTranslation();
	const theme = useTheme();
	const [searchQueryParams] = useSearchQueryParams();
	const { categorySelected } = useAnalyseSetup();

	const params = useParams();

	const segment = params.segment!;

	const {
		data: tradeOffData,
		isLoading: isDataTradeOffLoading,
		isSuccess: isDataTradeOffSuccess,
	} = useGetTradeoffAnalyseData({
		categorySelected,
		searchQueryParams,
	});

	const { selectedYear } = useYearSelection();

	const indicators = useMemo(() => {
		return getIndicators(theme, t, selectedYear);
	}, [theme, t]);

	// ---- value graph by indicator ---- //
	const useValue = useMemo(() => {
		if (indicator === CARBON_INDICATOR) {
			return {
				x: indicators[2],
				y: indicators[0],
			};
		} else {
			return {
				x: indicators[2],
				y: indicators[1],
			};
		}
	}, [indicators, indicator]);

	const { skeleton: tradeoffSkeleton } = useSkeleton({ condition: isDataTradeOffLoading });
	return (
		<TradeOffChart
			datas={(tradeOffData ? { data: formatDataGraph(segment, tradeOffData, useValue, indicator, t) } : {}) as any}
			displayLine={`${tradeOffData?.length || 0} ${t('sur')} ${totalCount} ${t('éléments')}`}
			indicators={indicators}
			useValue={useValue}
			loading={false}
			skeleton={tradeoffSkeleton}
			toggleImpact={false}
		/>
	);
};

export default TradeOffAnalyse;
