import { type QueryFunctionContext } from '@tanstack/react-query';
import dayjs from 'dayjs';
import _ from 'lodash';
import { utils, writeFile } from 'xlsx';

import { type IIngredient } from '@carbonmaps/shared/types/ingredient.types';
import { functionName } from '@carbonmaps/shared/utils/constants';

import { type ExportFormat } from '../../../apps/front/src/components/dialogs/ExportDialog';
import IngredientModel from '../../../apps/front/src/models/Ingredient.model';
import { getFacetToShow } from '../../../apps/front/src/utils/utils';

export type GetParams = {
	size: number;
	page: number;
	direction?: number;
	id?: string;
	input?: string;
	facetFilters?: { values: string[]; path: string }[] | [];
	supplierIds?: string[];
	sortingBy?: {
		id: string;
		desc: boolean;
	} | null;
	objectId?: string;
	codeIngCmaps?: string;
	categoryFilters?: any;
	viewMode?: string;
	family?: string;
	groupField?: string;
};

export async function getIngredients(params: GetParams) {
	const { size, page, input = '', facetFilters, sortingBy, categoryFilters, viewMode, ...rest } = params;

	try {
		const { data, count, maxCarboneIntensity, maxWaterIntensity, totalCarbonImpact, totalWaterImpact } =
			(await Parse.Cloud.run('getAllIngredient', {
				limit: size,
				skip: (page - 1) * size,
				input,
				facetFilters,
				sortingBy,
				unityCarbonImpact: 1,
				categoryFilters,
				pageIndex: page,

				viewMode,
				...rest,
			})) as {
				data: Parse.Object[];
				count: number;
				maxCarboneIntensity?: number;
				maxWaterIntensity?: number;
				totalCarbonImpact?: number;
				totalWaterImpact?: number;
				pageIndex?: number;
			};

		return {
			data,
			maxCarboneIntensity,
			maxWaterIntensity,
			totalCarbonImpact,
			totalWaterImpact,
			meta: {
				last_page: count ? Math.ceil(count / size) : 1,
				count,
			},
		};
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getTradeOffData(params: GetParams) {
	const { input = '', facetFilters, categoryFilters, supplierIds, ...rest } = params;

	try {
		const { data } = await Parse.Cloud.run('getAllIngredient', {
			limit: 0,
			isGraph: true,
			unityCarbonImpact: 1000,
			input,
			facetFilters,
			categoryFilters,
			supplierIds,
			...rest,
		});
		return data;
	} catch (error) {
		return Promise.reject(error);
	}
}

// export async function getIngredientCount(context: any) {
// 	try {
// 		const count = await Parse.Cloud.run('getIngredientCount');
// 		return count;
// 	} catch (error) {
// 		return Promise.reject(error);
// 	}
// }


//--------------------------------------------------------------------------------------//
//                              count product for company                               //
//--------------------------------------------------------------------------------------//
export const getIngredientCount = async (context: QueryFunctionContext<readonly ['getIngredientCount', Record<string, any>]>) => {
	try {
		const params = context.queryKey[1];
		// const count = await Parse.Cloud.run('getProductCount');
		const count = await Parse.Cloud.run(functionName.temporality.countIngredientByPeriod, { ...params });
		return count;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getIngredientKPIs = async (context: QueryFunctionContext<readonly ['getIngredientKPIs', Record<string, any>]>) => {
	try {
		const params = context.queryKey[1];
		// const count = await Parse.Cloud.run('getProductCount');
		const count = await Parse.Cloud.run(functionName.ingredients.getIngredientKPIs, { ...params });
		return count;
	} catch (error) {
		return Promise.reject(error);
	}
};


/**
 * facets
 * @param context
 * @returns
 */
export const getCategoriesIngredient = async (
	context: QueryFunctionContext<
		readonly [
			'getCategoryIngredient',
			{
				input: string;
				company: string;
				facetFilters: [];
				categoryFilters: any;
				objectId?: string;
				ids: any;
				path?: string;
			},
		]
	>,
): Promise<any> => {
	try {
		const {
			queryKey: {
				1: { input: search, facetFilters, categoryFilters, objectId, ids, path },
			},
		} = context;
		const params = {
			search,
			facetFilters,
			categoryFilters,
			objectId,
			ids,
			path,
		};
		const data = await Parse.Cloud.run('getIngredientFacets', params);

		// For a facet with a value already selected, I may be able to select another value
		// and see the products (OR ingredient) of one OR the other of the facet values appear
		if (facetFilters.length && data.length) {
			const allData = await Parse.Cloud.run('getIngredientFacets', {
				...params,
				facetFilters: [],
			});

			return getFacetToShow(facetFilters, data[0], allData[0]);
		}

		if (data.length) return data[0];
		return [];
	} catch (error) {
		return Promise.reject(error);
	}
};

export type GetIngredientSheetActionData = {
	ingredient: any;
	codeIngCmaps?: string
};



export const getIngredientSheetAction = async (
	context: QueryFunctionContext<readonly ['getIngredientSheet', { objectId: string }]>,
): Promise<GetIngredientSheetActionData> => {
	try {
		const {
			queryKey: [, { objectId }],
		} = context;

		const ingredientSheetData = (await Parse.Cloud.run('getIngredientSheet', {
			ingredientId: objectId,
		})) as GetIngredientSheetActionData; // Everything must be already JSONs from the server.

		return ingredientSheetData;
	} catch (error) {
		console.log(' ------ getIngredientSheetAction error: ', error);
		return Promise.reject(error);
	}
};

export async function getIngredientProducts(params: GetParams) {
	const { size, page, input = '', sortingBy, objectId, codeIngCmaps, ...rest } = params;

	try {
		const { data, count, maxValue } = (await Parse.Cloud.run('findProduct', {
			limit: size,
			skip: (page - 1) * size,
			input,
			sortingBy,
			codeIngCmaps,
			ingredientId: objectId,
			...rest,
		})) as {
			data: Parse.Object[];
			count: number;
			maxValue: number;
		};

		return {
			data,
			maxValue,
			meta: {
				last_page: count ? Math.ceil(count / size) : 1,
				count,
			},
		};
	} catch (error) {
		return Promise.reject(error);
	}
}

//--------------------------------------------------------------------------------------//
//                                      For detail ingredient                           //
//--------------------------------------------------------------------------------------//

export type GetIngredientFamilySheetActionData = {
	ingredients: any;
	dataSegment: any;
};

export const getIngredientFamilySheetAction = async (
	context: QueryFunctionContext<readonly ['getIngredientFamilySheet', { objectId: string }]>,
): Promise<GetIngredientFamilySheetActionData> => {
	try {
		const {
			queryKey: [, { objectId }],
		} = context;

		const data = (await Parse.Cloud.run('getIngredientFamilySheet', {
			ingredientId: objectId,
		})) as GetIngredientFamilySheetActionData; // Everything must be already JSONs from the server.

		return data;
	} catch (error) {
		console.log(' ------ getIngredientSheetFamilyAction error: ', error);
		return Promise.reject(error);
	}
};


//--------------------------------------------------------------------------------------//
//                get data for one ingredient cmaps with time period                    //
//--------------------------------------------------------------------------------------//
export const getIngredientSheetTimePeriodAction = async (
	context: QueryFunctionContext<
		readonly ['getIngredientSheetTimePeriod', { objectId: string; period: string | number; } & any]
	>,
): Promise<GetIngredientSheetActionData> => {
	try {
		const {
			queryKey: [, { objectId, period, paramType }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.temporality.findOneIngredient, {
			ingredientId: objectId,
			period,
			paramType,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		console.log(' ------ getIngredientSheetTimePeriodAction error: ', error);
		return Promise.reject(error);
	}
};




//--------------------------------------------------------------------------------------//
//                get data family for one ingredient CMAPS with time period             //
//--------------------------------------------------------------------------------------//

export type GetIngredientSheetFamilySegmentActionData = {
	dataSegment: any[];
};

export const getIngredientSheetSegmentTimePeriodAction = async (
	context: QueryFunctionContext<
		readonly ['getIngredientSheetSegmentTimePeriod', { objectId: string; period: string | number; }]
	>,
): Promise<GetIngredientSheetFamilySegmentActionData> => {
	try {
		const {
			queryKey: [, { objectId, period }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.temporality.findOneIngredientSegmentSheet, {
			ingredientId: objectId,
			period,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		console.log(' ------ getIngredientSheetTimePeriodAction error: ', error);
		return Promise.reject(error);
	}
};

