import React, { ReactNode } from 'react';

import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';

import { useGetClientAuthQuery } from '../lib/react-query/features/auth/auth.hooks';

type Props = {
	children?: ReactNode;
};

const CompanySessionGuard = ({ children }: Props) => {
	const location = useLocation();

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	if (!authData) {
		return <h1>CompanySessionGuard loader</h1>;
	}

	if (!authData.session.company) {
		return <Navigate replace state={{ from: location }} to={FRONT_PATH_NAMES.userNoCompany} />;
	}

	return children ? children : <Outlet />;
};

export default CompanySessionGuard;
