import { css, cx } from '@emotion/css';
import _ from 'lodash';

import { useTranslation } from '../../../../../../hooks/useTranslation';
import { siteColors } from '../../../../../../lib/colors';

type Props = {
	statusType: string;
}

const statusColors = {
	supplier_contacted: siteColors.orange10,
	supplier_connected: siteColors.teal10,
	survey_completed: siteColors.green10,
};

const styles = {
	container: css({
		padding: '4px 8px',
		border: '1px solid rgba(0, 0, 0, 0.10)',
		color: siteColors.grey900,
		borderRadius: '4px',
		flexWrap: 'nowrap',
		whiteSpace: 'nowrap',
	}),
};

const StatusChip = (props: Props) => {
	const { t } = useTranslation();
	return (
		<div
			className={cx('flexCenter textCenter noWrap', styles.container)}
			style={{
				backgroundColor: _.get(statusColors, props.statusType, siteColors.grey500),
			}}
		>
			{t(props.statusType)}
		</div>
	);
};

export default StatusChip;
