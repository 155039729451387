import React, { /* Fragment, */ useEffect } from 'react';

import { css, cx } from '@emotion/css';
import { type Theme } from '@emotion/react';
import { type Interpolation } from '@emotion/serialize';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const styles = {
	gap12: css({
		gap: 12,
	}),
	width250: css({
		width: 250,
	}),
};
interface Menu {
	title: string;
	value: string;
	icon: React.ReactNode;
}

interface IMenu extends Menu {
	customComponent?: string;
}
interface BDropdownProps {
	children: React.ReactNode;
	menus: any;
	setSelected?: (menu: IMenu) => void;
	renderItem: (menu: IMenu, index?: number) => React.ReactNode;
	isOpen?: number;
	addStyles?: Interpolation<Theme>;
}

const BDropdown = ({ children, menus, setSelected, renderItem, isOpen, addStyles }: BDropdownProps) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (select?: Menu) => {
		setAnchorEl(null);
		setSelected && select && setSelected(select);
	};

	useEffect(() => {
		setAnchorEl(null);
	}, [isOpen]);

	return (
		<div
			className={css({
				...(addStyles as any),
			})}
		>
			<div
				id="fade-button"
				aria-controls={open ? 'fade-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
			>
				{children}
			</div>
			{anchorEl && (
				<Menu
					id="fade-menu"
					MenuListProps={{
						'aria-labelledby': 'fade-button',
					}}
					anchorEl={anchorEl}
					open={open}
					onClose={() => {
						return handleClose();
					}}
					TransitionComponent={Fade}
				>
					{menus.map((menu: any, index: number) => {
						return menu.customComponent
							? (
								renderItem(menu, index)
							)
							: (
								<MenuItem
									key={menu.title}
									onClick={() => {
										return handleClose(menu);
									}}
									className={styles.width250}
								>
									<div className={cx('flexRow alignCenter', styles.gap12)}>
										{menu.icon} {menu.title}
									</div>
								</MenuItem>
							);
					})}
				</Menu>
			)}
		</div>
	);
};

export default BDropdown;
