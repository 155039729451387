import { type RouteObject } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';

import AuthLayout from '../components/layout/AuthLayout';
import InvalidLink from '../containers/invalidLink/InvalidLink';
import LogIn from '../containers/logIn/LogIn';
import ResendEmailConfirmation from '../containers/logIn/ResendEmailConfirmation';
import UserNoCompany from '../containers/logIn/sso/UserNoCompany';
import ResetPassword from '../containers/resetPassword/ResetPassword';
import ResetPasswordNewAccount from '../containers/resetPassword/ResetPasswordNewAccount';
import ChangeEmailValid from '../containers/userSettings/ChangeEmailValid';

const authRoutes: RouteObject[] = [
	{
		element: <AuthLayout />,
		children: [
			{
				path: FRONT_PATH_NAMES.logIn,
				element: <LogIn />,
				index: true,
			},
			{
				path: FRONT_PATH_NAMES.resendEmailConfirmation,
				element: <ResendEmailConfirmation />,
				index: true,
			},
			{
				path: FRONT_PATH_NAMES.changePassword,
				element: <ResetPassword />,
			},
			{
				path: FRONT_PATH_NAMES.invalidEmail,
				element: <ChangeEmailValid />,
			},
			{
				path: FRONT_PATH_NAMES.invalidLink,
				element: <InvalidLink />,
			},
			{
				path: FRONT_PATH_NAMES.resetPasswordNewAccount,
				element: <ResetPasswordNewAccount />,
			},
			{
				path: FRONT_PATH_NAMES.userNoCompany,
				element: <UserNoCompany />,
			},
		],
	},
];

export default authRoutes;
