import { css, cx } from '@emotion/css';
import DialogTitle from '@mui/material/DialogTitle';

import { stylesFlashMessage } from '../../containers/sales/clientsReports/ImportButton';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import Dialog from '../dialogs/Dialog';


const AlertContactUpload = ({ title, type, openInfo, setOpenInfo, infoMessage }: { title?: string; type?: string, openInfo: boolean, setOpenInfo: any, infoMessage: any }) => {
	const { t } = useTranslation();

	return <Dialog
		isLoading={false}
		description={''}
		open={openInfo}
		toggle={() => {
			setOpenInfo(!openInfo);
		}}
		closeButton={true}
		withCancelButton={false}
		paperProps={{
			maxWidth: '100vw',
		}}
	>
		<div className="flexColumn gap24">
			<div className="flexColumn gap16">
				<DialogTitle
					id="alert-dialog-title"
					variant="h1"
					textAlign="left"
					sx={{ margin: 0, padding: 0, color: siteColors.primary, fontSize: '24px', fontWeight: 600 }}
				>
					{title ?? t('import-quote-title')}
				</DialogTitle>
			</div>
			<div className={cx('flexColumn nowrap gap16')}>
				<div className={cx('flexColumn nowrap gap16', stylesFlashMessage.flash)} >
					<div className={cx('flexColumn nowrap gap8')}>
						<div>✅ {t('alert-imported-contact-rows', {
							nbSupplier: infoMessage?.nbSupplier,
							nbContact: infoMessage?.nbContact,
						})}</div>
						<div>❌  {t('alert-rows-contact-not-imported', { nbContactNotImported: infoMessage?.nbContactNotImported })}</div>
					</div>
					<div>

						{infoMessage?.supplierNotExists?.length ?
							<>	<div>{type === 'pre-campaign' ? t('alert-label-supplier-error-not-exists') : t('alert-label-supplier-error-outside')}:	</div>
								<ul>
									{infoMessage?.supplierNotExists?.map((item: any, index: number,
									) => {
										return (
											<li key={`${index}supplier-not-exists`}>{item.email} - {item.uid}</li>
										);
									})}
								</ul>
							</>
							: <></>
						}

						{infoMessage?.existingContact?.length ?
							<>	<div>{t('alert-label-existing-contact')}:	</div>
								<ul>
									{infoMessage?.existingContact?.map((item: any, index: number,
									) => {
										return (
											<li key={`${index}email-not-exists`}>{item.email}</li>
										);
									})}
								</ul>
							</>
							: <></>
						}

						{infoMessage?.emailsInvalid?.length ?
							<>	<div>{t('alert-label-email-invalid')}:	</div>
								<ul>
									{infoMessage?.emailsInvalid?.map((item: any, index: number,
									) => {
										return (
											<li key={`${index}existingContact-invalid`}>{item.email}</li>
										);
									})}
								</ul>
							</>
							: <></>
						}
					</div>
				</div>
			</div>
		</div>
	</Dialog>;
};

export default AlertContactUpload;
