import { useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SubmitHandler, useForm } from 'react-hook-form';

import defaultAvatar from '@carbonmaps/media/images/default-avatar.png';
import { UserAccountFormInput, userAccountFormValidationSchema } from '@carbonmaps/shared/validations/user.validations';
import { updateCurrentUserAccountAction, uploadUserAvatar } from '@carbonmaps/ui/actions/user.actions';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import BInput from '@carbonmaps/ui/components/saas/BInput';
import BSelectNative from '@carbonmaps/ui/components/saas/BSelectNative';
import FormContainer from '@carbonmaps/ui/components/saas/Form/FormContainer';
import { classes } from '@carbonmaps/ui/components/saas/Form/FormStyle';

import AlertStatic from '../../components/Toast/AlertStatic';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';
import { useGetImgProxy } from '../../lib/react-query/features/img/img.hooks';

const AccountForm = () => {
	const queryClient = useQueryClient();
	// const { refreshUser } = useAuth();
	const theme = useTheme();
	const styles = classes(theme);
	const [file, setFile] = useState(null);
	const [message, setMessage] = useState('');

	const { t } = useTranslation();

	const {
		result: { data: authData /* , refetch */ },
		key: getClientAuthKey,
	} = useGetClientAuthQuery();

	const {
		register,
		handleSubmit,
		formState: { errors, isDirty, isValid },
		reset,
	} = useForm<UserAccountFormInput>({
		resolver: zodResolver(userAccountFormValidationSchema),
		defaultValues: async () => {
			// const { data } = await refetch();
			return {
				firstName: authData?.user.firstName || '',
				lastName: authData?.user.lastName || '',
				position: authData?.user.position || 'Autre',
			};
		},
		mode: 'onChange',
	});

	const {
		mutate: updateCurrentUserAccount,
		isError,
		isSuccess,
	} = useMutation({
		mutationKey: ['updateCurrentUserAccount'],
		mutationFn: updateCurrentUserAccountAction,
		onSuccess: async () => {
			setMessage('Informations mises à jour');
			queryClient.invalidateQueries({ queryKey: getClientAuthKey });
			// refreshUser();
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	// ------------------------------------------------------------ //
	// ------------------------ upload photo ---------------------- //
	// ------------------------------------------------------------ //
	const { mutate: uploadUserPhoto, isLoading: isUploadLoading } = useMutation({
		mutationKey: ['uploadUserPhoto'],
		mutationFn: uploadUserAvatar,
		onSuccess: async () => {
			setMessage('Informations mises à jour');
			queryClient.invalidateQueries({ queryKey: getClientAuthKey });
			// refreshUser();
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const onSubmit: SubmitHandler<UserAccountFormInput> = async (values) => {
		updateCurrentUserAccount(values);
	};

	const onCancel = () => {
		reset();
	};

	const {
		result: { data: avatarUrl },
	} = useGetImgProxy({
		params: { filename: authData?.user.avatar || '' },
	});

	return (
		<>
			<FormContainer
				title={t('user-profil-form-title')}
				subtitle={t('user-profil-form-subtitle')}
				avatar
				onSubmit={handleSubmit(onSubmit /* , onFormError */)} // ? why onSubmit is called twice? the second time in the button later.
				file={file}
				setFile={uploadUserPhoto}
				defaultLogo={/* isSuccessUserLoading && */ authData?.user.avatar ? avatarUrl : defaultAvatar}
				isSuccess={isSuccess}
				isError={isError}
				// isSuccess={undefined}
				// isError={undefined}
				alertSuccess={
					<AlertStatic text={message} type={'success'} icon={<CheckIcon css={{ color: siteColors.green500 }} />} />
				}
				alertError={<AlertStatic text={message} type={'error'} icon={<ErrorIcon css={{ color: '#C74600' }} />} />}
				addStyles={{
					marginBottom: 148,
					logo: {
						objectFit: 'cover',
					},
				}}
				loading={isUploadLoading}
				t={t}
			>
				<p css={styles.subtitle}>{t('about-text')}</p>
				<div
					className="flexColumn width100"
					css={{
						maxWidth: 428,
						gap: 24,
						'.input-container': {
							width: '100%',
						},
					}}
				>
					<BInput
						required
						id="firstName"
						label={t('first-name-label')}
						fullWidth
						margin="dense"
						{...register('firstName')}
						name="firstName"
						error={errors.firstName ? true : false}
						errorMessage={errors?.firstName?.message}
					/>
					<BInput
						required
						id="lastName"
						label={t('last-name-label')}
						fullWidth
						margin="dense"
						{...register('lastName')}
						name="lastName"
						error={errors.lastName ? true : false}
						errorMessage={errors?.lastName?.message}
					/>
					<BSelectNative
						{...register('position')}
						required
						id="position"
						label={t('user-position-label')}
						options={[
							{ value: 'Qualité', label: t('position-quality') },
							{ value: 'R&D', label: t('rd-label') },
							{ value: 'Achat', label: t('purchase-position') },
							{ value: 'Direction', label: t('direction-position') },
							{ value: 'Responsable RSE', label: t('rse-position') },
							{ value: 'Conseiller technique', label: t('ct-position') },
							{ value: 'Autre', label: t('other-text') },
						]}
						name="position"
					/>
				</div>
			</FormContainer>

			{isDirty && isValid && (
				<Box
					className="flexRow justifyCenter alignCenter"
					css={{
						width: '100%',
						height: 100,
						gap: 10,
						borderTop: `1px solid ${theme.palette.grey[700]}`,
						position: 'fixed',
						left: 100,
						right: 0,
						bottom: 0,
						background: theme.palette.common.white,
						// width: '80%',
					}}
				>
					<BButton
						onClick={handleSubmit(onSubmit)}
						addStyles={{
							fontWeight: 600,
							fontSize: 16,
						}}
						label={t('user-profil-form-save-button')}
						variant="primary"
					/>
					<BButton
						onClick={() => {
							onCancel();
						}}
						addStyles={{ fontWeight: 600, fontSize: 16 }}
						label={t('user-profil-form-cancel-button')}
						variant="tertiary"
					/>
				</Box>
			)}
		</>
	);
};

export default AccountForm;
