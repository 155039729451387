import React from 'react';

import { css, cx } from '@emotion/css';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { select } from 'async';
import { BarChart2, Cloud, Cloudy, Droplet, Droplets } from 'lucide-react';

import { ReactComponent as CarbonImpactPortionIcon } from '@carbonmaps/media/icons/cloud-box.svg';
import { ReactComponent as WaterImpactPortionIcon } from '@carbonmaps/media/icons/drop-box.svg';
import { ALL_YEAR_OPTION_VALUE, WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import BPopover from '@carbonmaps/ui/components/saas/BPopover';
import { Indicator } from '@carbonmaps/ui/contexts/AppProvider';
import { CARBON_INDICATOR, WATER_INDICATOR } from '@carbonmaps/ui/utils/constants';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import ScoringCard from '../../components/card/ScoringCard';
import { useYearSelection } from '../../hooks/useImpactYear';
import { useTranslation } from '../../hooks/useTranslation';
import { cn, siteColors } from '../../lib/colors';
import BlueCheck from '../BlueCheck';
import SimpleIndicatorCard, { IndicatorSingleCardProps } from '../card/IndicatorSingleCard';
import Skeleton from '../Skeleton';
import Unit from '../units/Unit';

const iconProps = {
	// color: indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500,
	size: 30,
	fontWeight: 400,
	strokeWidth: 1.5,
};

const waterColor = siteColors.water500;
const carbonColor = siteColors.carbon500;

// eslint-disable-next-line react-refresh/only-export-components
export const indicatorConfigItems = new Map([
	[
		CARBON_INDICATOR,
		{
			impactTitle: WORDING_TERMS.IMPACT,
			impactIcon: <Cloudy {...iconProps} color={carbonColor} />,
			impactUnity: <Unit measure="carbonImpactTon" color={siteColors.grey700} />,
			intensityTitle: WORDING_TERMS.INTENSITY,
			intensityIcon: <Cloud {...iconProps} color={carbonColor} />,
			intensityUnity: <Unit measure="carbonIntensityKg" color={siteColors.grey700} />,
			indicatorTitle: 'Indicateurs carbone',
			impactPortionIcon: <CarbonImpactPortionIcon css={{ path: { stroke: carbonColor } }} />,
			impactPortionTitle: WORDING_TERMS.IMPACT_PORTION,
			impactPortionUnity: <Unit measure="carbonImpactPortion" color={siteColors.grey700} />,
		},
	],
	[
		WATER_INDICATOR,
		{
			impactTitle: WORDING_TERMS.IMPACT,
			impactIcon: <Droplets {...iconProps} color={waterColor} />,
			impactUnity: <Unit measure="waterImpact" color={siteColors.grey700} />,
			intensityTitle: WORDING_TERMS.INTENSITY,
			intensityIcon: <Droplet {...iconProps} color={waterColor} />,
			intensityUnity: <Unit measure="waterIntensity" color={siteColors.grey700} />,
			indicatorTitle: 'Indicateurs eau',
			impactPortionIcon: <WaterImpactPortionIcon css={{ path: { stroke: waterColor } }} />,
			impactPortionTitle: WORDING_TERMS.IMPACT_PORTION,
			impactPortionUnity: <Unit measure="waterImpactPortion" color={siteColors.grey700} />,
		},
	],
]);

type IndicatorData = {
	value: any;
	fullFilledPercent?: any;
	suppliersCount?: {
		fulfilled: number;
		total: number;
	};
	noValue?: any;
	isUndefinedValue?: boolean;
	popoverContent?: React.ReactNode;
};

type Props = {
	indicator: Indicator; // 'water' or 'carbon'
	indicatorDatas: {
		intensity: IndicatorData;
		impact: IndicatorData;
		impactPortion?: IndicatorData;
		scoring?: any;
	};
	withPopover?: boolean;
	popoverContent?: React.ReactNode;
	isLoading?: boolean;
};

const styles = {
	container: css({
		borderRadius: pxToRem(16),
		background: siteColors.common.white,
		border: cn('1px solid', siteColors.grey500),
		display: 'flex',
		padding: '24px 0 24px 24px',
		alignItems: 'flex-start',
		gap: '24px',
		width: '100%',
		paddingRight: '24px',
		justifyContent: 'space-evenly',
	}) as any,
	divider: css({
		borderLeft: cn('1px solid', siteColors.grey500),
		height: '75px',
		background: siteColors.grey500,
		width: '1px',
	}) as any,
};

const IndicatorsBlockLight = ({
	indicator,
	indicatorDatas,
	/* popoverContent, */ isLoading,
	withPopover = true,
}: Props) => {
	const { t } = useTranslation();
	const { selectedYear } = useYearSelection();
	return (
		<div className={cx(styles.container)}>
			{indicatorDatas?.scoring && (
				<>
					<ScoringCard
						value={indicatorDatas.scoring?.value}
						sx={{
							width: pxToRem(75),
							height: pxToRem(42),
							// borderRadius: '78px',
							border: 'none',
						}}
						fontSize={23.438}
						withLabel={indicatorDatas.scoring?.withLabel}
						withBorder={false}
					/>
					<Divider className={cx(styles.divider)} />
				</>
			)}

			<SynthesisIndicatorCard
				indicatorCardProps={{
					indicator,
					value: indicatorDatas.impact.value,
					icon: indicatorConfigItems.get(indicator)?.impactIcon,
					title:
						selectedYear === ALL_YEAR_OPTION_VALUE
							? t(WORDING_TERMS.IMPACT_ALL_YEAR)
							: t(indicatorConfigItems.get(indicator)?.impactTitle ?? '', { period: selectedYear || t('period') }),
					unity: indicatorConfigItems.get(indicator)?.impactUnity,
					noValue: indicatorDatas.impact.noValue,
					isUndefinedValue: indicatorDatas.impact.isUndefinedValue,
				}}
				popoverContentProps={{
					fullFilledPercent: indicatorDatas.impact.fullFilledPercent,
					suppliersCount: indicatorDatas?.impact?.suppliersCount,
					type: 'impact',
				}}
				withPopover={withPopover}
				popoverContent={indicatorDatas?.impact?.popoverContent}
				isLoading={isLoading}
			/>

			<Divider className={cx(styles.divider)} />
			<SynthesisIndicatorCard
				indicatorCardProps={{
					indicator,
					value: indicatorDatas.intensity.value,
					icon: indicatorConfigItems.get(indicator)?.intensityIcon,
					title: t(indicatorConfigItems.get(indicator)?.intensityTitle ?? ''),
					unity: indicatorConfigItems.get(indicator)?.intensityUnity,
					noValue: indicatorDatas.intensity.noValue,
					isUndefinedValue: indicatorDatas.intensity.isUndefinedValue,
				}}
				popoverContentProps={{
					fullFilledPercent: indicatorDatas.intensity.fullFilledPercent,
					suppliersCount: indicatorDatas.intensity.suppliersCount,
					type: 'intensity',
				}}
				withPopover={withPopover}
				popoverContent={indicatorDatas?.intensity?.popoverContent}
				isLoading={isLoading}
			/>

			{indicatorDatas.impactPortion
? (
				<>
					<Divider className={cx(styles.divider)} />
					<SynthesisIndicatorCard
						indicatorCardProps={{
							indicator,
							value: indicatorDatas.impactPortion.value,
							icon: indicatorConfigItems.get(indicator)?.impactPortionIcon,
							title: t(indicatorConfigItems.get(indicator)?.impactPortionTitle ?? ''),
							unity: indicatorConfigItems.get(indicator)?.impactPortionUnity,
						}}
						popoverContentProps={{
							fullFilledPercent: indicatorDatas.impactPortion.fullFilledPercent,
							suppliersCount: indicatorDatas.impactPortion.suppliersCount,
							type: 'portion',
						}}
						withPopover={withPopover}
						popoverContent={indicatorDatas?.impactPortion?.popoverContent}
						isLoading={isLoading}
					/>
				</>
			)
: null}
		</div>
	);
};

export default IndicatorsBlockLight;

type PopoverContentProps = {
	fullFilledPercent: number | string;
	suppliersCount?: {
		fulfilled: number;
		total: number;
	};
	type?: 'intensity' | 'impact' | 'portion';
};

const PopoverContent = ({ fullFilledPercent, suppliersCount, type = 'impact' }: PopoverContentProps) => {
	const { t } = useTranslation();
	return (
		<Box padding="24px" maxWidth={'250px'}>
			<BlueCheck
				withIcon={true}
				// title={ingredientObject?.getSupplierImpactFullFilledPercent(indicator)}
				title={fullFilledPercent}
				iconSize={16}
				ok={parseInt(fullFilledPercent?.toString()) === 0 ? false : true}
			/>
			<Typography>
				{type === 'impact' && t('global-indicator-popover-subtitle1')}
				{type === 'intensity' && t('global-indicator-popover-subtitle-intensity1')}
				{type === 'portion' && t('global-indicator-popover-subtitle1')}{' '}
				{/* ingredientObject?.getSupplierImpactFullFilledPercent(indicator) */ fullFilledPercent}{' '}
				{t('global-indicator-popover-subtitle2')} ({suppliersCount?.fulfilled} {t('sur')} {suppliersCount?.total})
			</Typography>
		</Box>
	);
};

const stylesCard = {
	skeleton: css({
		height: '100% !important',
		width: '100% !important',
		flex: 1,
	} as any),
};

type SynthesisIndicatorSingleCardProps = {
	popoverContentProps: PopoverContentProps;
	indicatorCardProps: IndicatorSingleCardProps;
	withPopover?: boolean;
	popoverContent?: React.ReactNode;
	isLoading?: boolean;
};

const SynthesisIndicatorCard = ({
	popoverContentProps,
	indicatorCardProps,
	withPopover = true,
	popoverContent,
	isLoading,
}: SynthesisIndicatorSingleCardProps) => {
	if (isLoading) {
		return <Skeleton className={stylesCard.skeleton} />;
	}

	if (withPopover) {
		return (
			<BPopover
				addStyles={{ width: '100%', padding: 0 }}
				anchorOriginHorizontal={'left'}
				transformOriginVertical={'top'}
				transformOriginHorizontal={'left'}
				anchorOriginVertical={'bottom'}
				modeHover
				content={(onClose) => {
					return popoverContent ? <>{popoverContent}</> : <PopoverContent {...popoverContentProps} />;
				}}
			>
				<SimpleIndicatorCard {...indicatorCardProps} />
			</BPopover>
		);
	}

	return <SimpleIndicatorCard {...indicatorCardProps} />;
};
